import React from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../../../services/api'
import { useLoading } from '../../../../hooks/useLoading'
import { ISurvey } from '../../../blog'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import Container from '../../../../components/Container'
import { FcSurvey } from 'react-icons/fc'

function LinearProgressWithLabel (props: LinearProgressProps & { value: number }) {
  return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
  )
}

const Vizualizar: React.FC = () => {
  const { id } = useParams()
  const { showLoading, closeLoading } = useLoading()
  const [survey, setSurvey] = React.useState<ISurvey>()

  React.useEffect(() => {
    getSurvey(id)
  }, [id])

  const getSurvey = async (id?: string) => {
    try {
      if (!id) return

      showLoading()

      const { data } = await api.get(`/survey/${id}`)

      setSurvey(data.data)
    } catch (err: any) {

    } finally {
      closeLoading()
    }
  }

  // eslint-disable-next-line no-unused-vars
  let totalVotes = 0
  survey?.options.forEach(option => {
    totalVotes += option.votes.length
  })

  return (
        <Container>
            <h3><FcSurvey /> {survey?.question}</h3>
            {survey?.options.map(option => (
                <div key={option.id}>
                    <span>{option.name}</span>
                    <LinearProgressWithLabel key={option.id} value={totalVotes === 0 ? 0 : (option.votes.length * 100) / totalVotes} />
                </div>
            ))}
        </Container>
  )
}

export default Vizualizar
