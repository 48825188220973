import React from 'react'
import Container from '../../components/Container'
import { ContainerLogin, Form, ErrorStyled, ButtonContainer } from './styles'
import { Formik, FormikValues } from 'formik'
import Input from '../../components/Input'
import Alert from '@mui/material/Alert'
import Button from '../../components/Button'
import { useLoading } from '../../hooks/useLoading'
import { Checkbox as CheckboxMui, CheckboxProps, FormControlLabel, FormGroup } from '@mui/material'
import { api } from '../../services/api'
import { useSnackbar } from 'notistack'
import { Link, useNavigate } from 'react-router-dom'
import Logotipo from '../../assets/png/logo.png'

interface FormFieldsError {
    nome?: string;
    email?: string;
    password?: string;
    password_confirmation?: string;
}

const Checkbox = (props: CheckboxProps) => (
    <CheckboxMui {...props} sx={{
      color: '#ddd'
    }}/>
)

const Cadastro: React.FC = () => {
  const [state, setState] = React.useState({
    arbitragem: false,
    curiosidades: false,
    entrevistas: false,
    estadios: false,
    competicoes: false,
    historia: false,
    jogadores: false,
    selecao: false,
    regras: false,
    reportagens: false
  })

  const { arbitragem, curiosidades, entrevistas, estadios, competicoes, historia, jogadores, selecao, regras, reportagens } = state

  const { showLoading, closeLoading, loading } = useLoading()

  const [terms, setTerms] = React.useState(true)
  const [newsletter, setNewsletter] = React.useState(false)
  const [errorPassword, setErrorPassword] = React.useState('')

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const handleTerms = () => {
    setTerms(!terms)
  }

  const handleNewsletter = () => {
    setNewsletter(!newsletter)
  }

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    })
  }

  const handleForm = async (values: FormikValues) => {
    if (values.password !== values.password_confirmation) {
      setErrorPassword('As senhas são diferentes.')
      return
    }

    let interessesFutebol = ''

    interessesFutebol += arbitragem ? 'Arbitragem;' : ''
    interessesFutebol += curiosidades ? 'Curiosidades;' : ''
    interessesFutebol += entrevistas ? 'Entrevistas;' : ''
    interessesFutebol += estadios ? 'Estádios;' : ''
    interessesFutebol += competicoes ? 'Competições;' : ''
    interessesFutebol += historia ? 'História;' : ''
    interessesFutebol += jogadores ? 'Jogadores;' : ''
    interessesFutebol += selecao ? 'Seleção brasileira;' : ''
    interessesFutebol += regras ? 'Técnicos;' : ''
    interessesFutebol += reportagens ? 'Reportagens;' : ''

    try {
      showLoading()

      await api.post('/registration', {
        nome: values.nome,
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
        interesse_futebol: interessesFutebol,
        terms,
        newsletter
      })

      navigate('/cadastro/link', { replace: true })
    } catch (e: any) {
      if (e.status === 400) {
        e.errors.map((error: string) => enqueueSnackbar(error, { variant: 'error' }))
      }
    } finally {
      closeLoading()
    }
  }

  return (
        <ContainerLogin>
            <Container tag="main">
                <div className="content-page bg-white">
                    <Link to="/">
                        <div id="logo">
                            <img alt="Logotipo Pelejas" title="Logotipo Pelejas" src={Logotipo} />
                        </div>
                    </Link>
                    <Formik
                        initialValues={{ email: '', password: '', password_confirmation: '', nome: '' }}
                        validate={values => {
                          const errors: FormFieldsError = {}

                          if (!values.email) {
                            errors.email = 'Campo obrigatório!'
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                          ) {
                            errors.email = 'E-mail inválido!'
                          }

                          if (!values.password) {
                            errors.password = 'Campo obrigatório!'
                          } else if (
                            !/^[a-zA-Z0-9!@#$%^&*]{6,}$/i.test(values.password)
                          ) {
                            errors.password = 'A senha deve ter no minimo 6 caracteres.'
                          }

                          if (!values.password_confirmation) {
                            errors.password_confirmation = 'Campo obrigatório!'
                          } else if (
                            !/^[a-zA-Z0-9!@#$%^&*]{6,}$/i.test(values.password_confirmation)
                          ) {
                            errors.password_confirmation = 'A senha deve ter no minimo 6 caracteres.'
                          }

                          return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          handleForm(values)
                        }}
                    >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                          /* and other goodies */
                        }) => (
                            <Form onSubmit={(e) => {
                              e.preventDefault()
                              handleSubmit()
                            }}>
                                <div className="division">
                                    <div>
                                        <Input
                                            type="text"
                                            name="nome"
                                            id="nome"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nome}
                                            label="Nome Completo:"
                                            placeholder="Nome Completo"
                                            required
                                        />
                                        {errors.nome && touched.nome && (
                                            <Alert severity="error">{errors.nome}</Alert>
                                        )}
                                    </div>
                                    <div>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            label="E-mail:"
                                            placeholder="example@host.com"
                                            required
                                        />
                                        {errors.email && touched.email && (
                                            <Alert severity="error">{errors.email}</Alert>
                                        )}
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <Input
                                            type="password"
                                            name="password"
                                            id="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            label={'Senha:'}
                                            placeholder="******"
                                            required
                                        />
                                        {errors.password && touched.password && (
                                            <Alert severity="error">{errors.password}</Alert>
                                        )}

                                        {errorPassword && (
                                            <ErrorStyled>
                                                <Alert severity="error">{errorPassword}</Alert>
                                            </ErrorStyled>
                                        )}
                                    </div>
                                    <div>
                                        <Input
                                            type="password"
                                            name="password_confirmation"
                                            id="password_confirmation"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password_confirmation}
                                            label={'Confirme sua senha:'}
                                            placeholder="******"
                                            required
                                        />
                                        {errors.password_confirmation && touched.password_confirmation && (
                                            <Alert severity="error">{errors.password_confirmation}</Alert>
                                        )}

                                        {errorPassword && (
                                            <ErrorStyled>
                                                <Alert severity="error">{errorPassword}</Alert>
                                            </ErrorStyled>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <label className="label">Áreas de interesse sobre o PALMEIRAS: </label>
                                    <div className="division">
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={arbitragem} onChange={handleChangeCheckBox} name="arbitragem" />
                                                    }
                                                    label="Arbitragem"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={curiosidades} onChange={handleChangeCheckBox} name="curiosidades" />
                                                    }
                                                    label="Curiosidades"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={entrevistas} onChange={handleChangeCheckBox} name="entrevistas" />
                                                    }
                                                    label="Entrevistas"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={estadios} onChange={handleChangeCheckBox} name="estadios" />
                                                    }
                                                    label="Estádios"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={competicoes} onChange={handleChangeCheckBox} name="competicoes" />
                                                    }
                                                    label="Competições"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={historia} onChange={handleChangeCheckBox} name="historia" />
                                                    }
                                                    label="História"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={jogadores} onChange={handleChangeCheckBox} name="jogadores" />
                                                    }
                                                    label="Jogadores"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={selecao} onChange={handleChangeCheckBox} name="selecao" />
                                                    }
                                                    label="Seleção brasileira"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={regras} onChange={handleChangeCheckBox} name="regras" />
                                                    }
                                                    label="Regras"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={reportagens} onChange={handleChangeCheckBox} name="reportagens" />
                                                    }
                                                    label="Reportagens"
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                <div id="terms">
                                    <FormControlLabel
                                        sx={{
                                          '&.MuiFormControlLabel-root': {
                                            marginRight: 0
                                          }
                                        }}
                                        control={
                                            <Checkbox required checked={terms} onChange={handleTerms} name="terms" />
                                        }
                                        label=""
                                    />
                                    <p> *Concordo com os <Link to="/cadastro">Termos de Uso</Link> e <Link to="/cadastro">Política de Privacidade</Link>.</p>
                                </div>
                                {!terms && (
                                    <ErrorStyled>
                                        <Alert severity="error">Os termos de uso são obrigatórios!</Alert>
                                    </ErrorStyled>
                                )}
                                <div id="terms">
                                    <FormControlLabel
                                        sx={{
                                          '&.MuiFormControlLabel-root': {
                                            marginRight: 0
                                          }
                                        }}
                                        control={
                                            <Checkbox checked={newsletter} onChange={handleNewsletter} name="terms" />
                                        }
                                        label=""
                                    />
                                    <p>Sim, gostaria de receber novidades do PELEJAS através de e-mail.</p>
                                </div>
                                <ButtonContainer>
                                    <Button disabled={loading} type="submit">
                                        {loading ? 'Carregando' : 'Enviar'}
                                    </Button>
                                </ButtonContainer>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Container>
        </ContainerLogin>
  )
}

export default Cadastro
