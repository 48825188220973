import styled from 'styled-components'
import banner from '../../../src/assets/jpg/background-home.jpg'

export const ContainerLogin = styled.div`
  background: url(${banner});
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  
  .content-page {
    width: 100%;
    margin: 0 auto;
    max-width: 500px;
    padding: 20px;
    border-radius: 5px;
  }
  
  #logo {
    text-align: center;
    
    img {
      width: 300px;
    }
  }
  
  #register {
    margin-top: 20px;
    font-size: 0.8em;
    text-align: center;
  }
  
  #forget-pass {
    text-align: right;
    font-size: 0.8em;
    margin-bottom: 20px;
  }
`

export const Form = styled.form`
  width: 100%;
  margin: 10px 0;
`

export const ErrorStyled = styled.div`
  margin-top: 10px;
  text-align: center;
`
