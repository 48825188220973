import React from 'react'
import Container from '../../../components/Container'
import TitleSection from '../../../components/TitleSection'
import { api } from '../../../services/api'
import { useLoading } from '../../../hooks/useLoading'
import { CardContainer, AddCard } from './styles'
import AddCardIcon from '@mui/icons-material/AddCard'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { useSnackbar } from 'notistack'

interface CardsMp {
    id: string;
    cardholder: {
        identification: {
            number: string;
            type: string;
        };
    };
    expiration_month: string;
    expiration_year: string;
    first_six_digits: string;
    last_four_digits: string;
    issuer: {
        id: string;
        name: string;
    };
    security_code: {
        length: number;
    },
    payment_method: {
        thumbnail: string;
        secure_thumbnail: string;
    }
}

const Cartoes: React.FC = () => {
  const [cards, setCards] = React.useState<CardsMp[]>()
  const [defaultCard, setDefaultCard] = React.useState<string>()
  const [value, setValue] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [cardSelect, setCardSelect] = React.useState<string>()

  const { showLoading, closeLoading } = useLoading()
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    getCards()
  }, [])

  const handleClickOpen = (card_id: string) => {
    setCardSelect(card_id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getCards = async () => {
    try {
      showLoading()

      const { data: dataCards } = await api.get('mercado-pago/client/cards')

      setCards(dataCards.data.cards)
      setDefaultCard(dataCards.data.default_card)
      setValue(dataCards.data.default_card)
    } catch (error) {

    } finally {
      closeLoading()
    }
  }

  const deleteCard = async () => {
    try {
      showLoading()

      await api.delete(`mercado-pago/client/card/${cardSelect}`)

      getCards()

      enqueueSnackbar('Cartão Deletado!', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Erro ao deleter o cartão', { variant: 'error' })
    } finally {
      closeLoading()
      handleClose()
    }
  }

  const updateCard = async (id: string) => {
    try {
      showLoading()

      await api.put(`mercado-pago/client/card/${id}`)
      setDefaultCard(id)

      enqueueSnackbar('Cartão principal selecionado!', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Erro ao selecionar o cartão principal', { variant: 'error' })

      getCards()
    } finally {
      closeLoading()
    }
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
    updateCard((event.target as HTMLInputElement).value)
  }

  return (
        <>
            <TitleSection text="Meus Cartões"/>
            <Container>
                <AddCard>
                    <AddCardIcon />
                    <a href="/perfil/cartoes/adicionar">ADICIONAR CARTÃO</a>
                </AddCard>
                <CardContainer>
                    {(cards && cards.length > 0)
                      ? cards.map(card => (
                            <div className="card" key={card.id}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-error-radios"
                                        name="card"
                                        value={value}
                                        onChange={handleRadioChange}
                                    >
                                        <FormControlLabel value={card.id} control={<Radio />} label="Cartão Princípal" />
                                    </RadioGroup>
                                </FormControl>
                                <div className="header">
                                    <img src={card.payment_method.secure_thumbnail}/>
                                    <p className="issuer">{card?.issuer.name}</p>
                                </div>
                                <p className="number">{card?.first_six_digits} **** **** {card?.last_four_digits}</p>
                                <p className="expire"><b>Expiração:</b> {card?.expiration_month}/{card?.expiration_year}</p>
                                <div className="footer">
                                    {defaultCard !== card.id && (<DeleteForeverIcon className="pointer" onClick={() => handleClickOpen(card.id)} />)}
                                </div>
                            </div>
                      ))
                      : (
                            <div>
                                <h1>Você não tem cartões cadastrados!</h1>
                            </div>
                        )}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Excluir Cartão de Crédito/Débito
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Essa cartão será excluido e não será possível recupera-lo.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cencelar</Button>
                            <Button onClick={deleteCard} autoFocus>
                                Excluir
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CardContainer>
            </Container>
        </>
  )
}

export default Cartoes
