import styled from 'styled-components'

interface PropsHeader{
  inicio: boolean
}

export const Header = styled.header<PropsHeader>`
  padding: 20px 0;
  background-color: var(--white);
  border-bottom: 1px solid #ededed;
  
  @media (min-width: 64.5em) {
    transition: all .3s ease 0s;
  }

  @media (min-width: 64.5em) {
    flex: 1;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 100px;
    padding: 0;
    z-index: 999;    
    -webkit-box-shadow: -1px 0px 8px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 0px 8px -5px rgba(0,0,0,0.75);
    box-shadow: -1px 0px 8px -5px rgba(0,0,0,0.75);
    
    ${props => props.inicio &&
      `width: 350px;
      
      a{
        width: 100% !important;
      }

      span{
        opacity: 1 !important;
        left: 0 !important;
      }`
}

    span{
      @media (min-width: 64.5em) {
        transition: all .3s ease 0s;
      }
      position: relative;
      left: -50px;
      opacity: 0;
      width: 350px;
    }
      
    &:hover{
      width: 350px;
      
      a{
        width: 100%;
      }

      span{
        opacity: 1;
        left: 0;
      }
    }
  }
`

export const Title = styled.span`
  display: none;
  
  @media (min-width: 64.5em) {
    font-size: 1em;
    min-width: 350px;
    display: block; 
    font-weight: bold;
    color: #ccc;
    display: block;
  }
`

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (min-width: 64.5em) {
    display: block;
  }
`

export const Logo = styled.h1`
  font-size: 0em; 

  img {
    width: 80px;
  }

  @media (min-width: 64.5em) {
    min-height: 80px;
    min-width: 110px;    
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
`

export const Content = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: var(--max-width-content);

  @media (min-width: 64.5em) {
    width: 100%;
    margin: 0;  
  }
`

export const Clear = styled.div`
  clear: both;
`

export const HrVert = styled.div`
  display: inline-block;
  width: 1px;
  height: 40px;
  margin: 0 20px;
  background-color: #ededed;
`

interface PropsOptions {
    show: boolean
}

export const MenuOptions = styled.ul<PropsOptions>`
  display: ${props => props.show ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;
  padding: 80px 0 40px 0;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  li {
    list-style: none;
    display: flex;
    align-items: center;
  }

  a {
    display: inline-block;
    padding: 12px 20px;   
    min-height: 50px;
    width: 100%;
    max-width: 250px;
    display: flex;
    margin: 10px 0;
    font-weight: bold;
    border-radius: 1000px;
    align-items: center;
    color: #666;
    text-decoration: none;
    font-size: 0.875rem;
    overflow: hidden;
    
    @media (min-width: 64.5em) {
      transition: all .3s ease 0s;
      font-size: 0.8em;
    }

    svg {
      @media (min-width: 64.5em) {
        transition: 0.5s fill;  
      }

      width: 30px;
      margin-right: 20px;
    }

    &:hover {
      background-color: #f1f1f1;
    }

    &.selected {
      background-color: rgba(162, 155, 254, .2);
      color: rgba(108, 92, 231, 1.0);

      svg {
        fill: rgba(108, 92, 231, 1.0);
      }
    }    

    @media (min-width: 64.5em) {
      width: 75px;
      max-width: 100%;
      align-items: flex-start;      
    }
  }

  @media (min-width: 64.5em) {
    display: block !important;
    position: static;
    height: auto;
    max-height: calc(100vh - 80px);
    overflow: auto;
    padding: 0 10px;   
    transform: inherit !important;
    visibility: inherit !important;    
  }
`

export const ToolBar = styled.div<PropsOptions>`
  display: flex;
  align-items: center;

  svg {
    position: ${props => props.show ? 'fixed' : 'relative'};
    z-index: 1001;
    cursor: pointer;
    background-color: ${props => props.show ? '#f1f1f1' : 'transparent'};
    border-radius: 3px;
  }

  @media (min-width: 64.5em) {
    display: none;
  }
`

export const Division = styled.div<PropsHeader>`
  display: flex;
  flex-direction: column;

  @media (min-width: 64.5em) {
    padding-left: ${props => props.inicio ? '400px' : '150px'};
    flex-direction: column;
  }
`

export const DivContent = styled.div`
  flex: 1;
  background-color: rgba(223, 230, 233,.4);
  min-height: calc(100vh - 80px);

  @media (min-width: 64.5em) {
    position: relative;
    margin: 90px 50px 0 0;
    border-radius: 30px;
  }
`

export const Hr = styled.div`
  display: none;

  @media (min-width: 64.5em) {
    display: block;
    height: 1px;
    background-color: #ededed;
    width: calc(100% - 10px);
    margin: 0 auto;
    border: 0;
  }  
`
