import styled from 'styled-components'

export const TableStyled = styled.div`
  width: 100%;
  height: 400px;
  background-color: #fff;
  margin-top: 20px;
  
  .link-default {
    color: #2980b9;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .link-warning {
    color: #e74c3c;

    &:hover {
      color: #c0392b;
      text-decoration: underline;
    }
  }
`
