export const cnpjCpfMask = (value: string) => {
  let maskCpfCnpj

  if (value.replace(/\D/g, '').length >= 12) {
    maskCpfCnpj = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
  } else {
    maskCpfCnpj = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }

  return maskCpfCnpj
}

export const cellphoneTelephoneMask = (value: string) => {
  let maskCellphoneTelephone

  if (value.replace(/\D/g, '').length >= 11) {
    maskCellphoneTelephone = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  } else {
    maskCellphoneTelephone = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }

  return maskCellphoneTelephone
}

export function formatStringPlayer(value: any) {
  let goals = "";
  let cards = "";

  value.goals.map((goal: any, index: any) => {
    goals += `${goal.time} do ${goal.period}{${goal.gol}}` + (index !== value.goals.length - 1 ? ';' : '');
  });

  value.cards.map((card: any) => {
    cards += `{${card.card} ${card.time} do ${card.period}}`;
  });

  return {goals: goals ? `[${goals}]` : '', cards}
}

export const getPlayerString = (query: string) => {
  let playerName = "";
  let playerDesc = "";
  let playerInfos = "";

  const desc = query.split('|');
  if (desc.length !== 1) {
    playerName = desc[0];
    const infos = desc[1].split("*");
    if (infos.length !== 1) {
      playerDesc = infos[0];
      playerInfos = infos[1];
    } else {
      playerDesc = infos[0];
    }
  } else {
    const infos = query.split("*");
    if (infos.length !== 1) {
      playerName = infos[0];
      playerInfos = infos[1];
    } else {
      playerName = query;
    }
  }

  return {name: playerName, desc: playerDesc, infos: playerInfos};
}