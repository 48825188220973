import React from 'react'
import Container from '../../../components/Container'
import TitleSection from '../../../components/TitleSection'
import { api } from '../../../services/api'
import { useLoading } from '../../../hooks/useLoading'
import { useSnackbar } from 'notistack'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import MyButton from '../../../components/Button'
import moment from 'moment'
import { SignatureContainer, Status } from './styles'
import BallKing from '../../../assets/png/ball-king.png'
import { useNavigate } from 'react-router-dom'

interface Signature {
    expire_at: string;
    status_mp: string;
}

const Assinatura: React.FC = () => {
  const [signature, setSignature] = React.useState<Signature>()
  const [activeSignature, setSignatureActive] = React.useState<boolean>()
  const [open, setOpen] = React.useState(false)

  const { showLoading, closeLoading } = useLoading()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  React.useEffect(() => {
    getSignatures()
  }, [])

  const getSignatures = async () => {
    try {
      showLoading()

      const { data: signatureData } = await api.get('mercado-pago/client/signature')

      if (signatureData.data.length === 0) {
        navigate('/planos')
      }

      setSignature(signatureData.data)
      setSignatureActive(!!signatureData.data)
    } catch (error) {

    } finally {
      closeLoading()
    }
  }

  const cancelSignature = async () => {
    try {
      showLoading()

      await api.delete('mercado-pago/client/signature')

      getSignatures()

      enqueueSnackbar('Assinatura cancelada!', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Erro ao cancelar assinatura! Entre em contato conosco.', { variant: 'error' })
    } finally {
      closeLoading()
      handleClose()
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
        <>
            <TitleSection text="Assinatura"/>
            <Container>
                {signature?.status_mp && (
                    <SignatureContainer>
                        <div className="header">
                            <img src={BallKing}/>
                            <h1>Informações de sua Assinatura</h1>
                        </div>
                        <p><strong>Status:</strong> <Status name={signature.status_mp}>{signature.status_mp}</Status></p>
                        {(signature.status_mp === 'Cancelado' && activeSignature) && (
                            <p><strong>Assinatura válida até:</strong> {signature.expire_at ? moment(signature.expire_at).format('DD/MM/YYYY') : ''}</p>
                        )}
                        <p><strong>Periodo:</strong> Anual</p>
                        {signature.status_mp !== 'Cancelado'
                          ? (
                                <div className="footer">
                                    <MyButton color="secondary" onClick={handleClickOpen}>Cancelar</MyButton>
                                </div>
                            )
                          : (
                                <p><strong>Observação:</strong> Você Cancelou sua assinatura, sendo assim, seu acesso será suspenso em {signature.expire_at ? moment(signature.expire_at).format('DD/MM/YYYY') : ''}</p>
                            )}
                    </SignatureContainer>
                )}

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Cancelar Assinatura
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Você está prestes a cancelar sua assinatura Anual no Pelejas! Tem certeza que deseja realizar essa ação ? Tenha em mente que não será possível assinar novamente antes de {moment(signature?.expire_at).format('DD/MM/YYYY')}.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cencelar</Button>
                        <Button onClick={cancelSignature} autoFocus>
                            Confirmar Cancelamento
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
  )
}

export default Assinatura
