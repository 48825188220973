import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .card {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 30px 20px;
    flex: 1;
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;
    color: #222;
    font-weight: bold;
    display: flex;
    align-items: center;
    
    svg {
      margin-right: 10px;
    }
  }
`

export const AddCard = styled.div`
  display: flex;
  
  svg {
    margin-right: 10px;
  }
  
  a {
    color: #222;
    font-weight: bold;
  }
  
  margin-bottom: 20px;
`
