import React from 'react'
import Container from '../../../components/Container'
import Table from '../../../components/Table'
import { GridRowData, GridColDef } from '@mui/x-data-grid'
import { api } from '../../../services/api'
import { UserProps } from '../../../interfaces/user'
import { UserContext } from '../../../UserContext'

export interface Signatures {
    id: number
    external_reference: string
    expire_at: any
    next_payment_date_mp: any
    created_at: any
    updated_at: any
    user_id: number
    plan_id: number
    payer_id_mp: any
    status_mp: string
    id_signature_mp: string
    deleted_at: any
}

interface User extends UserProps {
    signatures?: Signatures;
}

const Usuarios: React.FC = () => {
  const [rows, setRows] = React.useState<GridRowData[]>([])

  const { setLoading } = React.useContext(UserContext)

  React.useEffect(() => {
    getUsers()

    // eslint-disable-next-line
    }, []);

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'ID', flex: 1, minWidth: 150 },
    { field: 'col2', headerName: 'Nome', flex: 1, minWidth: 150 },
    { field: 'col3', headerName: 'E-mail', flex: 1, minWidth: 150 },
    { field: 'col4', headerName: 'Desativado em', flex: 1, minWidth: 150 },
    {
      field: 'col5',
      headerName: 'Status Plano',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                        {params.row.col10
                          ? (
                                <span>{params.row.col10} ({params.row.col9 ? 'Mercado Pago' : 'Manual'})</span>
                            )
                          : (
                                <span>Sem Plano</span>
                            )}
                    </div>
        )
      }
    },
    {
      field: 'col6',
      headerName: 'Ação',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="link-default" onClick={() => changeStatus(params.row.col1)}>{!params.row.col4 ? 'Desativar' : 'Ativar'}</span>
                    </div>
        )
      }
    },
    {
      field: 'col7',
      headerName: 'Ação',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="link-warning" onClick={() => deleteUser(params.row.col1)}>Deletar</span>
                    </div>
        )
      }
    },
    { field: 'col9', headerName: 'Id Pagamedor MP', flex: 1, minWidth: 150, hide: true },
    { field: 'col10', headerName: 'Plano', flex: 1, minWidth: 150, hide: true },
    {
      field: 'col11',
      headerName: 'Ação',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="link-default" onClick={() => handlePlans(params.row.col1)}>{!params.row.col10 ? 'Ativar' : 'Desativar'} Plano</span>
                    </div>
        )
      }
    }
  ]

  const changeStatus = async (id: number) => {
    try {
      setRows([])

      await api.patch(`/users/${id}`)
    } catch (error) {

    } finally {
      getUsers()
    }
  }

  const deleteUser = async (id: number) => {
    try {
      setRows([])

      await api.delete(`/users/${id}`)
    } catch (error) {

    } finally {
      getUsers()
    }
  }

  const handlePlans = async (id: number) => {
    try {
      setRows([])

      await api.post(`/plans/${id}`)
    } catch (error) {

    } finally {
      getUsers()
    }
  }

  const getUsers = async () => {
    try {
      setRows([])
      setLoading(true)

      const { data } = await api.get('/users')

      const users = data.users.map((el: User) => {
        const data = el.deleted_at ? new Intl.DateTimeFormat('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(el.deleted_at)) : ''

        return {
          id: el.id,
          col1: el.id,
          col2: el.nome,
          col3: el.email,
          col4: data,
          col9: el.signatures?.payer_id_mp,
          col10: el.signatures?.status_mp
        }
      })

      setRows(users)
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  return (
        <Container>
            <Table rows={rows} columns={columns}/>
        </Container>
  )
}

export default Usuarios
