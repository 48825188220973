import Container from '../../../components/Container'
import TitleSection from '../../../components/TitleSection'
import { Hr, Title, MeansPayment, InfoPayment } from './styles'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import PixIcon from '@mui/icons-material/Pix'
import React from 'react'
import { Link } from 'react-router-dom'

const MeiosDePagamento: React.FC = () => {
  return (
        <>
            <TitleSection text="ASSINATURA DO PLANO ANUAL"/>
            <Container className="bg-white-1" tag="main">
                <Title>SELECIONE A FORMA DE PAGAMENTO</Title>
                <MeansPayment>
                    <Link title="PAGAMENTO VIA PIX" to="/assinatura/pagamento/pix">
                        <li><PixIcon /> PIX</li>
                    </Link>
                    <a title="PAGAMENTO VIA CARTÃO DE CRÉTIDO/DÉBITO" href="/assinatura/pagamento/cartao">
                        <li><CreditCardIcon /> CARTÃO CRÉDITO/DÉBITO</li>
                    </a>
                </MeansPayment>
                <Hr />
                <Title>DIFERENÇAS ENTRE AS FORMAS DE PAGAMENTO</Title>
                <InfoPayment>
                    <li><PixIcon /> <strong>PIX</strong> -  Aprovação imadiata! seu plano é liberado após o pagamento. Sem pagamento automatico.</li>
                    <li><CreditCardIcon /> <strong>Cartão de Crédito</strong> -  Aprovação imadiata! seu plano é liberado alguns minutos após o pagamento. Com pagamento automatico!</li>
                    <li><CreditCardIcon /> <strong>Cartão de Débito (Virtual Caixa)</strong> -  Aprovação imadiata! seu plano é liberado após o pagamento. Sem pagamento automatico.</li>
                </InfoPayment>
            </Container>
        </>
  )
}

export default MeiosDePagamento
