import styled from 'styled-components'

export const ButtonSubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  
  button {
    margin-top: 20px;
    margin-left: 20px;
    max-width: 200px;
    padding: 5px 10px;
    font-size: 1em;
  }
`
