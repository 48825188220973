import React from 'react'
import Container from '../../../components/Container'
import { Form, ButtonContainer } from './styles'
import { Formik, FormikValues } from 'formik'
import Input from '../../../components/Input'
import Alert from '@mui/material/Alert'
import Button from '../../../components/Button'
import { useLoading } from '../../../hooks/useLoading'
import { api } from '../../../services/api'
import { useSnackbar } from 'notistack'
import TitleSection from '../../../components/TitleSection'
import getCep from 'cep-promise'
import MaskedInput from 'react-text-mask'
import { cellphoneTelephoneMask, cnpjCpfMask } from '../../../helpers'
import { Label, InputContainer } from '../../../components/Input/styles'
import { useNavigate } from 'react-router-dom'

interface FormFieldsError {
    cpf_cnpj?: string;
    telephone_cellphone?: string;
    cep?: string;
    logradouro?: string;
    number?: string;
    bairro?: string;
    cidade?: string;
    uf?: string;
}

const InformacoesPessoais: React.FC = () => {
  const [loadClient, setLoadingCLient] = React.useState(false)

  const { showLoading, closeLoading, loading } = useLoading()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    clientExist()
  }, [])

  const clientExist = async () => {
    try {
      showLoading()

      const { data: dataClient } = await api.get('mercado-pago/client')

      if (dataClient.data.id) {
        navigate('/assinatura/meios-de-pagamento', { replace: true })
      }
    } catch (error) {

    } finally {
      closeLoading()
      setLoadingCLient(true)
    }
  }

  const handleForm = async (values: FormikValues) => {
    try {
      showLoading()

      await api.post('/mercado-pago/client', {
        cpf_cnpj: values.cpf_cnpj,
        telephone_cellphone: values.telephone_cellphone,
        cep: values.cep,
        logradouro: values.logradouro,
        number: values.number,
        bairro: values.bairro,
        cidade: values.cidade,
        uf: values.uf
      })

      navigate('/assinatura/meios-de-pagamento', { replace: true })
    } catch (e: any) {
      if (e.status === 400) {
        e.errors.map((error: string) => enqueueSnackbar(error, { variant: 'error' }))
      } else if (e.status === 500) {
        enqueueSnackbar('Erro ao cadastrar usuário no MercadoPago.', { variant: 'error' })
      }
    } finally {
      closeLoading()
    }
  }

  const handleCep = async (cep: string) => {
    return await getCep(cep.replace(/\D/g, ''))
  }

  if (!loadClient) {
    return <></>
  }

  return (
        <>
            <TitleSection text="ASSINATURA - INFORMAÇÕES PESSOAIS"/>

            <Container tag="main">
                <div className="content-page bg-white">
                    <Formik
                        initialValues={{ cpf_cnpj: '', telephone_cellphone: '', cep: '', logradouro: '', number: '', bairro: '', cidade: '', uf: '' }}
                        validate={values => {
                          const errors: FormFieldsError = {}

                          if (!values.cpf_cnpj) {
                            errors.cpf_cnpj = 'Campo obrigatório!'
                          }

                          if (!values.telephone_cellphone) {
                            errors.telephone_cellphone = 'Campo obrigatório!'
                          }

                          if (!values.cep) {
                            errors.cep = 'Campo obrigatório!'
                          }

                          if (!values.logradouro) {
                            errors.logradouro = 'Campo obrigatório!'
                          }

                          if (!values.number) {
                            errors.number = 'Campo obrigatório!'
                          }

                          if (!values.bairro) {
                            errors.bairro = 'Campo obrigatório!'
                          }

                          if (!values.cidade) {
                            errors.cidade = 'Campo obrigatório!'
                          }

                          if (!values.uf) {
                            errors.uf = 'Campo obrigatório!'
                          }

                          return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          handleForm(values)
                        }}
                    >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                          /* and other goodies */
                        }) => {
                          const cepOnBlur = (e: React.FocusEvent<any>) => {
                            if (values.cep.replace(/\D/g, '').length === 8) {
                              showLoading()

                              handleCep(values.cep).then((response: any) => {
                                values.logradouro = response.street
                                values.uf = response.state
                                values.cidade = response.city
                                values.bairro = response.neighborhood
                              }).finally(() => {
                                closeLoading()
                              })
                            }

                            handleBlur(e)
                          }

                          return (<Form onSubmit={(e: React.FormEvent) => {
                            e.preventDefault()
                            handleSubmit()
                          }}>
                                <div className="division">
                                    <div>
                                        <MaskedInput
                                            type="text"
                                            name="cpf_cnpj"
                                            id="cpf_cnpj"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.cpf_cnpj}
                                            placeholder="NÚMERO DO DOCUMENTO"
                                            required
                                            guide={false}
                                            render={(ref: any, props) => (
                                                <InputContainer>
                                                    <Label htmlFor="cpf_cnpj">CPF/CNPJ:</Label>
                                                    <input className="input" ref={ref} {...props} />
                                                </InputContainer>
                                            )}
                                            mask={cnpjCpfMask}
                                        />
                                        {errors.cpf_cnpj && touched.cpf_cnpj && (
                                            <Alert severity="error">{errors.cpf_cnpj}</Alert>
                                        )}
                                    </div>
                                    <div>
                                        <MaskedInput
                                            type="text"
                                            name="telephone_cellphone"
                                            id="telephone_cellphone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.telephone_cellphone}
                                            placeholder="(__) _____-____"
                                            required
                                            guide={false}
                                            render={(ref: any, props) => (
                                                <InputContainer>
                                                    <Label htmlFor="cellphone">TEL/CEL PARA CONTATO:</Label>
                                                    <input className="input" ref={ref} {...props} />
                                                </InputContainer>
                                            )}
                                            mask={cellphoneTelephoneMask}
                                        />
                                        {errors.telephone_cellphone && touched.telephone_cellphone && (
                                            <Alert severity="error">{errors.telephone_cellphone}</Alert>
                                        )}
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <MaskedInput
                                            type="text"
                                            name="cep"
                                            autoComplete="some-random-name"
                                            id="cep"
                                            onChange={handleChange}
                                            onBlur={cepOnBlur}
                                            value={values.cep}
                                            placeholder="CEP"
                                            required
                                            guide={false}
                                            render={(ref: any, props) => (
                                                <InputContainer>
                                                    <Label htmlFor="cep">CEP:</Label>
                                                    <input className="input" ref={ref} {...props} />
                                                </InputContainer>
                                            )}
                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                        />
                                        {errors.cep && touched.cep && (
                                            <Alert severity="error">{errors.cep}</Alert>
                                        )}
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <Input
                                            type="text"
                                            name="logradouro"
                                            id="logradouro"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.logradouro}
                                            label="Logradouro:"
                                            placeholder="Logradouro"
                                            required
                                            disabled
                                        />
                                        {errors.logradouro && touched.logradouro && (
                                            <Alert severity="error">{errors.logradouro}</Alert>
                                        )}
                                    </div>
                                    <div>
                                        <Input
                                            type="number"
                                            name="number"
                                            id="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.number}
                                            label="N°"
                                            placeholder="Numero"
                                            required
                                        />
                                        {errors.number && touched.number && (
                                            <Alert severity="error">{errors.number}</Alert>
                                        )}
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <Input
                                            type="text"
                                            name="bairro"
                                            id="bairro"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.bairro}
                                            label="Bairro:"
                                            placeholder="Bairro"
                                            required
                                            disabled
                                        />
                                        {errors.bairro && touched.bairro && (
                                            <Alert severity="error">{errors.bairro}</Alert>
                                        )}
                                    </div>
                                    <div>
                                        <Input
                                            type="text"
                                            name="cidade"
                                            id="cidade"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.cidade}
                                            label="Cidade:"
                                            placeholder="Cidade"
                                            required
                                            disabled
                                        />
                                        {errors.cidade && touched.cidade && (
                                            <Alert severity="error">{errors.cidade}</Alert>
                                        )}
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <Input
                                            type="text"
                                            name="uf"
                                            id="uf"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.uf}
                                            label="UF:"
                                            placeholder="UF"
                                            required
                                            disabled
                                        />
                                        {errors.uf && touched.uf && (
                                            <Alert severity="error">{errors.uf}</Alert>
                                        )}
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <ButtonContainer>
                                    <Button disabled={loading} type="submit">
                                        {loading ? 'Carregando' : 'Avançar'}
                                    </Button>
                                </ButtonContainer>
                            </Form>)
                        }}
                    </Formik>
                </div>
            </Container>
        </>
  )
}

export default InformacoesPessoais
