import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../pages/login'
import Home from '../pages/inicio'
import Cadastro from '../pages/cadastro'
import EsqueciMinhaSenha from '../pages/esqueci-minha-senha'
import Link from '../pages/cadastro/link'
import Sair from '../pages/sair/index.page'
import Planos from '../pages/planos'
import LinkEsqueciMinhaSenha from '../pages/esqueci-minha-senha/link'
import RedefinirMinhaSenha from '../pages/esqueci-minha-senha/redefinir/[token]/[email]'
import LinkEsqueciMinhaSenhaSucesso from '../pages/esqueci-minha-senha/redefinir/sucesso'
import Blog from '../pages/blog'
import Artigo from '../pages/blog/artigo/[id]'
import MinhaConta from '../pages/minha-conta'
import FichasTecnicasRoutes from './DataSheet'
import SignaturesRoutes from './Signatures'
import PerfilRoutes from './Perfil'
import Footer from '../components/Footer'
import Header from '../components/Header'

const JustNotLoginRoutes = () => {
  return (
        <>
            <Header/>
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/inicio" element={<Home />}/>
                <Route path="/entrar" element={<Login />}/>
                <Route path="/sair" element={<Sair />}/>
                <Route path="/cadastro" element={<Cadastro />}/>
                <Route path="/planos" element={<Planos />}/>
                <Route path="/cadastro/link" element={<Link />}/>

                <Route path="/esqueci-minha-senha" element={<EsqueciMinhaSenha/>}/>
                <Route path="/esqueci-minha-senha/link" element={<LinkEsqueciMinhaSenha/>}/>
                <Route path="/esqueci-minha-senha/redefinir/:token/:email" element={<RedefinirMinhaSenha/>}/>
                <Route path="/esqueci-minha-senha/redefinir/sucesso" element={<LinkEsqueciMinhaSenhaSucesso/>}/>

                <Route path="/blog" element={<Blog/>}/>
                <Route path="/blog/:page" element={<Blog/>}/>
                <Route path="/blog/artigo/:id" element={<Artigo/>}/>

                <Route path="/minha-conta" element={<MinhaConta />}/>
                <Route path="/fichas-tecnicas/*" element={<FichasTecnicasRoutes />}/>
                <Route path="/assinatura/*" element={<SignaturesRoutes />}/>

                <Route path="/perfil/*" element={<PerfilRoutes />}/>
            </Routes>
            <Footer/>
        </>
  )
}

export default JustNotLoginRoutes
