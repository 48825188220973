import { ContainerButton, ContainerFullHeight, ErrorStyled, Form } from '../styles'
import Container from '../../../../../components/Container'
import { Formik } from 'formik'
import Input from '../../../../../components/Input'
import Alert from '@mui/material/Alert'
import Button from '../../../../../components/Button'
import React from 'react'
import { useLoading } from '../../../../../hooks/useLoading'
import { api } from '../../../../../services/api'
import { useSnackbar } from 'notistack'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Logotipo from '../../../../../assets/png/logo.png'

interface FormFieldsError {
    password?: string;
    password_confirmation?: string;
}

const RedefinirMinhaSenha: React.FC = () => {
  const [error, setError] = React.useState('')

  const { showLoading, closeLoading, loading } = useLoading()
  const { enqueueSnackbar } = useSnackbar()

  const { token, email } = useParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    verifyToken(token, email)
  }, [])

  const verifyToken = async (token: string | undefined, email: string | undefined) => {
    try {
      showLoading()
      if (!token || !email) return

      await api.post('/reset-password/verify-token', {
        email,
        token
      })
    } catch (e: any) {
      navigate('/not-found', { replace: true })
    } finally {
      closeLoading()
    }
  }

  const handleSnackbar = (message: string) => {
    enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
  }

  const handleSubmit = async (password: string, password_confirmation: string) => {
    try {
      showLoading()

      await api.post('/reset-password', {
        token,
        email,
        password,
        password_confirmation
      })

      navigate('/esqueci-minha-senha/redefinir/sucesso', { replace: true })
    } catch (e: any) {
      if (e.status === 429) {
        handleSnackbar('Aguarde 60 segundos para tentar novamente.')
      } else if (e.status === 404) {
        handleSnackbar('Usuário não encontrado.')
      } else {
        handleSnackbar('Erro interno.')
      }
    } finally {
      closeLoading()
    }
  }

  return (
        <>
            <ContainerFullHeight>
                <Container tag="main">
                    <div className="content-page bg-white">
                        <Link to="/">
                            <div id="logo">
                                <img alt="Logotipo Pelejas" title="Logotipo Pelejas" src={Logotipo} />
                            </div>
                        </Link>
                        <Formik
                            initialValues={{ password: '', password_confirmation: '' }}
                            validate={values => {
                              const errors: FormFieldsError = {}

                              if (!values.password) {
                                errors.password = 'Campo obrigatório!'
                              } else if (
                                !/^[a-zA-Z0-9!@#$%^&*]{6,}$/i.test(values.password)
                              ) {
                                errors.password = 'A senha deve ter no minimo 6 caracteres.'
                              }

                              if (!values.password_confirmation) {
                                errors.password_confirmation = 'Campo obrigatório!'
                              } else if (
                                !/^[a-zA-Z0-9!@#$%^&*]{6,}$/i.test(values.password_confirmation)
                              ) {
                                errors.password_confirmation = 'A senha deve ter no minimo 6 caracteres.'
                              }

                              return errors
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                              if (values.password !== values.password_confirmation) {
                                setError('As senhas são diferentes!')
                                return
                              }

                              setError('')
                              handleSubmit(values.password, values.password_confirmation)
                            }}
                        >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting
                              /* and other goodies */
                            }) => (
                                <Form onSubmit={(e) => {
                                  e.preventDefault()
                                  handleSubmit()
                                }}>
                                    <Input
                                        type="password"
                                        name="password"
                                        id="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        label="Digite a nova senha:"
                                        placeholder="******"
                                    />
                                    {errors.password && touched.password && (
                                        <ErrorStyled>
                                            <Alert severity="error">{errors.password}</Alert>
                                        </ErrorStyled>
                                    )}

                                    <Input
                                        type="password"
                                        name="password_confirmation"
                                        id="password_confirmation"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password_confirmation}
                                        label="Confirme a senha:"
                                        placeholder="******"
                                    />
                                    {errors.password_confirmation && touched.password_confirmation && (
                                        <ErrorStyled>
                                            <Alert severity="error">{errors.password_confirmation}</Alert>
                                        </ErrorStyled>
                                    )}
                                    {error && (
                                        <ErrorStyled>
                                            <Alert severity="error">{error}</Alert>
                                        </ErrorStyled>
                                    )}
                                    <ContainerButton>
                                        <Button disabled={loading} type="submit">
                                            {loading ? 'Carregando' : 'Enviar'}
                                        </Button>
                                    </ContainerButton>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Container>
            </ContainerFullHeight>
        </>
  )
}

export default RedefinirMinhaSenha
