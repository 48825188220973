import React from 'react'
import Container from '../../../components/Container'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { api } from '../../../services/api'
import { useSnackbar } from 'notistack'
import { useLoading } from '../../../hooks/useLoading'
import { List, Options } from './styles'
import { useNavigate } from 'react-router-dom'

const Enquete: React.FC = () => {
  const [date, setDate] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [option, setOption] = React.useState('')
  const [options, setOptions] = React.useState<string[]>([])

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { showLoading, closeLoading } = useLoading()

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const handleOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOption(e.target.value)
  }

  const handleDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value)
  }

  const handleOptionSubmit = () => {
    setOptions(current => [...current, option])
    setOption('')
  }

  const handleSnackbar = (message: string) => {
    enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
  }

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault()

      if (options.length === 0) {
        handleSnackbar('Verifique os campos!')
        return
      }

      showLoading()

      await api.post('/survey', {
        question: title,
        expire_at: date,
        options
      })

      enqueueSnackbar('Enquete adicionada!', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })

      navigate('/backoffice/enquetes')
    } catch (e: any) {
      handleSnackbar('Verifique os campos!')
    } finally {
      closeLoading()
    }
  }

  return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Input required onChange={handleTitleChange} placeholder="Titulo da enquete" label="Titulo da enquete" value={title}/>
                <Input required type="date" onChange={handleDate} placeholder="Expiração" label="Expiração" value={date}/>
                <Options>
                    <Input onChange={handleOption} placeholder="Opções" label="Opções" value={option}/>
                    <Button type="button" onClick={handleOptionSubmit}>Adicionar opção</Button>
                </Options>
                {options && (
                    <List>
                        {options.map((item, index) => <li key={index}>{item}</li>)}
                    </List>
                )}
                <Button type="submit">Adicionar Enquete</Button>
            </form>
        </Container>
  )
}

export default Enquete
