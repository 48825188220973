import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowData,
  GridRowParams,
  GridSlotsComponent,
  GridSlotsComponentsProps, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton
} from '@mui/x-data-grid'
import React from 'react'
import { TableStyled } from './styled'

interface PropsTable {
  rows: GridRowData[],
  columns: GridColDef[],
  cellClick?: (params?: GridCellParams) => void,
  rowClassName?: (params?: GridRowParams) => string,
  components?: GridSlotsComponent,
  componentsProps?: GridSlotsComponentsProps,
  onClick?: (id: any) => void
}

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ padding: '10px', backgroundColor: '#eee' }}>
      <GridToolbarFilterButton placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const Table: React.FC<PropsTable> = ({
  rows,
  columns,
  cellClick = (params) => { },
  rowClassName = (params) => 'default',
  components,
  componentsProps,
  onClick = (id) => { }
}) => {
  return (
    <TableStyled>
      <DataGrid
        rows={rows}
        columns={columns}
        onCellClick={(params) => cellClick(params)}
        components={{
          Toolbar: CustomToolbar
        }}
        componentsProps={componentsProps}
        getRowClassName={
          (params) => rowClassName(params)
        }
        onRowClick={(e) => {
          const row = e

          if (row !== null) onClick(row)
        }}
      />
    </TableStyled>
  )
}

export default Table
