const theme = {
  breakpoints: {
    largeMobile: '(min-width: 480px)',
    tablet: '(min-width: 768px)',
    desktop: '(min-width: 1024px)'
  },
  colors: {
    /* NOVAS CORES DESIGN SYSTEM */
    backgroundDark: '#1a1a1a',
    backgroundWhite: '#fff'
  }
}

export default theme
