import React from 'react'
import {
  Header as HeaderStyle,
  Logo,
  Content,
  Clear,
  Nav,
  HrVert,
  MenuOptions,
  ToolBar,
  Title,
  Hr
} from './styled'
import Logotipo from '../../../../assets/png/logo.png'
import { CgMenu } from 'react-icons/cg'
import Avatar from '@mui/material/Avatar'
import Slide from '@mui/material/Slide'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import { BiUserMinus } from 'react-icons/bi'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { UserContext } from '../../../../UserContext'
import { ListItemIcon, ListItemText } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

const Header: React.FC = () => {
  const [display, setDisplay] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { userLogout, data } = React.useContext(UserContext)

  const navigate = useNavigate()
  const location = useLocation()

  const onButtonClick = () => {
    setDisplay(!display)
  }

  const handleLogout = () => {
    userLogout()
    navigate('/entrar', { replace: true })
  }

  const onCloseMenu = () => {
    setDisplay(false)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const toEdit = () => {
    handleClose()
    navigate('editar/perfil')
  }

  if (!data.id) return <></>

  return (
        <HeaderStyle inicio={location.pathname === '/backoffice/'}>
            <Content>
                <Nav>
                    <ToolBar show={display}>
                        <CgMenu
                            size={35}
                            color={'#666'}
                            onClick={onButtonClick}
                        />
                        <HrVert />
                        <Avatar
                            alt={data.nome}
                            onClick={handleClick}
                            src="/static/images/avatar/sdsdsds1.jpg"
                        />
                        <Menu
                            elevation={0}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center'
                            }}
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => toEdit()}>
                                <ListItemIcon>
                                    <PersonIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Editar Perfil" />
                            </MenuItem>
                            <MenuItem onClick={() => handleLogout()}>
                                <ListItemIcon>
                                    <ExitToAppIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Sair" />
                            </MenuItem>
                        </Menu>
                    </ToolBar>
                    <div className="ds-flex align-items-center">
                        <Logo>
                            <img src={Logotipo} title="Pelejas" alt="Pelejas" />
                        </Logo>
                        <Title>Pelejas</Title>
                    </div>
                    <Hr />
                    <Slide direction="right" in={display}>
                        <MenuOptions show={true}>
                            <NavLink
                                title="Garotas Pendentes"
                                onClick={onCloseMenu}
                                to={`${process.env.PUBLIC_URL}/backoffice/usuarios`}
                            >
                                <li>
                                    <BiUserMinus color={'#666'} size={20} />
                                    <span>USUÁRIOS</span>
                                </li>
                            </NavLink>
                            <NavLink
                                title="ADICIONAR POST BLOG"
                                onClick={onCloseMenu}
                                to={`${process.env.PUBLIC_URL}/backoffice/blog`}
                            >
                                <li>
                                    <AiOutlineUsergroupAdd
                                        color={'#666'}
                                        size={20}
                                    />
                                    <span>ADICIONAR POST AO BLOG</span>
                                </li>
                            </NavLink>
                            <NavLink
                                title="POSTS"
                                onClick={onCloseMenu}
                                to={`${process.env.PUBLIC_URL}/backoffice/blog/posts`}
                            >
                                <li>
                                    <AiOutlineUsergroupAdd
                                        color={'#666'}
                                        size={20}
                                    />
                                    <span>LISTAR POSTS DO BLOG</span>
                                </li>
                            </NavLink>
                            <NavLink
                                title="ESQUENTES"
                                onClick={onCloseMenu}
                                to={`${process.env.PUBLIC_URL}/backoffice/enquetes`}
                            >
                                <li>
                                    <AiOutlineUsergroupAdd
                                        color={'#666'}
                                        size={20}
                                    />
                                    <span>ENQUENTES</span>
                                </li>
                            </NavLink>
                            <NavLink
                                title="ENQUETE"
                                onClick={onCloseMenu}
                                to={`${process.env.PUBLIC_URL}/backoffice/enquete`}
                            >
                                <li>
                                    <AiOutlineUsergroupAdd
                                        color={'#666'}
                                        size={20}
                                    />
                                    <span>NOVA ENQUETE</span>
                                </li>
                            </NavLink>
                            <NavLink
                                title="LOGOTIPOS"
                                onClick={onCloseMenu}
                                to={`${process.env.PUBLIC_URL}/backoffice/logotipos`}
                            >
                                <li>
                                    <AiOutlineUsergroupAdd
                                        color={'#666'}
                                        size={20}
                                    />
                                    <span>LOGOTIPOS</span>
                                </li>
                            </NavLink>
                            <NavLink
                                title="FICHAS"
                                onClick={onCloseMenu}
                                to={`${process.env.PUBLIC_URL}/backoffice/fichas-tecnicas`}
                            >
                                <li>
                                    <AiOutlineUsergroupAdd
                                        color={'#666'}
                                        size={20}
                                    />
                                    <span>FICHAS TÉCNICAS</span>
                                </li>
                            </NavLink>
                        </MenuOptions>
                    </Slide>
                </Nav>
                <Clear />
            </Content>
        </HeaderStyle>
  )
}

export default Header
