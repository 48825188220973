import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 1.2em;
`

export const ContainerFlex = styled.div`
  display: flex;
  align-items: center;
  
  .icon {
    margin-right: 10px;

    @media (min-width: 71.1875em) {
      display: none;
    }
  }
`
