import React, { createContext, useContext, useState } from 'react'
import Loading from '../components/Loading'

interface LoadingContextData {
  showLoading: () => void;
  closeLoading: () => void;
  loading: boolean;
}

const LoadingContext = createContext<LoadingContextData>({} as LoadingContextData)

interface MyLoadingProps {
  children?: React.ReactNode;
}

const LoadingProvider: React.FC<MyLoadingProps> = ({ children }) => {
  const [open, setOpen] = useState(false)

  const showLoading = () => {
    setOpen(true)
  }

  const closeLoading = () => setOpen(false)

  return (
        <LoadingContext.Provider value={{ showLoading, closeLoading, loading: open }}>
            <Loading open={open}/>
            {children}
        </LoadingContext.Provider>
  )
}

function useLoading (): LoadingContextData {
  const context = useContext(LoadingContext)

  if (!context) {
    throw new Error('useLoading deve ser usado com o LoadingProvider')
  }

  return context
}

export { LoadingProvider, useLoading }
