import React from 'react'
import { api } from './services/api'
import secure from './config/secureLS'
import { UserProps } from './interfaces/user'

interface Props {
    login: null | boolean,
    userLogin: Function,
    data: UserProps,
    subscriber: boolean,
    error: string,
    loading: boolean | null,
    userLogout: Function,
    setLoading: Function,
}

interface PropsContainer {
    children?: React.ReactNode,
}

export const UserContext = React.createContext<Props>({
  userLogin: (email: string, password: string) => {
  },
  login: null,
  data: {},
  subscriber: false,
  error: '',
  loading: null,
  userLogout: () => {
  },
  setLoading: (state: boolean) => {
  }
})

export const UserStorage: React.FC<PropsContainer> = ({ children }) => {
  const [data, setData] = React.useState({})
  const [subscriber, setSubscriber] = React.useState(false)
  const [login, setLogin] = React.useState<boolean | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const userLogout = React.useCallback(async function () {
    setData({})
    setSubscriber(false)
    setError('')
    setLoading(false)
    setLogin(false)

    secure.clear()
    // eslint-disable-next-line
    }, []);

  React.useEffect(() => {
    async function autoLogin () {
      const token = secure.get('pelejas@access-token')

      if (token) {
        try {
          setError('')
          setLoading(true)

          await api.post('auth/me')

          await getUser()
        } catch (error: any) {
          userLogout()

          const result = error.error
          setError(result)
        } finally {
          setLoading(false)
        }
      } else {
        userLogout()
      }
    }

    autoLogin()
  }, [userLogout])

  async function getUser () {
    try {
      setLoading(true)

      const { data } = await api.post('auth/me')

      setData(data.user)
      setSubscriber(data.isSubscriber)
      setLogin(true)
    } catch (error: any) {
      const result = error.error
      setError(result)
    } finally {
      setLoading(false)
    }
  }

  async function userLogin (email: string, password: string) {
    try {
      setError('')
      setLoading(true)

      const { data } = await api.post('/auth/login', {
        password,
        email
      })

      secure.set('pelejas@access-token', { data: data.access_token })
      await getUser()
    } catch (error: any) {
      setLogin(false)

      const result = error.error
      setError(result)
    } finally {
      setLoading(false)
    }
  };

  return (
        <UserContext.Provider value={{
          userLogin,
          login,
          data,
          subscriber,
          error,
          loading,
          userLogout,
          setLoading
        }}>
            {children}
        </UserContext.Provider>
  )
}
