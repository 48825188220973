import styled from 'styled-components'

interface PropsHeader{
  inicio: boolean
}

export const Responsive = styled.div<PropsHeader>`
  display: none;

  @media (min-width: 64.5em) {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    width: ${props => props.inicio ? 'calc(100% - 350px)' : 'calc(100% - 100px)'};
    background-color: var(--white);
    height: 80px;
    z-index: 99;
  }
`

export const PageNow = styled.span`
  font-size: 2em;
  font-weight: bold;
  color: #666;
`

export const ContainerUserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 100px);
  margin: 0 auto;
`

export const Infos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 0.5em;
  text-transform: uppercase;

  div {
    margin-right: 20px;
  }

  p{
    font-size: 1.5em;
    color: #666;
  }
`
