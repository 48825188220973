import React from 'react'
import { UserContext } from '../../UserContext'
import { useNavigate } from 'react-router-dom'

const Sair: React.FC = () => {
  const { userLogout } = React.useContext(UserContext)

  const navigate = useNavigate()

  React.useEffect(() => {
    userLogout()
    navigate('/entrar', { replace: true })
  })

  return <h1>Encerrando Sessão...</h1>
}

export default Sair
