import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import Input from '../../../components/Input'
import Container from '../../../components/Container'
import Button from '../../../components/Button'
import { BlogContainer } from './styles'
import { api } from '../../../services/api'
import { useParams } from 'react-router-dom'
import { useLoading } from '../../../hooks/useLoading'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ButtonMui from '@mui/material/Button'

const Blog: React.FC = () => {
  const [title, setTitle] = React.useState('')
  const [text, setText] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const editorRef = React.useRef<any>(null)

  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { showLoading, closeLoading } = useLoading()
  const navigate = useNavigate()

  React.useEffect(() => {
    getPost(id)
  }, [id])

  const getPost = async (id?: string | undefined) => {
    if (!id) {
      setTitle('')
      setText('')
      return
    };

    try {
      showLoading()

      const { data } = await api.get(`/blog/${id}`)

      setTitle(data.post.titulo)
      setText(data.post.texto)
    } catch (e: any) {
      handleSnackbar('Ocorreu um erro ao obter os posts')
    } finally {
      closeLoading()
    }
  }

  const handleSubmit = async () => {
    try {
      showLoading()

      if (id) {
        await api.patch(`/blog/${id}`, {
          title,
          html: text
        })

        enqueueSnackbar('Post Editado!', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })

        return
      }

      const { data } = await api.post('/blog', {
        title,
        html: text
      })

      enqueueSnackbar('Post Adicionado!', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })

      navigate(`/backoffice/blog/${data.post}`)
    } catch (e: any) {
      handleSnackbar('Verifique os campos!')
    } finally {
      closeLoading()
    }
  }

  const handleSnackbar = (message: string) => {
    enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
  }

  const handleEditorChange = (content: string) => {
    setText(content)
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const deletePost = async () => {
    try {
      showLoading()

      await api.delete(`/blog/${id}`)

      enqueueSnackbar('Post Deletado!', { variant: 'success', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
      handleClose()

      navigate('/backoffice/blog/posts')
    } catch (error) {

    } finally {
      closeLoading()
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
        <Container>
            <BlogContainer>
                <Input onChange={handleTitleChange} placeholder="TITULO DO POST" label="TITULO" value={title}/>
                <Editor
                    apiKey="4qvjh8eh1z59c5at9ohyis7rt212hruzifqy7fumitj2epzf"
                    onInit={(evt: any, editor) => {
                      editorRef.current = editor
                    }}
                    onEditorChange={handleEditorChange}
                    value={text}
                    init={{
                      plugins: 'print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help  charmap quickbars emoticons',
                      tinydrive_token_provider: 'URL_TO_YOUR_TOKEN_PROVIDER',
                      tinydrive_dropbox_app_key: 'YOUR_DROPBOX_APP_KEY',
                      tinydrive_google_drive_key: 'YOUR_GOOGLE_DRIVE_KEY',
                      tinydrive_google_drive_client_id: 'YOUR_GOOGLE_DRIVE_CLIENT_ID',
                      mobile: {
                        plugins: 'print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons'
                      },
                      menu: {
                        tc: {
                          title: 'Comments',
                          items: 'addcomment showcomments deleteallconversations'
                        }
                      },
                      menubar: 'file edit view insert format tools table tc help',
                      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor  removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                      autosave_ask_before_unload: true,
                      autosave_interval: '30s',
                      autosave_prefix: '{path}{query}-{id}-',
                      autosave_restore_when_empty: false,
                      autosave_retention: '2m',
                      image_advtab: true,
                      link_list: [
                        { title: 'My page 1', value: 'https://www.tiny.cloud' },
                        { title: 'My page 2', value: 'http://www.moxiecode.com' }
                      ],
                      image_list: [
                        { title: 'My page 1', value: 'https://www.tiny.cloud' },
                        { title: 'My page 2', value: 'http://www.moxiecode.com' }
                      ],
                      image_class_list: [
                        { title: 'None', value: '' },
                        { title: 'Some class', value: 'class-name' }
                      ],
                      importcss_append: true,
                      templates: [
                        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                        { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                        { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                      ],
                      template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                      template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                      height: 600,
                      image_caption: true,
                      quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                      noneditable_noneditable_class: 'mceNonEditable',
                      toolbar_mode: 'sliding',
                      spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
                      content_style: '.mymention{ color: gray; }',
                      contextmenu: 'link image imagetools table configure',
                      a11y_advanced_options: true,
                      mentions_selector: '.mymention',
                      mentions_item_type: 'profile',
                      resize: false,
                      language: 'pt_BR',
                      mceInsertContent: false
                    }}
                />
                <div className="buttons">
                    {id && <Button className="remove" onClick={handleClickOpen}>EXCLUIR</Button>}
                    <Button onClick={handleSubmit}>Confirmar</Button>
                </div>
            </BlogContainer>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Excluir Post
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Você está excluindo uma postagem e não será possível recupera-la! Tem certeza desta ação?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonMui onClick={handleClose}>Cancelar</ButtonMui>
                    <ButtonMui onClick={deletePost} autoFocus>
                        Excluir
                    </ButtonMui>
                </DialogActions>
            </Dialog>
        </Container>
  )
}

export default Blog
