import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  margin: 10px 0;

  .label {
    font-weight: bold;
    font-size: 0.9em;
    margin-top: 20px;
    margin-bottom: 5px;
    display: block;
  }

  .label-medium {
    font-size: 1.1em;
  }

  .input-div-flex {
    display: flex;
    align-items: flex-start;

    > div {
      margin: 0;
    }
  }

  @media (min-width: 71.1875em) {
    #options-scoreboard {
      display: flex;

      & > div:last-of-type {
        margin-left: 100px;
      }
    }
  }

  #scoreboard {
    display: flex;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;

      .team-logo {
        display: flex;
        align-items: center;
      }

      #opponent-logo {
        margin-left: 10px;
      }

      #palmeiras-logo {
        margin-right: 10px;
      }

      img {
        max-width: 40px;
      }
    }

    & input {
      max-width: 100px;
      text-align: center;
    }

    & > span {
      display: block;
      margin: 0 10px;
      font-weight: bold;
    }
  }

  @media (min-width: 71.1875em) {
    .division {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      > div {
        flex: 1;
      }

      > div:first-of-type {
        margin-right: 20px;
      }
    }
  }
`;

export const Container = styled.div`
  padding: 40px;

  select {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
  }

  #image {
    margin-top: 20px;
    width: 50%;
    height: 500px;
    background-color: #ccc;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .options {
      position: absolute;
      bottom: 0;
      left: 0%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
  }
`;
