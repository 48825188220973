import React from 'react'
import Container from '../../../../components/Container'
import TitleSection from '../../../../components/TitleSection'
// import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet'
import secure from '../../../../config/secureLS'
import { UserContext } from '../../../../UserContext'
import { api } from '../../../../services/api'
import { useNavigate } from 'react-router-dom'
import { useLoading } from '../../../../hooks/useLoading'
import Loading from '../../../../components/Loading'

interface ClientData {
    email: string;
    identification: {
        type: string;
        number: string;
    }
}

const Adicionar: React.FC = () => {
  // const { enqueueSnackbar } = useSnackbar();
  const [client, setClient] = React.useState<ClientData | null>()

  const navigate = useNavigate()

  const { data } = React.useContext(UserContext)
  const { showLoading, closeLoading } = useLoading()

  const token = secure.get('pelejas@access-token')

  React.useEffect(() => {
    clientExist()
  }, [])

  const clientExist = async () => {
    try {
      showLoading()

      const { data: dataClient } = await api.get('mercado-pago/client')

      if (!dataClient.data.id) {
        navigate('/assinatura/informacoes-pessoais', { replace: true })
      }

      setClient(dataClient.data)
    } catch (err) {
      navigate('/inicio', { replace: true })
    } finally {
      closeLoading()
    }
  }

  return (
        <div>
            <TitleSection text="Adicionar Cartão"/>

            <Container tag="main">
                <div id="cardPaymentBrick_container"></div>
            </Container>

            <div id="loading">
                <Loading open={true}/>
            </div>

            {(data.id && client && client.email) && (
                <Helmet>
                    <script
                        type="text/javascript"
                    >
                        {`
                        function removeElement(id) {
                            var elem = document.getElementById(id);
                            return elem.parentNode.removeChild(elem);
                        }
                        
                        var mp = new MercadoPago('${process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY}', { 
                            locale: 'pt-BR' 
                        }); 
                        
                        var bricksBuilder = mp.bricks(); 
                        
                        var renderCardPaymentBrick = async (bricksBuilder) => { 

                        var settings = { 
                            initialization: { 
                              amount: 29.99,      
                              payer: { 
                                email: '${client.email}', 
                                identification: { 
                                    type: '${client.identification.type}', 
                                    number: '${client.identification.number}', 
                                }, 
                              },                 
                            },    
                            customization: { 
                                paymentMethods: { 
                                    minInstallments: 1, 
                                    maxInstallments: 1, 
                                }, 
                                visual: { 
                                    texts: { 
                                        formTitle: 'Cartão de crédito ou débito' ,
                                        formSubmit: 'Salvar Cartão'
                                    } 
                                } 
                            },                      
                            callbacks: { 
                              onReady: () => { 
                                removeElement('loading')                    
                              }, 
                              onSubmit: (cardFormData) => {   
                                return new Promise((resolve, reject) => { 
                                    fetch("${process.env.REACT_APP_API_URL}/mercado-pago/client/card", { 
                                        method: "POST", 
                                        headers: { 
                                            "Content-Type": "application/json", 
                                            "Authorization": "Bearer " + "${token.data}", 
                                        }, 
                                        body: JSON.stringify(cardFormData) 
                                    }) 
                                    .then((response) => { 
                                        // get payment result 
                                        if (response.status == 200){ 
                                            window.location.href = '/perfil/cartoes'; 
                                        }
                                        else { 
                                            window.location.href = '/perfil/cartoes/adicionar?error=true'; 
                                        } 
                                        
                                        resolve(); 
                                    }) 
                                    .catch((error) => { 
                                        // get payment result error 
                                        reject(); 
                                    }) 
                                }); 
                              }, 
                              onError: (error) => { 
                                // callback chamado para todos os casos de erro do Brick 
                              }, 
                            }, 
                          }; 
                          var cardPaymentBrickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings); 
                        }; 
                        
                        renderCardPaymentBrick(bricksBuilder);                         
                        `}
                    </script>
                </Helmet>
            )}
        </div>
  )
}

export default Adicionar
