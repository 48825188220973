import styled from 'styled-components'
import banner from '../../../src/assets/jpg/background-home.jpg'

export const ContainerLogin = styled.div`
  background: url(${banner});
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  
  .content-page {
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
    border-radius: 5px;
  }
  
  #logo {
    text-align: center;
    margin-top: 10px;
    
    img {
      width: 200px;
    }
  }
  
  #terms {
    margin: 5px 0;
    font-size: 0.8em;
    display: flex;
    align-items: center;
  }
  
  #forget-pass {
    text-align: right;
    font-size: 0.8em;
    margin-bottom: 20px;
  }
`

export const Form = styled.form`
  width: 100%;
  margin: 10px 0;

  .label {
    font-weight: bold;
    font-size: 0.9em;
    margin-top: 20px;
    display: block;
  }
  
  @media (min-width: 71.1875em) {
    .division {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      
      > div {
        flex: 1;
      }

      > div:first-of-type {
        margin-right: 20px;
      }
    }
  }
`

export const ErrorStyled = styled.div`
  margin-top: 10px;
  text-align: center;
`

export const ButtonContainer = styled.div`
  text-align: right;
  padding-top: 20px;
  margin-top: 10px;
  border-top: 1px solid #eee;
  
  button {
    padding: 10px;
    max-width: 200px;
  }
`
