import PageMessageDialog from '../../../../components/PageMessageDialog'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'

const Link: React.FC = () => {
  return (
        <>
            <PageMessageDialog
                icon={<CheckCircleIcon sx={{ fontSize: '80px', color: '#27ae60' }} />}
                title="Senha Redefinida"
                text={<p><LinkRouter to="/entrar">Clique aqui</LinkRouter> para fazer login.</p>}
            />
        </>
  )
}

export default Link
