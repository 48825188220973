import styled, { createGlobalStyle } from 'styled-components'

export const HeaderContainer = styled.footer`
  padding: 80px 0 !important;
  background-color: ${props => props.theme.colors.backgroundDark};
  height: var(--footer-height);
  
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const BodyPadding = createGlobalStyle`
  .App {
    padding-bottom: var(--footer-height);
  }
`

export const FooterContainer = styled.div`
  padding: 40px 0;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  .box {
    margin-bottom: 30px;
  }

  .box h1{
    font-size: 1.2em;
    margin-bottom: 10px;
    text-align: center;
  }
  
  #logo {
    text-align: center;
    margin-bottom: 20px;
  }
  
  img {
    max-width: 250px;
  }
  
  ul {
    list-style: none;
  }
  
  ul > a {
    text-align: center;
    margin-bottom: 20px;
    display: block;
    color: #fff;
    text-decoration: none;
    
    :last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: 734px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    padding: 5px 0;

    ul > a {
      margin-right: 40px;
      font-size: 0.8em;
      text-align: left;
    }

    #logo {
      text-align: center;
      margin-right: 40px;
    }

    .box {
      margin-bottom: 0px;
    }

    .box h1{
      text-align: left;
    }
  }
`

export const PagSeguroIcon = styled.div`
  &#mobile {
    display: none;
  }
  
  &#desktop {
    font-weight: normal;

    img {
      margin-bottom: 10px;
    }
  }
  
  margin-top: 20px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 0.7em;
  
  img {
    max-width: 200px;
  }
`

export const Copy = styled.div`
  margin-top: 50px;
  text-align: center;
  font-size: 0.8em;
`

export const MethodPayments = styled.div`
  padding: 20px 0;
  
  display: grid;
  
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  grid-gap: 10px;
  
  > div {
    max-width: 80px;
  }
  
  img {
    width: 100%;
  }
`
