import React from 'react'
import { ContainerStyled, ContentStyled, Dialog, Icon, Hr } from './styles'

interface PageMessageProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: React.ReactNode;
    title: string;
    text?: React.ReactNode;
};

const PageMessageDialog: React.FC<PageMessageProps> = ({ text, title, icon, ...props }) => {
  return (
        <ContainerStyled>
            <ContentStyled>
                {icon && (
                    <div>
                        <Icon>{icon}</Icon>
                    </div>
                )}
                <Dialog>
                    {title && (
                        <>
                            <h1>{title}</h1>
                            <Hr />
                        </>
                    )}

                    {text}
                </Dialog>
            </ContentStyled>
        </ContainerStyled>
  )
}

export default PageMessageDialog
