import React from 'react'
import {
  CancelIconStyled,
  CheckCircleIconStyled,
  ContainerPlans,
  ContainerPlansDescription,
  Terms
} from '../inicio/styles'
import PalmeirasChart from '../../assets/png/pal-chart.png'
import BallKing from '../../assets/png/ball.png'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import Ball from '../../assets/png/ball-king.png'
import Container from '../../components/Container'
import { UserContext } from '../../UserContext'

const Planos: React.FC = () => {
  const { loading, subscriber, data } = React.useContext(UserContext)

  const navigate = useNavigate()

  React.useEffect(() => {
    if (subscriber) {
      navigate('/perfil/assinatura', { replace: true })
    }
  }, [subscriber])

  if (loading) {
        <></>
  }

  return (
        <Container className="bg-white">
            <ContainerPlansDescription>
                <div id="description">
                    <h1>Assine o plano ANUAL para ter acesso a mais dados do seu time Favorito!</h1>
                    <p>Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry.</p>
                </div>
                <div id="image">
                    <img title="Icone do Palmeiras com Gráfico" alt="Icone do Palmeiras com Gráfico" src={PalmeirasChart}/>
                </div>
            </ContainerPlansDescription>
            <ContainerPlans>
                <div className="box">
                    <div className="header">
                        <div className="icon">
                            <img src={BallKing}/>
                        </div>
                        <h1 className="title">ASSINATURA PADRÃO</h1>
                    </div>
                    <div className="price">
                        <span>GRÁTIS</span>
                    </div>
                    <ul className="benefits">
                        <div className="item">
                            <CheckCircleIconStyled sx={{ color: 'green' }}/>
                            <li>Dados dos últimos 2 anos</li>
                        </div>
                        <div className="item">
                            <CheckCircleIconStyled sx={{ color: 'green' }}/>
                            <li>Filtre dados dos últimos 2 anos</li>
                        </div>
                        <div className="item">
                            <CheckCircleIconStyled sx={{ color: 'green' }}/>
                            <li>Visualize as fichas técnicas dos últimos 2 anos</li>
                        </div>
                        <div className="item">
                            <CancelIconStyled sx={{ color: 'red' }} />
                            <li>Jogos desde a fundação do time em 1914 </li>
                        </div>
                    </ul>
                    <Link to="/cadastro">
                        <Button color="primary">
                            Experimente gratuitamente
                        </Button>
                    </Link>
                </div>
                <div className="box" id="premium">
                    <div className="header">
                        <div className="icon">
                            <img src={Ball}/>
                        </div>
                        <h1 className="title">ASSINATURA PREMIUM</h1>
                    </div>
                    <div className="price">
                        <span>R$ 19,90 <span className="period">/ANO</span></span>
                    </div>
                    <ul className="benefits">
                        <div className="item">
                            <CheckCircleIconStyled sx={{ color: 'green' }}/>
                            <li>Dados dos últimos 100 anos</li>
                        </div>
                        <div className="item">
                            <CheckCircleIconStyled sx={{ color: 'green' }}/>
                            <li>Dados desde a fundação do Palmeiras em 1914</li>
                        </div>
                        <div className="item">
                            <CheckCircleIconStyled sx={{ color: 'green' }}/>
                            <li>Filtre dados de todos os anos</li>
                        </div>
                        <div className="item">
                            <CheckCircleIconStyled sx={{ color: 'green' }}/>
                            <li>Visualize as fichas técnicas de todos os anos</li>
                        </div>
                    </ul>
                    <a href={`https://buy.stripe.com/test_aEU28k1V20DV80g8wx${data && data.email ? `?prefilled_email=${data.email}` : ''}`}>
                        <Button color="secondary">
                            Assinar
                        </Button>
                    </a>
                </div>
            </ContainerPlans>
            <Terms>
                <p>Recomendamos que Leia a <Link to="/">Política de Reembolso</Link> e
                    <Link to="/">Política de Reembolso</Link> ou <Link to="/">Entre em Contato</Link> para tirar dúvidas.</p>
            </Terms>
        </Container>
  )
}

export default Planos
