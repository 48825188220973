import React from 'react'
import Container from '../../../../components/Container'
import { ContentResult } from './styles'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { api } from '../../../../services/api'
import { useLoading } from '../../../../hooks/useLoading'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

interface Game {
    id: number;
    data: string;
    estadio: string;
    placar1: number;
    placar2: number;
    time1: string;
    time2: string;
    tipo: string;
}

interface Summary {
    average_palmeiras: number | null;
    average_adversary: number | null;
    average_media: number | null;
    goals_scored_palmeiras: number | null;
    goals_scored_adversary: number | null;
    goal_balance: number | null;
    draws: number | null;
    loses: number | null;
    wins: number | null;
    total_games: number;
    goals_score?: number;
    goals_penalty_score?: number;
    goals_penalty_suffered?: number;
    replacement?: number;
    yellowCard?: number;
    redCard?: number;
    biggest_goals_scored?: number;
    biggest_goals_suffered?: number;
    highest_number_of_goals_scored?: number;
    fewest_number_of_goals_scored?: number;
    highest_number_of_goals_conceded?: number;
    fewest_number_of_goals_conceded?: number;
}

function getScoreBoard (params: any) {
  return `${params.row.time1} ${params.row.placar1 || 0} x ${params.row.placar2 || 0} ${params.row.time2}`
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70, hide: true },
  { field: 'data', headerName: 'Data', width: 150, type: 'date' },
  { field: 'estadio', headerName: 'Estádio', width: 370 },
  { field: 'placar1', headerName: 'Palmeiras Placar', width: 200, hide: true },
  { field: 'placar2', headerName: 'Adversário Placar', width: 200, hide: true },
  { field: 'time1', headerName: 'Palmeiras', width: 200, hide: true },
  { field: 'time2', headerName: 'Adversário', width: 200, hide: true },
  { field: 'resultado', headerName: 'Resultado', width: 340, valueGetter: getScoreBoard },
  { field: 'tipo', headerName: 'Campeonato', width: 200 }
]

const Relatorio: React.FC = () => {
  const [games, setGames] = React.useState<Game[] | null>()
  const [gamesFilter, setGamesFilter] = React.useState<Game[] | null>()
  const [summary, setSummary] = React.useState<Summary | null>()

  const { showLoading, closeLoading } = useLoading()
  const location = useLocation()
  const [search] = useSearchParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    doSearch(location.search)
    doSearchGames(location.search, 1)
    doSearchGamesFilter(location.search, 1)
  }, [])

  const doSearch = async (query: any) => {
    try {
      showLoading()

      const { data: gameSummary } = await api.get(`/technical-files/to-cross/game-summary${query}`)

      setSummary(gameSummary.data)
    } catch (err) {

    } finally {
      closeLoading()
    }
  }

  const doSearchGames = async (query: any, page: number) => {
    try {
      showLoading()

      const { data: dataGames } = await api.get(`/technical-files/to-cross/game-list${query ? query + '&' + `page=${page}` : `?page=${page}`}`)

      setGames(dataGames.data)
    } catch (err) {

    } finally {
      closeLoading()
    }
  }

  const doSearchGamesFilter = async (query: any, page: number) => {
    try {
      showLoading()

      const { data: dataGames } = await api.get(`/technical-files/to-cross/game-list-filter${query ? query + '&' + `page=${page}` : `?page=${page}`}`)

      setGamesFilter(dataGames.data)
    } catch (err) {

    } finally {
      closeLoading()
    }
  }

  const grafico_pesquisa = [
    { name: 'Derrotas', value: summary?.loses, color: '#e74c3c' },
    { name: 'Vitorias', value: summary?.wins, color: '#124030' },
    { name: 'Empates', value: summary?.draws, color: '#7f8c8d' }
  ]

  const grafico_equipe = [
    { name: 'Gols do Palmeiras', value: summary?.goals_scored_palmeiras, color: '#124030' },
    { name: 'Gols do Adverário', value: summary?.goals_scored_adversary, color: '#e74c3c' }
  ]

  const getPlayersFilters = () => {
    const url = new URL(window.location.href)
    const players = url.searchParams.getAll('players[]')

    return players.map(player => {
      const split = player.split('--')
      if (split.length === 1) {
        return player
      } else {
        return `${split[0]}(${split[1]})`
      }
    })
  }

  const getPlayersName = (name: string | null) => {
    if (!name) return

    const split = name.split('--')
    if (split.length === 1) {
      return name
    } else {
      return `${split[0]}(${split[1]})`
    }
  }

  const hasFilter = search && (search.get('team') || search.get('tecnico') || search.get('tecnico2') || search.get('juiz') || search.get('auxiliar1') || search.get('auxiliar2') || (search.get('public_compare') && search.get('public_number')) || search.get('difference') ||
        (search.get('score1') && search.get('score2')) || search.get('localdojogo') || search.get('periodo') || (search.get('periodStart') && search.get('periodEnd')) || search.get('data') || search.get('averageOption') || search.get('generalData') || getPlayersFilters())

  const handleRedirect = async (id: string | number) => {
    showLoading()
    navigate(`/fichas-tecnicas/${id}`, { replace: true })
    closeLoading()
  }

  const getParamPublic = (param: string | undefined) => {
    if (!param) return

    switch (param) {
      case 'L':
        return 'menor'
      case 'B':
        return 'maior'
      case 'E':
        return 'igual'
    }
  }

  return (
        <>
            <Container>
                {hasFilter && (
                    <ContentResult>
                        <div>
                            <h1>Filtros utilizados para a pesquisa:</h1>
                            {search.get('team') && (<p><strong>Time:</strong> {search.get('team')}</p>)}
                            {search.get('tecnico') && (<p><strong>Técnico:</strong> {search.get('tecnico')}</p>)}
                            {search.get('tecnico2') && (<p><strong>Técnico Adversário:</strong> {search.get('tecnico2')}</p>)}
                            {search.get('juiz') && (<p><strong>Juiz:</strong> {search.get('juiz')}</p>)}
                            {search.get('firstAssistant') && (<p><strong>Auxíliar 1:</strong> {search.get('firstAssistant')}</p>)}
                            {search.get('secondAssistant') && (<p><strong>Auxíliar 2:</strong> {search.get('secondAssistant')}</p>)}
                            {search.get('stadium') && (<p><strong>Estádio:</strong> {search.get('stadium')}</p>)}
                            {(search.get('public_compare') && search.get('public_number')) && (<p><strong>País:</strong> {search.get('public_compare')} - {search.get('public_number')}</p>)}
                            {search.get('difference') && (<p><strong>Diferença de Gols:</strong> {search.get('difference')}</p>)}
                            {(search.get('score1') && search.get('score2')) && (<p><strong>Placar:</strong> {search.get('score1')} x {search.get('score2')}</p>)}
                            {search.get('localdojogo') && (<p><strong>Mandante:</strong> {search.get('localdojogo')}</p>)}
                            {search.get('periodo') && (<p><strong>Período:</strong> {search.get('periodo')}</p>)}
                            {(search.get('periodStart') && search.get('periodEnd')) && (<p><strong>Datas:</strong> {search.get('periodStart')?.replaceAll('-', '/')} á {search.get('periodEnd')?.replaceAll('-', '/')}</p>)}
                            {search.get('data') && (<p><strong>Data:</strong> {search.get('data')?.replaceAll('-', '/')}</p>)}
                            {search.get('stage') && (<p><strong>Fase da Competição:</strong> {search.get('stage')}</p>)}
                            {search.get('publicNumber') && (<p><strong>Público {getParamPublic(search.get('publicNumber')?.split(',')[0])} que:</strong> {search.get('publicNumber')?.split(',')[1]}</p>)}
                            {getPlayersFilters().length > 0 && (
                                <p>
                                    <strong>Jogadores:</strong>
                                    <span> {Array.isArray(getPlayersFilters()) ? getPlayersFilters().join(', ') : getPlayersFilters()}</span>
                                </p>
                            )}
                            {search.get('avarageTarget') && (<p><strong>Média do jogador:</strong> {getPlayersName(search.get('avarageTarget'))}</p>)}
                            {summary?.goals_score !== undefined && (
                                <>
                                    <p><strong>Gols marcados:</strong> {summary?.goals_score}</p>
                                    <p><strong>Média de gols marcados:</strong> {Math.round(summary?.goals_score / (games?.length ?? 0) * 100) / 100} gols/partida</p>
                                </>
                            )}
                            {summary?.goals_penalty_score !== undefined && (
                                <>
                                    <p><strong>Gols marcados de pênalti:</strong> {summary?.goals_penalty_score}</p>
                                    <p><strong>Média de pênaltis marcados:</strong> {Math.round(summary?.goals_penalty_score / summary?.total_games * 100) / 100} pênaltis/partida</p>
                                </>
                            )}
                            {summary?.goals_penalty_suffered !== undefined && (
                                <>
                                    <p><strong>Gols sofridos de pênalti:</strong> {summary?.goals_penalty_suffered}</p>
                                    <p><strong>Média de pênaltis sofridos:</strong> {summary?.goals_penalty_suffered ? Math.round(summary?.goals_penalty_suffered / summary?.total_games * 100) / 100 : 0} pênaltis/partida</p>
                                </>
                            )}
                            {summary?.redCard !== undefined && (
                                <>
                                    <p><strong>Nº de cartões vermelhos:</strong> {summary?.redCard} cartões</p>
                                    <p><strong>Média de cartões vermelhos:</strong> {Math.round(summary?.redCard / summary?.total_games * 100) / 100} cartões vermelhos/partida</p>
                                </>
                            )}
                            {summary?.yellowCard !== undefined && (
                                <>
                                    <p><strong>Nº de cartões amarelo:</strong> {summary?.yellowCard} cartões</p>
                                    <p><strong>Média de cartões amarelo:</strong> {Math.round(summary?.yellowCard / summary?.total_games * 100) / 100} cartões amarelos/partida</p>
                                </>
                            )}
                            {(summary?.replacement !== undefined) && (
                                <>
                                    <p><strong>Nº de substituições feitas:</strong> {summary?.replacement} substituições</p>
                                    <p><strong>Média de substituições feitas:</strong> {Math.round(summary?.replacement / summary?.total_games * 100) / 100} substituições/partida</p>
                                </>
                            )}
                            {summary?.biggest_goals_scored !== undefined && (<p><strong>Maior goleada aplicada:</strong> {summary?.biggest_goals_scored}</p>)}
                            {summary?.biggest_goals_suffered !== undefined && (<p><strong>Maior goleada sofrida:</strong> {summary?.biggest_goals_suffered}</p>)}
                            {summary?.highest_number_of_goals_scored !== undefined && (<p><strong>Maior n° de gols marcados:</strong> {summary?.highest_number_of_goals_scored}</p>)}
                            {summary?.fewest_number_of_goals_scored !== undefined && (<p><strong>Menor n° de gols marcados:</strong> {summary?.fewest_number_of_goals_scored}</p>)}
                            {summary?.highest_number_of_goals_conceded !== undefined && (<p><strong>Maior n° de Gols Sofridos:</strong> {summary?.highest_number_of_goals_conceded}</p>)}
                            {summary?.fewest_number_of_goals_conceded !== undefined && (<p><strong>Menor n° de Gols Sofridos:</strong> {summary?.fewest_number_of_goals_conceded}</p>)}
                        </div>
                    </ContentResult>
                )}
                <ContentResult>
                    <div>
                        <h1>Resultados gerais da pesquisa:</h1>
                        {(!games?.filter(game => game.placar1 > game.placar2).length && !games?.filter(game => game.placar1 === game.placar2).length && !games?.filter(game => game.placar1 < game.placar2).length)
                          ? <p>Nenhum resultado encontrado</p>
                          : (
                                <>
                                    <p><strong>Jogos realizados:</strong> {games?.length ?? '0'}</p>
                                    <p><strong>Vitórias:</strong> {games?.filter(game => game.placar1 > game.placar2).length ?? '0'}</p>
                                    <p><strong>Empates:</strong> {games?.filter(game => game.placar1 === game.placar2).length ?? '0'}</p>
                                    <p><strong>Derrotas:</strong> {games?.filter(game => game.placar1 < game.placar2).length ?? '0'}</p>
                                </>
                            )}
                    </div>
                    <div>
                        <ResponsiveContainer width={300} height={220}>
                            <PieChart width={300} height={220}>
                                <Pie label dataKey="value" data={grafico_pesquisa} cx={150} cy={110} innerRadius={40} outerRadius={80}>
                                    {grafico_pesquisa.map((entry: any, index: any) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </ContentResult>
                <ContentResult>
                    <div>
                        <h1>Resultados gerais da equipe:</h1>
                        {(!summary?.goals_scored_palmeiras && !summary?.goals_scored_adversary && !summary?.goal_balance && !summary?.average_palmeiras && !summary?.average_adversary && !summary?.average_media)
                          ? <p>Nenhum resultado encontrado</p>
                          : (
                                <>
                                    <p><strong>Gols marcados:</strong> {summary?.goals_scored_palmeiras ?? '0'}</p>
                                    <p><strong>Gols sofridos:</strong> {summary?.goals_scored_adversary ?? '0'}</p>
                                    <p><strong>Saldo de gols:</strong> {summary?.goal_balance ?? '0'}</p>
                                    <p><strong>Média de gols marcados:</strong> {`${summary?.average_palmeiras ? `${summary?.average_palmeiras} gols/partida` : '0'}`}</p>
                                    <p><strong>Média de gols sofridos:</strong> {`${summary?.average_adversary ? `${summary?.average_adversary} gols/partida` : '0'}`}</p>
                                    {summary?.average_media && summary?.average_media > 0 && <p><strong>Média de público:</strong> {`${summary?.average_media ? `${Math.round(summary?.average_media)} pessoas/partida` : '0'}`}</p>}
                                </>
                            )}
                    </div>
                    <div>
                        <ResponsiveContainer width={300} height={220}>
                            <PieChart width={300} height={220}>
                                <Pie label dataKey="value" data={grafico_equipe} cx={150} cy={110} innerRadius={40} outerRadius={80}>
                                    {grafico_equipe.map((entry: any, index: any) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </ContentResult>
                <ContentResult>
                    <div style={{ flex: 1 }}>
                        <h1>Fichas Técnicas:</h1>
                        <div>
                            <div style={{ flex: 1, height: 700, width: '100%' }}>
                                <DataGrid
                                    sx={{
                                      '.MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#124030',
                                        color: '#fff'
                                      },
                                      '.MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold'
                                      }
                                    }}
                                    rows={gamesFilter || []}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[5]}
                                    onCellClick={(el) => handleRedirect(el.id)}
                                    getRowClassName={(params) => {
                                      let className = 'draw pointer'

                                      if (params.row.placar1 < params.row.placar2) {
                                        className = 'lose pointer'
                                      } else if (params.row.placar1 > params.row.placar2) {
                                        className = 'win pointer'
                                      }

                                      return `super-app-theme--${className}`
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ContentResult>
            </Container>
        </>
  )
}

export default Relatorio
