import React from 'react'
import Container from '../../components/Container'
import { ContainerLogin, Form, ErrorStyled } from './styles'
import { Formik } from 'formik'
import Input from '../../components/Input'
import Alert from '@mui/material/Alert'
import Button from '../../components/Button'
import { Link, useSearchParams } from 'react-router-dom'
import Logotipo from '../../assets/png/logo.png'
import { UserContext } from '../../UserContext'

interface FormFieldsError {
    email?: string;
    password?: string;
}

const Login: React.FC = () => {
  const [errorLogin, setErrorLogin] = React.useState('')

  const { userLogin, loading, error, login } = React.useContext(UserContext)

  const [search] = useSearchParams()

  React.useEffect(() => {
    if (error) {
      setErrorLogin('Usuário ou Senha incorretos!')
    }

    if (login) {
      window.location.href = search.get('redirect') ?? `${process.env.REACT_APP_PUBLIC_URL}/inicio`
    }
  }, [login])

  const handleSignIn = async (email: string, password: string) => {
    userLogin(email, password)
  }

  return (
        <ContainerLogin>
            <Container tag="main">
                <div className="content-page bg-white">
                    <Link to="/">
                        <div id="logo">
                            <img alt="Logotipo Pelejas" title="Logotipo Pelejas" src={Logotipo} />
                        </div>
                    </Link>
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validate={values => {
                          const errors: FormFieldsError = {}

                          if (!values.email) {
                            errors.email = 'Campo obrigatório!'
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                          ) {
                            errors.email = 'E-mail inválido!'
                          }

                          if (!values.password) {
                            errors.password = 'Campo obrigatório!'
                          } else if (
                            !/^[a-zA-Z0-9!@#$%^&*]{6,}$/i.test(values.password)
                          ) {
                            errors.password = 'A senha deve ter no minimo 6 caracteres.'
                          }

                          return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          handleSignIn(values.email, values.password)
                        }}
                    >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                          /* and other goodies */
                        }) => (
                            <Form onSubmit={(e) => {
                              e.preventDefault()
                              handleSubmit()
                            }}>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder="examplo@pelejas.com"
                                    label="E-mail:"
                                />
                                {errors.email && touched.email && (
                                    <Alert severity="error">{errors.email}</Alert>
                                )}
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    placeholder="******"
                                    label={'Senha:'}
                                />
                                {errors.password && touched.password && (
                                    <Alert severity="error">{errors.password}</Alert>
                                )}
                                <p id="forget-pass">Esqueceu a senha? <Link to="/esqueci-minha-senha">Clique aqui</Link></p>
                                <Button type="submit">
                                    {loading ? 'Carregando' : 'Entrar'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <p id="register">Ainda não tem uma conta? <Link to="/cadastro">Clique aqui</Link></p>
                    {errorLogin && (
                        <ErrorStyled>
                            <Alert severity="error">{errorLogin}</Alert>
                        </ErrorStyled>
                    )}
                </div>
            </Container>
        </ContainerLogin>
  )
}

export default Login
