import { ContainerButton, ContainerFullHeight, ErrorStyled, Form } from './styles'
import Container from '../../components/Container'
import { Formik } from 'formik'
import Input from '../../components/Input'
import Alert from '@mui/material/Alert'
import Button from '../../components/Button'
import React from 'react'
import { useLoading } from '../../hooks/useLoading'
import { api } from '../../services/api'
import { useSnackbar } from 'notistack'
import { Link, useNavigate } from 'react-router-dom'
import Logotipo from '../../assets/png/logo.png'

interface FormFieldsError {
    email?: string;
}

const EsqueciMinhaSenha: React.FC = () => {
  const { showLoading, closeLoading, loading } = useLoading()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleSnackbar = (message: string) => {
    enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
  }

  const handleSubmit = async (email: string) => {
    try {
      showLoading()

      await api.post('/forgot-password', {
        email
      })

      navigate('/esqueci-minha-senha/link', { replace: true })
    } catch (e: any) {
      if (e.status === 429) {
        handleSnackbar('Aguarde 60 segundos para tentar novamente.')
      } else if (e.status === 404) {
        handleSnackbar('Usuário não encontrado.')
      } else if (e.status === 400) {
        e.errors.map((error: string) => handleSnackbar(error))
      } else {
        handleSnackbar('Erro interno.')
      }
    } finally {
      closeLoading()
    }
  }

  return (
        <ContainerFullHeight>
            <Container tag="main">
                <div className="content-page bg-white">
                    <Link to="/">
                        <a title="Página Inicial | Pelejas">
                            <div id="logo">
                                <img alt="Logotipo Pelejas" title="Logotipo Pelejas" src={Logotipo} />
                            </div>
                        </a>
                    </Link>
                    <Formik
                        initialValues={{ email: '' }}
                        validate={values => {
                          const errors: FormFieldsError = {}

                          if (!values.email) {
                            errors.email = 'Campo obrigatório!'
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                          ) {
                            errors.email = 'E-mail inválido!'
                          }
                          return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          handleSubmit(values.email)
                        }}
                    >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                          /* and other goodies */
                        }) => (
                            <Form onSubmit={(e) => {
                              e.preventDefault()
                              handleSubmit()
                            }}>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    label="Digite seu e-mail:"
                                    placeholder="examplo@pelejas.com"
                                />
                                {errors.email && touched.email && (
                                    <ErrorStyled>
                                        <Alert severity="error">{errors.email}</Alert>
                                    </ErrorStyled>
                                )}
                                <ContainerButton>
                                    <Button disabled={loading} type="submit">
                                        {loading ? 'Carregando' : 'Enviar'}
                                    </Button>
                                </ContainerButton>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Container>
        </ContainerFullHeight>
  )
}

export default EsqueciMinhaSenha
