import React from 'react'
import Container from '../../../components/Container'
import TitleSection from '../../../components/TitleSection'
import { api } from '../../../services/api'
import { useLoading } from '../../../hooks/useLoading'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { ContentResult } from './styles'

interface InvoiceMp {
    coupon_code: string;
    currency_id: string;
    date_created: string;
    debit_date: string;
    error: string;
    expire_date: string;
    external_reference: string;
    id: string;
    last_modified: string;
    last_retry_date: string;
    next_retry_date: string;
    payment: string;
    preapproval_id: string;
    reason: string;
    rejection_code: string;
    retry_attempt: string;
    status: string;
    transaction_amount: string;
    type: string;
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70, hide: true },
  { field: 'status', headerName: 'Status de Pgmt', width: 150, type: 'date' },
  { field: 'transaction_amount', headerName: 'Total', width: 100 },
  { field: 'reason', headerName: 'Razão', width: 200 },
  { field: 'date_created', headerName: 'Data', width: 200 },
  { field: 'debit_date', headerName: 'Cobrança', width: 160 },
  { field: 'last_modified', headerName: 'Atualizado em', width: 170 },
  { field: 'next_retry_date', headerName: 'Próximo pagamento', width: 200 },
  { field: 'expire_date', headerName: 'Expiração', width: 120 }
]

const Faturas: React.FC = () => {
  const [invoice, setInvoice] = React.useState<InvoiceMp[]>()

  const { showLoading, closeLoading } = useLoading()

  React.useEffect(() => {
    getInvoice()
  }, [])

  const getInvoice = async () => {
    try {
      showLoading()

      const { data: dataInvoice } = await api.get('mercado-pago/client/invoice')

      setInvoice(dataInvoice.data)
    } catch (error) {

    } finally {
      closeLoading()
    }
  }

  return (
        <>
            <TitleSection text="Minhas Faturas"/>
            <Container>
                <ContentResult>
                    <div style={{ flex: 1 }}>
                        <div>
                            <div style={{ flex: 1, height: 700, width: '100%' }}>
                                <DataGrid
                                    sx={{
                                      '.MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#124030',
                                        color: '#fff'
                                      },
                                      '.MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold'
                                      }
                                    }}
                                    rows={invoice || []}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[5]}
                                    getRowClassName={(params) => {
                                      let className = 'draw'

                                      if (['Cancelado', 'Reembolsado', 'Extornado', 'Rejeitado'].includes(params.row.status)) {
                                        className = 'lose'
                                      } else if (['Aprovado', 'Autorizado'].includes(params.row.status)) {
                                        className = 'win'
                                      }

                                      return `super-app-theme--${className}`
                                    }}
                                    initialState={{
                                      sorting: {
                                        sortModel: [{ field: 'last_modified', sort: 'desc' }]
                                      }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ContentResult>
            </Container>
        </>
  )
}

export default Faturas
