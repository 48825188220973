import Container from '../../../components/Container'
import { ChartIconStyled, HeaderDescription, Form, ButtonContainer } from '../styles'
import React from 'react'
import Input from '../../../components/Input'
import {
  Accordion as MuiAccordion,
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup, Radio, RadioGroup, Select, SelectChangeEvent,
  TextField
} from '@mui/material'
import Button from '../../../components/Button'
import { Formik, FormikValues } from 'formik'
import { useLoading } from '../../../hooks/useLoading'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import SearchAucomplete from '../../../components/SearchAutocomplete'
import MenuItem from '@mui/material/MenuItem'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../services/api'

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    border: 0,
    marginLeft: '10px',
    '& .MuiTypography-root': {
      fontSize: '1.2em',
      fontWeight: 800
    }
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '5px 0',
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  boxShadow: 'none'
}))

interface OptionsProps {
    name: string;
    image?: string;
}

interface PlayersProps {
    name: string;
    description: string;
}

interface IData {
    opponentsData: {
        data: OptionsProps[];
    },
    coachesData: {
        data: OptionsProps[];
    },
    coachesOpponentsData: {
        data: OptionsProps[];
    },
    refereesData: {
        data: OptionsProps[];
    },
    assistantData: {
        data: OptionsProps[];
    },
    secondAssistantData: {
        data: OptionsProps[];
    },
    playersData: {
        data: PlayersProps[];
    },
    stadiumsData: {
        data: OptionsProps[];
    },
    championshipsData: {
        data: OptionsProps[];
    },
    stagesData: {
        data: OptionsProps[];
    },
    countriesData: {
        data: OptionsProps[];
    },
}

interface FormFields {
    day: string;
    month: string;
    year: string;
    opponentSelect: string;
    coachSelect: string;
    coachOpponentSelect: string;
    refereeSelect: string;
    assistantSelect: string;
    secondAssistantSelect: string;
    playersSelect: PlayersProps[] | undefined;
    stadiumSelect: string;
    championshipSelect: string;
    stageSelect: string;
    countrySelect: string;
    publicOption: string;
    valueScoreboard: string;
    valueDadosGerais: string;
    valueMandante: string;
    valuePeriodo: string;
    valuePeriodoDia: string;
    valueMedias: string;
    stageCheck: boolean;
    resultGame: string;
    valueDateStart: Date | null;
    valueDateEnd: Date | null;
    valueDate: Date | null;
    avarageTarget: string;
}

const CruzarDados: React.FC = () => {
  const { showLoading, closeLoading, loading } = useLoading()
  const navigate = useNavigate()

  const [formFields, setFormFields] = React.useState<FormFields>({
    day: '',
    month: '',
    year: '',
    opponentSelect: '',
    coachSelect: '',
    coachOpponentSelect: '',
    refereeSelect: '',
    assistantSelect: '',
    secondAssistantSelect: '',
    playersSelect: [{ name: 'Todos', description: '' }],
    stadiumSelect: '',
    championshipSelect: '',
    stageSelect: '',
    countrySelect: '',
    publicOption: '',
    resultGame: '',
    valueScoreboard: 'all',
    valueDadosGerais: 'all',
    valueMandante: 'all',
    valuePeriodo: 'all',
    valuePeriodoDia: 'all',
    valueMedias: 'all',
    stageCheck: false,
    valueDateStart: null,
    valueDateEnd: null,
    valueDate: null,
    avarageTarget: ''
  })

  const [expanded, setExpanded] = React.useState<string | false>('')
  const [optionsForm, setOptionsForm] = React.useState<IData | null>()

  React.useEffect(() => {
    getGameInfo()
  }, [])

  const getGameInfo = async () => {
    try {
      showLoading()

      const { data: opponentsData } = await api.get('/opponents')
      const { data: coachesData } = await api.get('/coaches')
      const { data: coachesOpponentsData } = await api.get('/coaches-opponents')
      const { data: refereesData } = await api.get('/referees')
      const { data: assistantData } = await api.get('/assistants')
      const { data: secondAssistantData } = await api.get('/assistants-second')
      const { data: playersData } = await api.get('/players')
      const { data: stadiumsData } = await api.get('/stadiums')
      const { data: championshipsData } = await api.get('/championships')
      const { data: stagesData } = await api.get('/stages')
      const { data: countriesData } = await api.get('/countries')

      setOptionsForm({
        opponentsData,
        coachesData,
        coachesOpponentsData,
        refereesData,
        assistantData,
        secondAssistantData,
        playersData,
        championshipsData,
        stagesData,
        stadiumsData,
        countriesData
      })
    } catch (err) {

    } finally {
      closeLoading()
    }
  }

  const handleChangePublicOption = (event: SelectChangeEvent) => {
    setFormFields(prevState => ({ ...prevState, publicOption: event.target.value as string }))
  }

  const handleChangeResultGame = (event: SelectChangeEvent) => {
    setFormFields(prevState => ({ ...prevState, resultGame: event.target.value as string }))
  }

  const handleChangeDay = (event: SelectChangeEvent) => {
    setFormFields(prevState => ({ ...prevState, day: event.target.value as string }))
  }

  const handleChangeMonth = (event: SelectChangeEvent) => {
    setFormFields(prevState => ({ ...prevState, month: event.target.value as string }))
  }

  const handleChangeYear = (event: SelectChangeEvent) => {
    setFormFields(prevState => ({ ...prevState, year: event.target.value as string }))
  }

  const handleChangeDateStart = (newValue: Date | null) => {
    setFormFields(prevState => ({ ...prevState, valueDateStart: newValue }))
  }

  const handleChangeDateEnd = (newValue: Date | null) => {
    setFormFields(prevState => ({ ...prevState, valueDateEnd: newValue }))
  }

  const handleChangeStage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFields(prevState => ({ ...prevState, stageCheck: event.target.checked }))
  }

  const handleRadioChangeScoreBoard = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFields(prevState => ({ ...prevState, valueScoreboard: (event.target as HTMLInputElement).value }))
  }

  const handleRadioChangeDadosGerais = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFields(prevState => ({ ...prevState, valueDadosGerais: (event.target as HTMLInputElement).value }))
  }

  const handleRadioChangeMedias = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFields(prevState => ({ ...prevState, valueMedias: (event.target as HTMLInputElement).value }))
  }

  const handleRadioChangeMandante = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFields(prevState => ({ ...prevState, valueMandante: (event.target as HTMLInputElement).value }))
  }

  const handleRadioChangePeriodo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFields(prevState => ({ ...prevState, valuePeriodo: (event.target as HTMLInputElement).value }))
  }

  const handleRadioChangePeriodoDia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormFields(prevState => ({ ...prevState, valuePeriodoDia: (event.target as HTMLInputElement).value }))
  }

  const handleChangeAccordeon =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
          setExpanded(newExpanded ? panel : false)
        }

  const handleForm = async (valuesFormik: FormikValues) => {
    showLoading()
    let queryPlayer = ''

    formFields.playersSelect?.forEach(player => {
      queryPlayer += player.name !== 'Todos' ? `&players[]=${player.name}${player.description ? `--${player.description}` : ''}` : ''
    })

    let target = formFields.avarageTarget
    if (formFields.avarageTarget.split('(').length !== 1) {
      target = formFields.avarageTarget.replace('(', '--').replace(/.$/, '')
    }

    const formatFields = {
      day: formFields.day,
      month: formFields.month,
      year: formFields.year,

      team: formFields.opponentSelect,

      firstCoach: formFields.coachSelect,
      secondCoach: formFields.coachOpponentSelect,

      referee: formFields.refereeSelect,

      firstAssistant: formFields.assistantSelect,
      secondAssistant: formFields.secondAssistantSelect,

      result: formFields.resultGame,
      stadium: formFields.stadiumSelect,
      type: formFields.championshipSelect,
      stage: formFields.stageSelect,
      champion: formFields.stageCheck ? 's' : '',

      country: formFields.countrySelect,

      publicNumber: valuesFormik.publicNumber > 0 ? `${formFields.publicOption},${valuesFormik.publicNumber}` : '',

      scoreboardOption: formFields.valueScoreboard,
      firstScoreboard: valuesFormik.score1,
      secondScoreboard: valuesFormik.score2,
      difference: valuesFormik.difference,

      // F, C, N
      localGame: formFields.valueMandante,

      // M, T, N
      period: formFields.valuePeriodoDia,

      // CHECK
      periodOption: formFields.valuePeriodo,
      periodStart: formFields.valueDateStart ? moment(formFields.valueDateStart.toString()).format('DD-MM-YYYY') : '',
      periodEnd: formFields.valueDateEnd ? moment(formFields.valueDateEnd.toString()).format('DD-MM-YYYY') : '',
      date: formFields.valueDate ? moment(formFields.valueDate.toString()).format('DD-MM-YYYY') : '',

      // EXTRAS
      avarageTarget: target,
      averageOption: formFields.valueMedias,
      generalData: formFields.valueDadosGerais
    };

    // Excluindo campos não utilizados na pesquisa
    (Object.keys(formatFields) as Array<keyof typeof formatFields>).forEach(key => {
      if (['all', '', 'Todos'].includes(formatFields[key])) {
        delete formatFields[key]
      }
    })

    // Ignorando porque o TypeScript estava acusando um erro ao usar string[];
    const queryParam = new URLSearchParams(formatFields).toString()
    navigate(`/fichas-tecnicas/cruzar-dados/relatorio?${queryParam}${queryPlayer}`)
    closeLoading()
  }

  const handlePlayersChange = (e: React.SyntheticEvent<Element, Event>, value: PlayersProps[]) => {
    if (value.length === 12) return false

    const valueFilter = value.length >= 2 ? value.filter(item => item.name !== 'Todos') : value
    setFormFields(prevState => ({ ...prevState, playersSelect: valueFilter }))
  }

  const handlePlayersBlur = () => {
    if (formFields.playersSelect && formFields.playersSelect.length === 0) {
      setFormFields(prevState => ({ ...prevState, playersSelect: [{ name: 'Todos', description: '' }] }))
    }
  }

  const opponentImage = optionsForm?.opponentsData?.data.find(opponent => opponent.name === formFields.opponentSelect)

  const months: number[] = []
  const days: number[] = []
  const years: number[] = []

  for (let i = 1; i < 13; i++) {
    months.push(i)
  }

  for (let i = 1; i < 32; i++) {
    days.push(i)
  }

  for (let i = new Date().getFullYear(); i >= 1915; i--) {
    years.push(i)
  }

  return (
        <>
            <Container tag="main">
                <HeaderDescription>
                    <ChartIconStyled />
                    <div className="control">
                        <h1>Ferramenta para Cruzar Dados de Fichas Técnicas</h1>
                        <p className="tagline">Mussum Ipsum, cacilds vidis litro abertis. Mais vale um bebadis conhecidiss, que um alcoolatra anonimis.Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Quem num gosta di mim que vai caçá sua turmis!Casamentiss faiz malandris se pirulitá.</p>
                    </div>
                </HeaderDescription>
                <Formik
                    initialValues={{ publicNumber: '', score1: '', score2: '', difference: '' }}
                    validate={values => {
                      // const errors: FormFieldsError = {};
                      //
                      // if (!values.password) {
                      //     errors.password = 'Campo obrigatório!';
                      // } else if (
                      //     !/^[a-zA-Z0-9!@#$%^&*]{6,}$/i.test(values.password)
                      // ) {
                      //     errors.password = 'A senha deve ter no minimo 6 caracteres.';
                      // }
                      //
                      // if (!values.password_confirmation) {
                      //     errors.password_confirmation = 'Campo obrigatório!';
                      // } else if (
                      //     !/^[a-zA-Z0-9!@#$%^&*]{6,}$/i.test(values.password_confirmation)
                      // ) {
                      //     errors.password_confirmation = 'A senha deve ter no minimo 6 caracteres.';
                      // }
                      //
                      // return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      handleForm(values)
                    }}
                >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                      /* and other goodies */
                    }) => (
                        <Form onSubmit={(e) => {
                          e.preventDefault()
                          handleSubmit()
                        }}>
                            <div className="division">
                                <div>
                                    {optionsForm?.opponentsData?.data && (
                                        <SearchAucomplete
                                            options={optionsForm?.opponentsData?.data}
                                            inputValue={formFields.opponentSelect}
                                            defaultValue={formFields.opponentSelect}
                                            setInputValue={(value) => setFormFields(prevState => ({ ...prevState, opponentSelect: value }))}
                                            inputComponentProps={{
                                              type: 'search',
                                              label: 'Adversário:',
                                              placeholder: 'Padrão: Todos'
                                            }}
                                        />
                                    )}
                                </div>
                                <div>
                                    {optionsForm?.coachesOpponentsData?.data && (
                                        <SearchAucomplete
                                            defaultValue={formFields.coachOpponentSelect}
                                            options={optionsForm?.coachesOpponentsData?.data}
                                            inputValue={formFields.coachOpponentSelect ?? undefined}
                                            setInputValue={(value) => setFormFields(prevState => ({ ...prevState, coachOpponentSelect: value }))}
                                            inputComponentProps={{
                                              type: 'search',
                                              label: 'Técnico Adversário:',
                                              placeholder: 'Padrão: Todos'
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="division">
                                <div>
                                    {optionsForm?.coachesData?.data && (
                                        <SearchAucomplete
                                            defaultValue={formFields.coachSelect}
                                            options={optionsForm.coachesData.data}
                                            inputValue={formFields.coachSelect}
                                            setInputValue={(value) => setFormFields(prevState => ({ ...prevState, coachSelect: value }))}
                                            inputComponentProps={{
                                              type: 'search',
                                              label: 'Técnico (PALMEIRAS):',
                                              placeholder: 'Padrão: Todos'
                                            }}
                                        />
                                    )}
                                </div>
                                <div>
                                    {optionsForm?.refereesData?.data && (
                                        <SearchAucomplete
                                            defaultValue={formFields.refereeSelect}
                                            options={optionsForm?.refereesData?.data}
                                            inputValue={formFields.refereeSelect}
                                            setInputValue={(value) => setFormFields(prevState => ({ ...prevState, refereeSelect: value }))}
                                            inputComponentProps={{
                                              type: 'search',
                                              label: 'Árbitro:',
                                              placeholder: 'Padrão: Todos'
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="division">
                                <div>
                                    {optionsForm?.assistantData?.data && (
                                        <SearchAucomplete
                                            defaultValue={formFields.assistantSelect}
                                            options={optionsForm?.assistantData?.data}
                                            inputValue={formFields.assistantSelect}
                                            setInputValue={(value) => setFormFields(prevState => ({ ...prevState, assistantSelect: value }))}
                                            inputComponentProps={{
                                              type: 'search',
                                              label: 'Auxiliar 1:',
                                              placeholder: 'Padrão: Todos'
                                            }}
                                        />
                                    )}
                                </div>
                                <div>
                                    {optionsForm?.secondAssistantData?.data && (
                                        <SearchAucomplete
                                            options={optionsForm?.secondAssistantData?.data}
                                            inputValue={formFields.secondAssistantSelect}
                                            defaultValue={formFields.secondAssistantSelect}
                                            setInputValue={(value) => setFormFields(prevState => ({ ...prevState, secondAssistantSelect: value }))}
                                            inputComponentProps={{
                                              type: 'search',
                                              label: 'Auxiliar 2:',
                                              placeholder: 'Padrão: Todos'
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="division">
                                {optionsForm?.playersData?.data && (
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        sx={{ marginRight: '0 !important', border: '0' }}
                                        options={optionsForm?.playersData?.data}
                                        onChange={handlePlayersChange}
                                        onBlur={handlePlayersBlur}
                                        value={formFields.playersSelect}
                                        getOptionLabel={(option) => `${option.name}${option.description ? `(${option.description})` : ''}`}
                                        renderInput={(params) => (
                                            <>
                                                <label className="label">Jogadores:</label>
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 0, borderBottom: '2px solid #E3E4E7', borderRadius: 0 } }}
                                                    fullWidth
                                                    label=""
                                                    placeholder="Máximo: 11"
                                                />
                                            </>
                                        )}
                                    />
                                )}
                            </div>
                            <div className="division">
                                <div>
                                    <label className="label">Resultados:</label>
                                    <div className="input-div-flex">
                                        <Select
                                            fullWidth
                                            SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            variant="outlined"
                                            value={formFields.resultGame}
                                            onChange={handleChangeResultGame}
                                            sx={
                                                {
                                                  '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 0
                                                  },
                                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    border: 0
                                                  },
                                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    border: 0
                                                  },
                                                  '&': {
                                                    border: '2px solid #E3E4E7',
                                                    borderRadius: '5px',
                                                    marginRight: '5px !important'
                                                  }
                                                }
                                            }
                                        >
                                            <MenuItem value={0}>
                                                <em>Todos</em>
                                            </MenuItem>
                                            <MenuItem value="W"><em>Vitória</em></MenuItem>
                                            <MenuItem value="L"><em>Derrota</em></MenuItem>
                                            <MenuItem value="D"><em>Empate</em></MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                <div>
                                    <label className="label">Placar:</label>
                                    <FormControl variant="standard" fullWidth>
                                        <RadioGroup
                                            aria-labelledby="demo-error-radios"
                                            name="quiz"
                                            value={formFields.valueScoreboard}
                                            onChange={handleRadioChangeScoreBoard}
                                        >
                                            <FormControlLabel value="all" control={<Radio/>} label="Todos" />
                                            <div id="options-scoreboard">
                                                <div>
                                                    <FormControlLabel value="result" control={<Radio />} label="Placar:" />
                                                    <div id="scoreboard">
                                                        <div>
                                                            <div className="team-logo" id="palmeiras-logo">
                                                                {/* <Image width={40} height={40} src={SvgPal} /> */}
                                                            </div>
                                                            <Input
                                                                type="number"
                                                                name="score1"
                                                                id="score1"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.score1}
                                                                placeholder="0"
                                                                disabled={formFields.valueScoreboard !== 'result'}
                                                                required
                                                            />
                                                        </div>
                                                        <span>X</span>
                                                        <div>
                                                            <Input
                                                                type="number"
                                                                name="score2"
                                                                id="score2"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.score2}
                                                                placeholder="0"
                                                                disabled={formFields.valueScoreboard !== 'result'}
                                                                required
                                                            />
                                                            {
                                                                opponentImage && (
                                                                    <div className="team-logo" id="opponent-logo">
                                                                        <img src={opponentImage.image}/>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <FormControlLabel value="difference" control={<Radio />} label="Deferença de Gols:" />
                                                    <div id="scoreboard">
                                                        <div>
                                                            <Input
                                                                type="number"
                                                                name="difference"
                                                                id="difference"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.difference}
                                                                placeholder="0"
                                                                disabled={formFields.valueScoreboard !== 'difference'}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordeon('panel1')}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Typography>Local</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="division">
                                        <div>
                                            {optionsForm?.stadiumsData?.data && (
                                                <SearchAucomplete
                                                    options={optionsForm?.stadiumsData?.data}
                                                    inputValue={formFields.stadiumSelect}
                                                    defaultValue={formFields.stadiumSelect}
                                                    setInputValue={(value) => setFormFields(prevState => ({ ...prevState, stadiumSelect: value }))}
                                                    inputComponentProps={{
                                                      type: 'search',
                                                      label: 'Estádio:',
                                                      placeholder: 'Padrão: Todos'
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div>
                                            <label className="label">Público</label>
                                            <div className="input-div-flex">
                                                <Select
                                                    fullWidth
                                                    SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    variant="outlined"
                                                    value={formFields.publicOption}
                                                    onChange={handleChangePublicOption}
                                                    sx={
                                                        {
                                                          '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 0
                                                          },
                                                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            border: 0
                                                          },
                                                          '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            border: 0
                                                          },
                                                          '&': {
                                                            border: '2px solid #E3E4E7',
                                                            borderRadius: '5px',
                                                            marginRight: '5px !important'
                                                          }
                                                        }
                                                    }
                                                >
                                                    <MenuItem value={0}>
                                                        <em>Todos</em>
                                                    </MenuItem>
                                                    <MenuItem value="B"><em>Maior que</em></MenuItem>
                                                    <MenuItem value="L"><em>Menor que</em></MenuItem>
                                                    <MenuItem value="E"><em>Igual a</em></MenuItem>
                                                </Select>
                                                <Input
                                                    type="number"
                                                    name="publicNumber"
                                                    id="publicNumber"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.publicNumber}
                                                    placeholder="0"
                                                    disabled={formFields.publicOption === '0'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="division">
                                        <div>
                                            <label className="label">Mandante:</label>
                                            <FormControl sx={{ m: 3 }} variant="standard" fullWidth>
                                                <RadioGroup
                                                    aria-labelledby="demo-error-radios"
                                                    name="quiz"
                                                    value={formFields.valueMandante}
                                                    onChange={handleRadioChangeMandante}
                                                >
                                                    <FormControlLabel value="all" control={<Radio/>} label="Todos" />
                                                    <FormControlLabel value="C" control={<Radio />} label="Casa" />
                                                    <FormControlLabel value="F" control={<Radio />} label="Fora" />
                                                    <FormControlLabel value="N" control={<Radio />} label="Neutro" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <label className="label">Período:</label>
                                            <FormControl sx={{ m: 3 }} variant="standard" fullWidth>
                                                <RadioGroup
                                                    aria-labelledby="demo-error-radios"
                                                    name="quiz"
                                                    value={formFields.valuePeriodoDia}
                                                    onChange={handleRadioChangePeriodoDia}
                                                >
                                                    <FormControlLabel value="all" control={<Radio/>} label="Todos" />
                                                    <FormControlLabel value="M" control={<Radio />} label="Manha" />
                                                    <FormControlLabel value="T" control={<Radio />} label="Tarde" />
                                                    <FormControlLabel value="N" control={<Radio />} label="Noite" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="division">
                                        <div>
                                            <div>
                                                {optionsForm?.countriesData?.data && (
                                                    <SearchAucomplete
                                                        options={optionsForm?.countriesData?.data}
                                                        inputValue={formFields.countrySelect}
                                                        defaultValue={formFields.countrySelect}
                                                        setInputValue={(value) => setFormFields(prevState => ({ ...prevState, countrySelect: value }))}
                                                        inputComponentProps={{
                                                          type: 'search',
                                                          label: 'País:',
                                                          placeholder: 'Padrão: Todos'
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordeon('panel2')}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                    <Typography>Competição</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="division">
                                        <div>
                                            {optionsForm?.championshipsData?.data && (
                                                <SearchAucomplete
                                                    options={optionsForm?.championshipsData?.data}
                                                    inputValue={formFields.championshipSelect}
                                                    setInputValue={(value) => setFormFields(prevState => ({ ...prevState, championshipSelect: value }))}
                                                    inputComponentProps={{
                                                      type: 'search',
                                                      label: 'Competição:',
                                                      placeholder: 'Padrão: Todos'
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div>
                                            {optionsForm?.stagesData?.data && (
                                                <SearchAucomplete
                                                    options={optionsForm?.stagesData?.data}
                                                    inputValue={formFields.stageSelect}
                                                    setInputValue={(value) => setFormFields(prevState => ({ ...prevState, stageSelect: value }))}
                                                    inputComponentProps={{
                                                      type: 'search',
                                                      label: 'Fase:',
                                                      placeholder: 'Padrão: Todos'
                                                    }}
                                                />
                                            )}

                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={formFields.stageCheck}
                                                            onChange={handleChangeStage}
                                                        />
                                                    }
                                                    label="Jogo em que foi Campeão"
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="division">
                                        <div>
                                            <label className="label">Período:</label>
                                            <FormControl sx={{ m: 3 }} variant="standard" fullWidth>
                                                <RadioGroup
                                                    aria-labelledby="demo-error-radios"
                                                    name="quiz"
                                                    value={formFields.valuePeriodo}
                                                    onChange={handleRadioChangePeriodo}
                                                >
                                                    <FormControlLabel value="all" control={<Radio/>} label="Todos" />
                                                    <div id="options-scoreboard">
                                                        <div>
                                                            <FormControlLabel value="result" control={<Radio />} label="Período:" />
                                                            <div id="scoreboard">
                                                                <LocalizationProvider locale={ptBR} dateAdapter={AdapterDateFns}>
                                                                    <MobileDatePicker
                                                                        label="Data de Inicio"
                                                                        inputFormat="dd/MM/yyyy"
                                                                        value={formFields.valueDateStart}
                                                                        disabled={formFields.valuePeriodo !== 'result'}
                                                                        onChange={handleChangeDateStart}
                                                                        renderInput={(params) =>
                                                                            <Input
                                                                                {...params.inputProps}
                                                                                inputProps={{
                                                                                  ...params.inputProps,
                                                                                  autoComplete: 'new-password' // disable autocomplete and autofill
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                    <span>Até</span>
                                                                    <MobileDatePicker
                                                                        label="Data Final"
                                                                        inputFormat="dd/MM/yyyy"
                                                                        value={formFields.valueDateEnd}
                                                                        disabled={formFields.valuePeriodo !== 'result'}
                                                                        onChange={handleChangeDateEnd}
                                                                        renderInput={(params) =>
                                                                            <Input
                                                                                {...params.inputProps}
                                                                                inputProps={{
                                                                                  ...params.inputProps,
                                                                                  autoComplete: 'new-password' // disable autocomplete and autofill
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <FormControlLabel value="data" control={<Radio />} label="Data:" />
                                                            <div id="scoreboard">
                                                                <Select
                                                                    fullWidth
                                                                    SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                                                                    labelId="demo-select-small"
                                                                    id="demo-select-small"
                                                                    variant="outlined"
                                                                    disabled={formFields.valuePeriodo !== 'data'}
                                                                    value={formFields.day}
                                                                    onChange={handleChangeDay}
                                                                    sx={
                                                                        {
                                                                          '& .MuiOutlinedInput-notchedOutline': {
                                                                            border: 0
                                                                          },
                                                                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                            border: 0
                                                                          },
                                                                          '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                            border: 0
                                                                          },
                                                                          '&': {
                                                                            border: '2px solid #E3E4E7',
                                                                            borderRadius: '5px',
                                                                            marginRight: '5px !important'
                                                                          }
                                                                        }
                                                                    }
                                                                >
                                                                    <MenuItem value={0}>
                                                                        <em>Todos</em>
                                                                    </MenuItem>
                                                                    {days.map(day => <MenuItem key={day} value={`${day > 10 ? day : `0${day}`}`}><em>{day}</em></MenuItem>)}
                                                                </Select>
                                                                <Select
                                                                    fullWidth
                                                                    SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                                                                    labelId="demo-select-small"
                                                                    id="demo-select-small"
                                                                    variant="outlined"
                                                                    disabled={formFields.valuePeriodo !== 'data'}
                                                                    value={formFields.month}
                                                                    onChange={handleChangeMonth}
                                                                    sx={
                                                                        {
                                                                          '& .MuiOutlinedInput-notchedOutline': {
                                                                            border: 0
                                                                          },
                                                                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                            border: 0
                                                                          },
                                                                          '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                            border: 0
                                                                          },
                                                                          '&': {
                                                                            border: '2px solid #E3E4E7',
                                                                            borderRadius: '5px',
                                                                            marginRight: '5px !important'
                                                                          }
                                                                        }
                                                                    }
                                                                >
                                                                    <MenuItem value={0}>
                                                                        <em>Todos</em>
                                                                    </MenuItem>
                                                                    {months.map(month => <MenuItem key={month} value={`${month > 10 ? month : `0${month}`}`}><em>{month}</em></MenuItem>)}
                                                                </Select>
                                                                <Select
                                                                    fullWidth
                                                                    SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                                                                    labelId="demo-select-small"
                                                                    id="demo-select-small"
                                                                    variant="outlined"
                                                                    disabled={formFields.valuePeriodo !== 'data'}
                                                                    value={formFields.year}
                                                                    onChange={handleChangeYear}
                                                                    sx={
                                                                        {
                                                                          '& .MuiOutlinedInput-notchedOutline': {
                                                                            border: 0
                                                                          },
                                                                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                            border: 0
                                                                          },
                                                                          '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                            border: 0
                                                                          },
                                                                          '&': {
                                                                            border: '2px solid #E3E4E7',
                                                                            borderRadius: '5px',
                                                                            marginRight: '5px !important'
                                                                          }
                                                                        }
                                                                    }
                                                                >
                                                                    <MenuItem value={0}>
                                                                        <em>Todos</em>
                                                                    </MenuItem>
                                                                    {years.map(year => <MenuItem key={year} value={year}><em>{year}</em></MenuItem>)}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordeon('panel3')}>
                                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                    <Typography>Extras</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="division">
                                        <div>
                                            <label className="label label-medium">Dados Gerais:</label>
                                            <FormControl variant="standard" fullWidth>
                                                <RadioGroup
                                                    aria-labelledby="demo-error-radios"
                                                    name="quiz"
                                                    value={formFields.valueDadosGerais}
                                                    onChange={handleRadioChangeDadosGerais}
                                                >
                                                    <FormControlLabel value="all" control={<Radio/>} label="Nenhum" />
                                                    <FormControlLabel value="highest_number_of_goals_scored" control={<Radio/>} label="Maior nº de gols marcados" />
                                                    <FormControlLabel value="fewest_number_of_goals_scored" control={<Radio />} label="Menor nº de gols marcados" />
                                                    <FormControlLabel value="highest_number_of_goals_conceded" control={<Radio />} label="Maior nº de gols sofridos" />
                                                    <FormControlLabel value="fewest_number_of_goals_conceded" control={<Radio />} label="Menor nº de gols sofridos" />
                                                    <FormControlLabel value="biggest_goals_scored" control={<Radio />} label="Maiores goleadas aplicadas" />
                                                    <FormControlLabel value="biggest_goals_suffered" control={<Radio />} label="Maiores goleadas sofridas" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <label className="label label-medium">Médias:</label>
                                            <div>
                                                {optionsForm?.playersData?.data && (
                                                    <SearchAucomplete
                                                        options={optionsForm?.playersData?.data.map(player => ({
                                                          name: `${player.name}${player.description ? `(${player.description})` : ''}`
                                                        }))}
                                                        inputValue={formFields.avarageTarget}
                                                        defaultValue={formFields.avarageTarget}
                                                        setInputValue={(value) => setFormFields(prevState => ({ ...prevState, avarageTarget: value }))}
                                                        inputComponentProps={{
                                                          type: 'search',
                                                          label: 'Média da Equipe ou Jogador:',
                                                          placeholder: 'Padrão: Equipe'
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <FormControl variant="standard" fullWidth>
                                                <RadioGroup
                                                    aria-labelledby="demo-error-radios"
                                                    name="quiz"
                                                    value={formFields.valueMedias}
                                                    onChange={handleRadioChangeMedias}
                                                >
                                                    <FormControlLabel value="all" control={<Radio/>} label="Nenhum" />
                                                    <FormControlLabel value="goals_score" control={<Radio/>} label="Nº de gols marcados" />
                                                    <FormControlLabel value="goals_penalty_score" control={<Radio />} label="Nº de gols de pênalti marcados" />
                                                    <FormControlLabel value="goals_penalty_suffered" control={<Radio />} label="Nº de gols de pênalti sofridos" />
                                                    <FormControlLabel value="redCard" control={<Radio />} label="Nº de cartões vermelhos recebidos" />
                                                    <FormControlLabel value="yellowCard" control={<Radio />} label="Nº de cartões amarelos recebidos" />
                                                    <FormControlLabel value="replacement" control={<Radio />} label="Nº de jogos com substituição" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <ButtonContainer>
                                <Button disabled={loading} type="submit">
                                    {loading ? 'Carregando' : 'Pesquisar'}
                                </Button>
                            </ButtonContainer>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
  )
}

export default CruzarDados;