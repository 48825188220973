import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 1.3em;
`

export const Hr = styled.hr`
  display: block;
  height: 1px;
  background-color: #ccc;
  border: 0;
  margin: 40px 0;
`

export const MeansPayment = styled.ul`
  list-style: none;
  margin: 20px 0;
  display: flex;
  
  li {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 5px 10px;
    margin-right: 10px; 
    font-weight: bold;
    display: flex;
    
    svg {
      margin-right: 5px;
    }
  }

  a {
    color: #000;
  }
`

export const InfoPayment = styled.ul`
  list-style: none;
  margin: 20px 0;
  
  li {
    margin: 10px 0; 
    color: #666;
    display: flex;
    align-items: center;
    
    strong {
      margin-left: 10px;
    }
  }
`
