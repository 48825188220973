import React from 'react'
import Container from '../../components/Container'
import { api } from '../../services/api'
import { useSnackbar } from 'notistack'
import { useLoading } from '../../hooks/useLoading'
import DOMPurify from 'dompurify'
import { Post, PaginationContainer, LayoutBlog, Survey } from './styles'
import { Box, Pagination } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import { FcSurvey } from 'react-icons/fc'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Button from '../../components/Button'
import { UserContext } from '../../UserContext'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

interface IBlog {
    id: number;
    textoLimpo: string;
    nome: string;
    data: string;
    titulo: string;
    comentarios: number | null;
    imgPrincipal: string | null;
}

export interface Option {
    id: number;
    name: string;
    survey_id: number;
    created_at: string;
    updated_at: string;
    votes: any[];
}

export interface ISurvey {
    id: number;
    question: string;
    expire_at: string;
    created_at: string;
    updated_at: string;
    deleted_at: any;
    options: Option[];
}

interface IPages {
    totalPosts: number;
    totalPages: number;
}

function LinearProgressWithLabel (props: LinearProgressProps & { value: number }) {
  return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
  )
}

const Blog: React.FC = () => {
  const [blog, setBlog] = React.useState<IBlog[]>()
  const [survey, setSurvey] = React.useState<ISurvey>()
  const [total, setTotal] = React.useState<IPages>()
  const [page, setPage] = React.useState(1)
  const [selectedValue, setSelectedValue] = React.useState('')

  const { enqueueSnackbar } = useSnackbar()
  const { login, data } = React.useContext(UserContext)
  const { showLoading, closeLoading } = useLoading()
  const history = useNavigate()
  const { page: currentPage } = useParams()

  React.useEffect(() => {
    const currentPageCondition = currentPage ? Number(currentPage) : 1
    getPosts(currentPageCondition)
    getSurvey()
  }, [currentPage])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history(`/blog/${value}`)
  }

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value)
  }

  const handleSnackbar = (message: string) => {
    enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
  }

  const getPosts = async (page?: number) => {
    try {
      showLoading()

      const conditionPage = page ? '?page=' + page : ''
      const { data: dataBlog } = await api.get(`/blog${conditionPage}`)

      setBlog(dataBlog.data)
      setTotal({
        totalPosts: dataBlog.meta.total,
        totalPages: dataBlog.meta.last_page
      })

      if (page) {
        setPage(page)
      }
    } catch (e: any) {
      handleSnackbar('Ocorreu um erro ao obter os posts')
    } finally {
      closeLoading()
    }
  }

  const getSurvey = async () => {
    try {
      showLoading()

      const { data: dataSurvey } = await api.get('/survey')

      setSurvey(dataSurvey.data)
    } catch (e: any) {
      handleSnackbar('Ocorreu um erro ao obter as enquetes')
    } finally {
      closeLoading()
    }
  }

  const handleVote = async () => {
    if (!login) {
      history(`/entrar?redirect=${window.location.href}`)
      return
    }

    try {
      showLoading()

      await api.post('/votes', {
        id_option: selectedValue
      })

      await getSurvey()
    } catch (e: any) {
      handleSnackbar('Ocorreu um erro ao obter as enquetes')
    } finally {
      closeLoading()
    }
  }

  const voto = survey?.options.find(option => option.votes.find(vote => vote.user_id === data.id))
  // eslint-disable-next-line no-unused-vars
  let totalVotes = 0
  survey?.options.forEach(option => {
    totalVotes += option.votes.length
  })

  return (
        <Container className="bg-white">
            <LayoutBlog>
                <div>
                    {blog && blog.map(post => (
                        <Post key={post.id}>
                            <Link to={`/blog/artigo/${post.id}`}>
                                <h1>{post.titulo}</h1>
                            </Link>
                            <div className="ds-flex">
                                <div>
                                    {post.imgPrincipal && (
                                        <div>
                                            <img src={post.imgPrincipal}/>
                                        </div>
                                    )}
                                </div>
                                <div className="text">
                                    <div className="html-content">
                                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.textoLimpo) }}></p>
                                    </div>
                                    <div className="footer">
                                        <span>{post.comentarios ?? 0} comentarios</span>
                                        <Link to={`/blog/artigo/${post.id}`}>Ver mais...</Link>
                                    </div>
                                </div>
                            </div>
                        </Post>
                    ))}
                </div>
                <StickyBox className="sidebar" offsetTop={20} offsetBottom={20}>
                    {survey?.id && (
                        <div>
                            <h2>Enquete</h2>
                            <Survey>
                                <h3><FcSurvey /> {survey.question}</h3>
                                {voto
                                  ? (
                                        <div>
                                            {survey.options.map(option => (
                                                <div key={option.id}>
                                                    <span>{option.name}</span>
                                                    <LinearProgressWithLabel key={option.id} value={(option.votes.length * 100) / totalVotes} />
                                                </div>
                                            ))}
                                        </div>
                                    )
                                  : (
                                        <>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                >
                                                    {survey.options.map(option => (
                                                        <FormControlLabel key={option.id} value={option.id} control={
                                                            <Radio
                                                                onChange={handleChangeRadio}
                                                                value={option.id}
                                                                name={option.name}
                                                                checked={selectedValue === option.id.toString()}
                                                            />
                                                        } label={option.name} />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                            <div id="submit">
                                                <Button onClick={handleVote}>Votar</Button>
                                            </div></>
                                    )
                                }
                            </Survey>
                        </div>
                    )}
                    <div>
                        <h2>Arquivo de Posts</h2>
                        <p>Novembro</p>
                        <p>Outubro</p>
                        <p>Junho</p>
                        <p>Março</p>
                        <p>Janeiro</p>
                        <p>Setembro</p>
                        <p>Agosto</p>
                        <p>Dezembro</p>
                        <p>Julho</p>
                        <p>Abril</p>
                        <p>Maio</p>
                        <p>Fevereiro</p>
                        <p>Janeiro</p>
                    </div>
                    <div>
                        <h2>Amigos do PELEJAS</h2>
                        <p>As Mil Camisas</p>
                        <p>Blog do Soares</p>
                        <p>Futebol ao vivo</p>
                        <p>Futebol na Vitrine - A Rede Social do Futebol</p>
                        <p>Ludopédio</p>
                        <p>Paixão Pelo Futebol</p>
                        <p>Pesquisadores Futebol Clube</p>
                        <p>Sinopse do Futebol</p>
                        <p>Verminosos Por Futebol</p>
                    </div>
                </StickyBox>
            </LayoutBlog>
            <PaginationContainer>
                <Pagination count={total?.totalPages} page={page} onChange={handleChange} />
            </PaginationContainer>
        </Container>
  )
}

export default Blog
