import React from 'react'
import Container from '../../../../components/Container'
import { useLoading } from '../../../../hooks/useLoading'
import Button from '../../../../components/Button'
import { api } from '../../../../services/api'
import { useSnackbar } from 'notistack'
import { PixContainer } from './styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router-dom'

interface PixProps {
    qr_code: string;
    qr_code_base64: string;
}

const Pix: React.FC = () => {
  const [pix, setPix] = React.useState<PixProps>({
    qr_code: '',
    qr_code_base64: ''
  })

  const { showLoading, closeLoading } = useLoading()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  React.useEffect(() => {
    handleSubmit()
    clientExist()
  }, [])

  const clientExist = async () => {
    try {
      showLoading()

      const { data: dataClient } = await api.get('mercado-pago/client')

      if (!dataClient.data) {
        closeLoading()
        navigate('/assinatura/informacoes-pessoais', { replace: true })
      }
    } catch (err) {
      navigate('/inicio', { replace: true })
    } finally {
      closeLoading()
    }
  }

  const handleSubmit = async () => {
    try {
      showLoading()

      const { data: dataPix } = await api.get('/mercado-pago/pix')

      setPix({
        qr_code: dataPix.data.qr_code,
        qr_code_base64: dataPix.data.qr_code_base64
      })
    } catch (e: any) {
      if (e.status === 400) {
        e.errors.map((error: string) => enqueueSnackbar(error, { variant: 'error' }))
      } else if (e.status === 500) {
        enqueueSnackbar('Erro inesperado!', { variant: 'error' })
      }
    } finally {
      closeLoading()
    }
  }

  const handleToast = () => {
    enqueueSnackbar('Copiado!', { variant: 'success' })
  }

  return (
        <>
            <Container tag="main">
                {pix.qr_code && (
                    <PixContainer>
                        <h1>Pagamento PIX</h1>
                        <p>Pronto! agora basta escanear o código no <strong>aplicativo do seu banco</strong>, ou copiar o código abaixo e usar e opção <strong>&quot;Pix Copia e Cola&quot;</strong>.</p>
                        <div>
                            <img src={`data:image/jpeg;base64,${pix.qr_code_base64}`}/>
                            <div id="code">
                                <p>{pix.qr_code}</p>
                            </div>
                            <CopyToClipboard
                                text={pix.qr_code}
                                onCopy={handleToast}>
                                <Button>Copiar código PIX</Button>
                            </CopyToClipboard>
                        </div>
                    </PixContainer>
                )}
            </Container>
        </>
  )
}

export default Pix
