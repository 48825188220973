import { Button } from '@mui/material'
import {
  DataGrid,
  type GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton
} from '@mui/x-data-grid'
import React from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Container from '../../components/Container'
import { useLoading } from '../../hooks/useLoading'
import { api } from '../../services/api'
import { ChartOneIconStyled, ContentResult, HeaderDescription } from './styles'

interface Game {
  id: number;
  data: string;
  estadio: string;
  placar1: number;
  placar2: number;
  time1: string;
  time2: string;
  tipo: string;
}

function getScoreBoard(params: any) {
  return `${params.row.time1} ${params.row.placar1 || 0} x ${params.row.placar2 || 0} ${params.row.time2}`
}

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    minWidth: 150,
    hide: true
  },
  {
    field: 'data',
    headerName: 'Data',
    minWidth: 150,
  },
  { field: 'estadio', headerName: 'Estádio', width: 370 },
  {
    field: 'placar1',
    headerName: 'Palmeiras Placar',
    flex: 1,
    minWidth: 150,
    hide: true
  },
  {
    field: 'placar2',
    headerName: 'Adversário Placar',
    flex: 1,
    minWidth: 150,
    hide: true
  },
  {
    field: 'time1',
    headerName: 'Palmeiras',
    flex: 1,
    minWidth: 150,
    hide: true
  },
  {
    field: 'time2',
    headerName: 'Adversário',
    flex: 1,
    minWidth: 150,
    hide: true
  },
  {
    field: 'resultado',
    headerName: 'Resultado',
    flex: 1,
    minWidth: 150,
    valueGetter: getScoreBoard
  },
  {
    field: 'tipo',
    headerName: 'Campeonato',
    flex: 1,
    minWidth: 150
  }
]

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ padding: '10px', backgroundColor: '#eee' }}>
      <GridToolbarFilterButton placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
    </GridToolbarContainer>
  )
}

const FichasTecnicas: React.FC<{ backoffice?: boolean }> = ({ backoffice }) => {
  const [games, setGames] = React.useState<Game[] | null>()

  const { showLoading, closeLoading } = useLoading()
  const navigate = useNavigate()

  const [search] = useSearchParams()

  React.useEffect(() => {
    const searchAsObject = Object.fromEntries(
      new URLSearchParams(search)
    )

    doSearchGames(searchAsObject, 0)
  }, [])

  const doSearchGames = async (query: any, page: number) => {
    try {
      showLoading()

      const { data: dataSheet } = await api.get(`/technical-files/to-cross/game-list?page=${page}`, {
        params: query
      })

      setGames(dataSheet.data)
    } catch (err) {

    } finally {
      closeLoading()
    }
  }

  const handleRedirect = async (id: string | number) => {
    showLoading()

    if (backoffice) {
      navigate(`/backoffice/fichas-tecnicas/${id}`);
      closeLoading();
      return;
    }
    navigate(`/fichas-tecnicas/${id}`)
    closeLoading()
  }

  return (
    <>
      <Container>
        <HeaderDescription>
          <ChartOneIconStyled />
          <div className="control">
            <h1>Buscar Fichas Técnicas</h1>
            <p className="tagline">Mussum Ipsum, cacilds vidis litro abertis. Mais vale um bebadis conhecidiss, que um alcoolatra anonimis.Posuere libero varius. Nullam a nisl ut ante blandit hendrerit. Aenean sit amet nisi.Quem num gosta di mim que vai caçá sua turmis!Casamentiss faiz malandris se pirulitá.</p>
            {backoffice && (
              <Link to="/backoffice/fichas-tecnicas/adicionar">
                <Button>Adicionar Nova Ficha</Button>
              </Link>
            )}
          </div>
        </HeaderDescription>
        {games && (
          <ContentResult style={{ height: 600, width: '100%' }}>
            <DataGrid
              sx={{
                '.MuiDataGrid-columnHeaders': {
                  backgroundColor: '#124030',
                  color: '#fff',
                  borderRadius: 0
                },
                '.MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold'
                }
              }}
              rows={games}
              columns={columns}
              components={{
                Toolbar: CustomToolbar
              }}
              onCellClick={(el) => handleRedirect(el.id)}
              getRowClassName={(params) => {
                let className = 'draw'

                if (+params.row.placar1 < +params.row.placar2) {
                  className = 'lose'
                } else if (+params.row.placar1 > +params.row.placar2) {
                  className = 'win'
                }

                return `super-app-theme--${className} pointer`
              }}
              initialState={{
                filter: {
                  filterModel: {
                    items: [
                      {
                        columnField: 'rating',
                        operatorValue: '>',
                        value: '2.5'
                      }
                    ]
                  }
                }
              }}
            />
          </ContentResult>
        )}
      </Container>
    </>
  )
}

export default FichasTecnicas
