import styled from 'styled-components'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import banner from '../../../src/assets/jpg/background-home.jpg'

export const CancelIconStyled = styled(CancelIcon)`
  fill: #F56565;
`

export const CheckCircleIconStyled = styled(CheckCircleIcon)`
  fill: #006437;
`

export const ChartIconStyled = styled(ShowChartIcon)`
  fill: #006437;
`

export const ChartCrossIconStyled = styled(StackedLineChartIcon)`
  fill: #006437;
`

export const ToolCharts = styled.div`
  .container {
    background: url(${banner});
    background-size: cover;
    height: 100%; 
  }
  
  .header {
    text-align: center;

    padding: 20px 0;

    h1 {
      color: #ddd;
    }

    p {
      color: #fff;
      font-size: 0.8em;
    }
  }

  .title-chart {
    color: #fff;
  }

  #opponent {
    color: #666;
  }

  .text-green {
    color: #006437;
  }

  .text-orange {
    color: #E27131;
  }
`

export const TeamsSelect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 38.75em) {
    flex-direction: row;
  }
  
  #versus {
    color: #fff;
    margin: 0 10px;
    font-weight: bold;
    font-size: 2em;
    text-shadow: 1px 1px #000;
  }
  
  .team {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: 38.75em) {
      flex-direction: row;
    }
    
    img {
      width: 60px;
      height: 60px;
    }
    
    > div {
      width: 100px;
      height: 100px;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 8px solid rgba(236, 240, 241,.4);
      
      > div {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
      }
    }

    span {
      font-size: 1.5em;
      font-weight: bold;
      color: #fff;
      display: block;
      margin: 0 10px;
      text-shadow: 1px 1px #000;
      word-break: break-all;
    }
  }
`

export const Hr = styled.hr`
  height: 0.1px;
  background-color: rgba(255, 255, 255, .1);
  border: 0;
  display: block;
  margin: 20px 0;
`

export const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  .charts:first-of-type {
    margin-bottom: 20px;
  }
  
  @media (min-width: 71.1875em) {
    flex-direction: row;
    justify-content: space-between;

    .charts:first-of-type {
      margin-right: 30px;
      margin-bottom: 0px;
    }
  }

  padding: 20px 0;

  .charts {
    flex: 1;
    height: 200px;

    .ds-flex {
      display: flex;
      align-items: center;

      flex-direction: column;

      @media (min-width: 38.75em) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
      }
    }

    h1 {
      font-size: 1em;
      color: #ddd;
      margin-bottom: 10px;
    }

    .info-charts {
      flex: 1;
      width: 100%;
      
      p, strong {
        color: #fff;
      }
      
      p {
        margin-bottom: 10px;
      }

      @media (min-width: 38.75em) {
        margin-left: 20px;
        flex: inherit;
        width: auto;
        min-width: 200px;
      }
    }
  }
`

export const LinkContainer = styled.div`
  text-align: center;
  margin-top: 70px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    margin-left: 10px;
  }
  
  svg path {
    fill: #fff;
  }
  
  a {
    color: #fff;
    text-decoration: underline;
  }
`

export const IconChange = styled.div`
  width: 13px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  border-radius: 10px;
`

export const ContainerTools = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .box {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-bottom: 20px;

    > div {
      padding: 20px;
    }

    .header {
      border-bottom: 1px solid #eee;
      padding: 10px 20px;
      
      display: flex;
      align-items: center;
      
      svg {
        margin-right: 20px;
      }
    }
    
    .header > h1 {
      font-size: 1.2em;
      color: #323232;
    }
    
    .description > p {
      font-size: .8em;
    }
    
    .action {
      text-align: right;
      padding-bottom: 10px;
      
      > a {
        text-decoration: none;
        
        :hover {
          color: #1C4E80;
        }
      }
    }
  }

  @media (min-width: 62.5em) {
    flex-direction: row;
    justify-content: space-between;

    .box:first-of-type {
      margin-right: 20px;
    }
  }
`

export const HeaderContent = styled.header`
  > h1 {
    font-size: 1.4em;
    color: #323232;
  }
  
  > p {
    font-size: 0.9em;
  }

  @media (min-width: 71.1875em) {
    > h1 {
      font-size: 1.6em;
      color: #323232;
    }

    > p {
      font-size: 1em;
    }
  }
`

export const ContainerPlansDescription = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  justify-content: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 40px;
  margin-bottom: 40px;

  #description {
    max-width: 800px;
    
    > h1 {
      font-size: 1em;
      text-align: center;
      margin-bottom: 10px;
      color: #323232;
    }
    
    p {
      font-size: 0.8em;
      text-align: center;
    }
  }
  
  #image {
    width: 200px;
  }

  @media (min-width: 50em) {
    #description {
      margin-right: 50px;
      
      > h1 {
        font-size: 1.3em;
        text-align: left;
        margin-bottom: 10px;
      }

      > p {
        font-size: 0.8em;
        text-align: left;
      }
    }
    
    flex-direction: row;

    #image {
      width: 200px;
      max-width: 300px;
      min-width: 200px;
    }
  }

  @media (min-width: 62.5em) {
    #description {
      > h1 {
        font-size: 1.6em;
      }

      > p {
        font-size: 1em;
      }
    }
    
    #image {
      width: 250px;
      max-width: 250px;
      min-width: 250px;
    }
  }
`

export const ContainerPlans = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 57.5em) {
    flex-direction: row;
    justify-content: center;

    .box {
      margin-bottom: 0px;
      
      :first-of-type {
        margin-right: 70px;
      }
    }
  }
  
  .box {
    flex: 1;
    border-radius: 10px;
    max-width: 500px;
    margin-bottom: 40px;
    padding: 20px;
    
    .icon {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      
      background-color: #D9D9D9;
      
      img {
        width: 50px;
      }
    }
    
    .header {
      display: flex;
      align-items: center;
      flex-direction: column;
      
      .title {
        font-size: 1.2em;
      }
    }
    
    .price {
      padding: 10px 0;
      text-align: center;
      
      > span {
        font-size: 1.9em;
        font-weight: bolder; 
        
        .period {
          font-size: 0.6em;
        }
      }
    }
    
    .benefits {
      margin-top: 20px;
      margin-bottom: 70px;
      list-style: none;
      
      .item {
        display: flex;
        align-items: center;
        font-size: 0.9em;
        margin-bottom: 30px;
        
        svg {
          margin-right: 20px;
        }
      }
    }
  }
  
  #premium {
    background-color: #124030;
    color: #fff;
  }
`

export const Terms = styled.div`
  padding-top: 20px;
  text-align: center;
  
  p {
    font-size: 0.8em;
  }
  
  a {
    text-decoration: none;
  }
`

export const CallBlog = styled.div`
  h1 { 
    font-size: 1.4em;
  }
`
