import PageMessageDialog from '../../../components/PageMessageDialog'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Link } from 'react-router-dom'
import React from 'react'

const BemVindo: React.FC = () => {
  return (
        <>
            <PageMessageDialog
                icon={<CheckCircleIcon sx={{ fontSize: '80px', color: '#27ae60' }} />}
                title="Bem-vindo"
                text={<p><Link to="/entrar">Clique aqui</Link> para fazer login.</p>}
            />
        </>
  )
}

export default BemVindo
