import styled from 'styled-components'

export const Container = styled.div`
    padding: 40px;

    select {
        padding: 10px;
        width: 100%;
        border: 1px solid #ccc;
        background-color: #fff;
    }

    #image {
        margin-top: 20px;
        width: 50%;
        height: 500px;
        background-color: #ccc;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .options {
            position: absolute;
            bottom: 0;
            left: 0%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
        }
    }

    button {
        background-color: red;
        color: #fff;
        width: 100px;
        height: 40px;
        padding: 0;
    }
`
