import React from 'react'
import Container from '../Container'
import { Title, ContainerFlex } from './styles'
import { useNavigate } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

interface Props {
    text: string;
};

const TitleSection: React.FC<Props> = ({ text }) => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
        <Container className="bg-white-3 padding-content-20">
            <ContainerFlex>
                <KeyboardBackspaceIcon className="pointer icon" onClick={goBack} />
                <Title>{text}</Title>
            </ContainerFlex>
        </Container>
  )
}

export default TitleSection
