import React from 'react'
import { LoadingStyled } from './styles'
import CircularProgress from '@mui/material/CircularProgress'

interface LoadingProps {
    open: boolean;
};

const Loading: React.FC<LoadingProps> = ({ open }) => {
  if (!open) return <></>

  return (
        <>
            <LoadingStyled>
                <div>
                    <CircularProgress
                        sx={{
                          color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
                        }}
                    />
                </div>
            </LoadingStyled>
        </>
  )
}

export default Loading
