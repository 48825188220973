import styled from 'styled-components'

export const Options = styled.div`
  display: flex;
  align-items: flex-end;
  
  > div {
    flex: 1;
  }
  
  button {
    padding: 5px 10px;
    max-width: 200px;
    margin-bottom: 22px;
    margin-left: 5px;
  }
`

export const List = styled.ol`
  list-style-position: inside;
  
  li {
    text-transform: uppercase;
  }
`
