import PageMessageDialog from '../../../components/PageMessageDialog'
import { MarkunreadIconStyled } from './styles'
import React from 'react'

const Link: React.FC = () => {
  return (
        <PageMessageDialog
            icon={<MarkunreadIconStyled />}
            title="E-mail de Confirmação"
            text={<p>Foi enviado para o seu e-mail, um link para confirmar sua conta.</p>}
        />
  )
}

export default Link
