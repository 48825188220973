import React from 'react'
import { BodyPadding, HeaderContainer, FooterContainer, PagSeguroIcon, Copy, MethodPayments } from './styles'
import { Link, matchRoutes, useLocation } from 'react-router-dom'
import Logotipo from '../../assets/png/logo.png'

const routes = [
  { path: '/login-backoffice' }
]

const useCurrentPath = () => {
  const location = useLocation()

  const router = matchRoutes(routes, location)

  return !!router
}

const Footer: React.FC = () => {
  const currentPath = useCurrentPath()

  if (currentPath) {
    return <></>
  }

  return (
        <>
            <HeaderContainer className="container bg-black">
                <div className="content">
                    <FooterContainer>
                        <article className="box">
                            <div id="logo">
                                <Link to="/">
                                    <a title="Página Inicial">
                                        <img title="Logotipo Pelejas" alt="Logotipo Pelejas" src={Logotipo}/>
                                    </a>
                                </Link>
                            </div>
                            <h1>Pelejas.com</h1>
                            <ul>
                                <Link to="/"><li>Sobre o Peleja</li></Link>
                                <Link to="/"><li>Entre em Contato</li></Link>
                                <Link to="/"><li>Politíca de Privacidade</li></Link>
                                <Link to="/"><li>Termos de uso</li></Link>
                            </ul>
                        </article>
                        <article className="box">
                            <h1>Assinatura</h1>
                            <ul>
                                <Link to="/"><li>Planos de Assinatura</li></Link>
                                <Link to="/"><li>Politíca de Reembolso</li></Link>
                            </ul>
                        </article>
                        <article className="box">
                            <h1>Redes Sociais</h1>
                            <ul>
                                <Link to="/"><li>Twitter</li></Link>
                                <Link to="/"><li>Instagram</li></Link>
                                <Link to="/"><li>Facebook</li></Link>
                            </ul>
                        </article>
                        <article className="box">
                            <h1>Formas de Pagamento</h1>
                            <MethodPayments>
                                <div><img src={process.env.PUBLIC_URL + '/images/svg/elo.svg'}/></div>
                                <div><img src={process.env.PUBLIC_URL + '/images/svg/amex.svg'}/></div>
                                <div><img src={process.env.PUBLIC_URL + '/images/svg/diners.svg'}/></div>
                                <div><img src={process.env.PUBLIC_URL + '/images/svg/hiper.svg'}/></div>
                                <div><img src={process.env.PUBLIC_URL + '/images/svg/hipercard.svg'}/></div>
                                <div><img src={process.env.PUBLIC_URL + '/images/svg/mastercard.svg'}/></div>
                                <div><img src={process.env.PUBLIC_URL + '/images/svg/visa.svg'}/></div>
                                <div><img src={process.env.PUBLIC_URL + '/images/svg/boleto.svg'}/></div>
                                <div><img src={process.env.PUBLIC_URL + '/images/svg/pix.svg'}/></div>
                            </MethodPayments>
                            <PagSeguroIcon id="desktop">
                                <img src={process.env.PUBLIC_URL + '/images/svg/mercado-pago.svg'}/>
                                <p>Esse site utiliza o <a href="https://conteudo.mercadopago.com.br/como-funciona-o-mercado-pago" title="Sobre o Mercado Pago" target="_blank" rel="nofollow noreferrer">Mercado Pago</a> em suas Transações</p>
                            </PagSeguroIcon>
                        </article>
                    </FooterContainer>
                    <PagSeguroIcon id="mobile">
                        <img src={process.env.PUBLIC_URL + '/images/svg/mercado-pago.svg'}/>
                        <p>Esse site utiliza o <a href="https://conteudo.mercadopago.com.br/como-funciona-o-mercado-pago" title="Sobre o Mercado Pago" target="_blank" rel="nofollow noreferrer">Mercado Pago</a> em suas Transações</p>
                    </PagSeguroIcon>
                    <Copy>
                        <p>© 2010 - 2022 - Pelejas, Todos os Direitos reservados</p>
                    </Copy>
                    <div className="clear"></div>
                </div>
            </HeaderContainer>
            <BodyPadding/>
        </>
  )
}

export default Footer
