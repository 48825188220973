import React from 'react'
import Container from '../../../components/Container'
import TitleSection from '../../../components/TitleSection'
import { api } from '../../../services/api'
import { useLoading } from '../../../hooks/useLoading'
import { Form } from '../../cadastro/styles'
import Input from '../../../components/Input'
import Alert from '@mui/material/Alert'
import { Formik, FormikValues } from 'formik'
import MaskedInput from 'react-text-mask'
import { InputContainer, Label } from '../../../components/Input/styles'
import getCep from 'cep-promise'
import { cellphoneTelephoneMask } from '../../../helpers'
import { useSnackbar } from 'notistack'
import Button from '../../../components/Button'
import { ButtonContainer } from '../../assinatura/informacoes-pessoais/styles'

interface CustomerMp {
    first_name: string;
    last_name: string;
    email: string;
    identification: {
        type: string;
        number: string;
    };
    phone: {
        area_code: string;
        number: string;
    },
    address: {
        id: string;
        zip_code: string;
        street_name: string;
        street_number: string;
        neighborhood: string;
        state: string;
        uf: string;
        country: string;
        city: string;
    },
}

interface FormFieldsError {
    nome?: string;
    sobrenome?: string;
    email?: string;
    cnpjCpf?: string;
}

const MeusDados: React.FC = () => {
  const [customer, setCustomer] = React.useState<CustomerMp>()

  const { showLoading, closeLoading, loading } = useLoading()
  const { enqueueSnackbar } = useSnackbar()

  React.useEffect(() => {
    getCustomer()
  }, [])

  const getCustomer = async () => {
    try {
      showLoading()

      const { data: userData } = await api.get('mercado-pago/client')

      setCustomer(userData.data)
    } catch (error: any) {

    } finally {
      closeLoading()
    }
  }

  const handleForm = async (values: FormikValues) => {
    try {
      showLoading()

      await api.put('/mercado-pago/client', {
        name: values.nome,
        lastname: values.sobrenome,
        telephone_cellphone: values.cellphone,
        cep: values.cep,
        logradouro: values.logradouro,
        number: values.number,
        bairro: values.bairro,
        cidade: values.cidade,
        uf: values.uf
      })

      enqueueSnackbar('Dados atualizados!', { variant: 'success' })
    } catch (e: any) {
      if (e.status === 400) {
        e.errors.map((error: string) => enqueueSnackbar(error, { variant: 'error' }))
      } else if (e.status === 500) {
        enqueueSnackbar('Erro ao cadastrar usuário no MercadoPago', { variant: 'error' })
      }
    } finally {
      closeLoading()
    }
  }

  const handleCep = async (cep: string) => {
    return await getCep(cep.replace(/\D/g, ''))
  }

  return (
        <>
            <TitleSection text="Meus Dados"/>
            {customer && (
                <Container>
                    <Formik
                        initialValues={{
                          cnpjCpf: customer.identification.number,
                          email: customer.email,
                          nome: customer.first_name,
                          sobrenome: customer.last_name,
                          cellphone: customer.phone.number,
                          cep: customer.address.zip_code,
                          logradouro: customer.address.street_name,
                          number: customer.address.street_number,
                          bairro: customer.address.neighborhood,
                          cidade: customer.address.city,
                          uf: customer.address.uf
                        }}
                        validate={values => {
                          const errors: FormFieldsError = {}

                          if (!values.email) {
                            errors.email = 'Campo obrigatório!'
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                          ) {
                            errors.email = 'E-mail inválido!'
                          }

                          return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          handleForm(values)
                        }}
                    >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                          /* and other goodies */
                        }) => {
                          const cepOnBlur = (e: React.FocusEvent<any>) => {
                            if (values.cep.replace(/\D/g, '').length === 8) {
                              showLoading()

                              handleCep(values.cep).then((response: any) => {
                                values.logradouro = response.street
                                values.uf = response.state
                                values.cidade = response.city
                                values.bairro = response.neighborhood
                              }).finally(() => {
                                closeLoading()
                              })
                            }

                            handleBlur(e)
                          }
                          return (<Form onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                          }}>
                                <div className="division">
                                    <div>
                                        <Input
                                            type="text"
                                            name="nome"
                                            id="nome"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nome}
                                            label="Nome Completo:"
                                            placeholder="Nome Completo"
                                            required
                                        />
                                        {errors.nome && touched.nome && (
                                            <Alert severity="error">{errors.nome}</Alert>
                                        )}
                                    </div>
                                    <div>
                                        <Input
                                            type="text"
                                            name="sobrenome"
                                            id="sobrenome"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.sobrenome}
                                            label="Sobrenome:"
                                            placeholder="Sobrenome"
                                            required
                                        />
                                        {errors.sobrenome && touched.sobrenome && (
                                            <Alert severity="error">{errors.sobrenome}</Alert>
                                        )}
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <Input
                                            type="text"
                                            name="cnpjCpf"
                                            id="cnpjCpf"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.cnpjCpf}
                                            label="CPF/CNPJ:"
                                            placeholder="CPF/CNPJ"
                                            disabled
                                        />
                                        {errors.cnpjCpf && touched.cnpjCpf && (
                                            <Alert severity="error">{errors.cnpjCpf}</Alert>
                                        )}
                                    </div>
                                    <div>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            label="E-mail:"
                                            placeholder="example@host.com"
                                            disabled
                                        />
                                        {errors.email && touched.email && (
                                            <Alert severity="error">{errors.email}</Alert>
                                        )}
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <MaskedInput
                                            type="text"
                                            name="cellphone"
                                            id="cellphone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.cellphone}
                                            placeholder="(__) _____-____"
                                            required
                                            guide={false}
                                            render={(ref: any, props) => (
                                                <InputContainer>
                                                    <Label htmlFor="cellphone">TEL/CEL PARA CONTATO:</Label>
                                                    <input className="input" ref={ref} {...props} />
                                                </InputContainer>
                                            )}
                                            mask={cellphoneTelephoneMask}
                                        />
                                        {errors.cellphone && touched.cellphone && (
                                            <Alert severity="error">{errors.cellphone}</Alert>
                                        )}
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <MaskedInput
                                            type="text"
                                            name="cep"
                                            autoComplete="some-random-name"
                                            id="cep"
                                            onChange={handleChange}
                                            onBlur={cepOnBlur}
                                            value={values.cep}
                                            placeholder="CEP"
                                            required
                                            guide={false}
                                            render={(ref: any, props) => (
                                                <InputContainer>
                                                    <Label htmlFor="cep">CEP:</Label>
                                                    <input className="input" ref={ref} {...props} />
                                                </InputContainer>
                                            )}
                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                        />
                                        {errors.cep && touched.cep && (
                                            <Alert severity="error">{errors.cep}</Alert>
                                        )}
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <Input
                                            type="text"
                                            name="logradouro"
                                            id="logradouro"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.logradouro}
                                            label="Logradouro:"
                                            placeholder="Logradouro"
                                            required
                                            disabled
                                        />
                                        {errors.logradouro && touched.logradouro && (
                                            <Alert severity="error">{errors.logradouro}</Alert>
                                        )}
                                    </div>
                                    <div>
                                        <Input
                                            type="number"
                                            name="number"
                                            id="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.number}
                                            label="N°"
                                            placeholder="Numero"
                                            required
                                        />
                                        {errors.number && touched.number && (
                                            <Alert severity="error">{errors.number}</Alert>
                                        )}
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <Input
                                            type="text"
                                            name="bairro"
                                            id="bairro"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.bairro}
                                            label="Bairro:"
                                            placeholder="Bairro"
                                            required
                                            disabled
                                        />
                                        {errors.bairro && touched.bairro && (
                                            <Alert severity="error">{errors.bairro}</Alert>
                                        )}
                                    </div>
                                    <div>
                                        <Input
                                            type="text"
                                            name="cidade"
                                            id="cidade"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.cidade}
                                            label="Cidade:"
                                            placeholder="Cidade"
                                            required
                                            disabled
                                        />
                                        {errors.cidade && touched.cidade && (
                                            <Alert severity="error">{errors.cidade}</Alert>
                                        )}
                                    </div>
                                </div>
                                <div className="division">
                                    <div>
                                        <Input
                                            type="text"
                                            name="uf"
                                            id="uf"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.uf}
                                            label="UF:"
                                            placeholder="UF"
                                            required
                                            disabled
                                        />
                                        {errors.uf && touched.uf && (
                                            <Alert severity="error">{errors.uf}</Alert>
                                        )}
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <ButtonContainer>
                                    <Button disabled={loading} type="submit">
                                        {loading ? 'Carregando' : 'Avançar'}
                                    </Button>
                                </ButtonContainer>
                            </Form>
                          )
                        }}
                    </Formik>
                </Container>
            )}
        </>
  )
}

export default MeusDados
