import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext";
import Loading from "../../components/Loading";
import ListarFichasTecnicas from "../fichas-tecnicas/index.page";
import Blog from "./blog";
import Header from "./components/Header";
import { DivContent, Division } from "./components/Header/styled";
import UserBarInfos from "./components/UserBarInfos";
import Enquete from "./enquete";
import Vizualizar from "./enquete/vizualizar";
import Enquetes from "./enquetes";
import FichasTecnicas from "./fichas-tecnicas";
import Logotipos from "./logotipos";
import Posts from "./posts";
import Usuarios from "./usuarios";

const LoginRoutes = () => {
  const location = useLocation();

  const { data, login, loading } = React.useContext(UserContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (login) {
      if (data.type_user !== "admin") {
        navigate(`${process.env.PUBLIC_URL}/entrar`);
      }
    }
  }, [data, login]);

  return (
    <Division
      className="bg-branco"
      inicio={location.pathname === "/backoffice/"}
    >
      {data.type_user === "admin" && (
        <>
          <Header />
          <DivContent>
            <UserBarInfos />
            <Routes>
              <Route path="/" element={<Usuarios />} />
              <Route path="/usuarios" element={<Usuarios />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<Blog />} />
              <Route path="/blog/posts" element={<Posts />} />
              <Route path="/enquete" element={<Enquete />} />
              <Route path="/enquete/:id" element={<Vizualizar />} />
              <Route path="/enquetes" element={<Enquetes />} />
              <Route path="/logotipos" element={<Logotipos />} />
              <Route path="/fichas-tecnicas" element={<ListarFichasTecnicas backoffice />} />
              <Route path="/fichas-tecnicas/:id" element={<FichasTecnicas />} />
              <Route path="/fichas-tecnicas/adicionar" element={<FichasTecnicas />} />
            </Routes>
            <Loading open={loading === true} />
          </DivContent>
        </>
      )}
    </Division>
  );
};

export default LoginRoutes;
