import React from 'react'
import { Route, Routes } from 'react-router-dom'
import BemVindo from '../../pages/assinatura/bem-vindo'
import InformacoesPessoais from '../../pages/assinatura/informacoes-pessoais'
import MeiosDePagamento from '../../pages/assinatura/meios-de-pagamento'
import Payment from '../Payment'
import { UserContext } from '../../UserContext'

const SignaturesRoutes = () => {
  const { subscriber } = React.useContext(UserContext)

  return (
        <Routes>
            <Route path="/bem-vindo" element={<BemVindo />}/>
            {!subscriber && (
                <>
                    <Route path="/informacoes-pessoais" element={<InformacoesPessoais />}/>
                    <Route path="/meios-de-pagamento" element={<MeiosDePagamento />}/>
                    <Route path="/pagamento/*" element={<Payment />}/>
                </>
            )}
        </Routes>
  )
}

export default SignaturesRoutes
