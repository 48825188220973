import styled, { createGlobalStyle } from 'styled-components'

export const LoadingStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  backdrop-filter: blur(4px);
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 100%;
  height: 100vh;
  
  background-color: rgba(0, 0, 0, 0.4);
`

export const BodyStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`
