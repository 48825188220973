import styled from 'styled-components'
import banner from '../../../src/assets/jpg/background-home.jpg'

export const ContainerStyled = styled.div`
  background: url(${banner});
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  width: 100%;
  height: 100vh;

  padding: 40px 20px;

  text-align: center;
  color: #666;
  background-color: #ededed;
  border-radius: 5px;

  h1 {
    font-size: 1.4em;
  }
  
  @media (min-width: 37.5em) {
    h1 {
      font-size: 1.8em;
    }

    border-radius: 5px;
    max-width: 500px;

    display: block;
    height: auto;
  }
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  
  #icon {
    width: 80px !important;
    height: 80px !important; 
  }
`

export const Dialog = styled.div`
  
`

export const Hr = styled.div`
  height: 0.5px;
  background-color: #ccc;
  margin-bottom: 20px;
  margin-top: 10px;
`
