import styled from 'styled-components'

export const Content = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: var(--max-width-content);
`

export const Clear = styled.div`
  clear: both;
`
