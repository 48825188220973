import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import type React from 'react'
import Input from '../Input'

interface CountryType {
    id?: number;
    name: string;
    image?: string;
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

interface SearchProps {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    options: any;
    inputComponentProps: InputProps;
    inputValue?: string;
    defaultValue?: string;
    setInputValue: (value: string) => void;
}

const SearchAucomplete: React.FC<SearchProps> = ({ ...props }) => {
    return (
        <Autocomplete
            id="select-opponent"
            options={props.options}
            autoHighlight
            getOptionLabel={(option: any) => option.name}
            defaultValue={{ name: props.defaultValue }}
            onChange={(event, newInputValue: any) => {
                props.setInputValue(newInputValue?.name ?? '')
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.inputComponentProps.label}
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                    }}
                />
            )}
        />
    )
}

export default SearchAucomplete
