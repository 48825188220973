import React from 'react'
import Container from '../../../components/Container'
import Table from '../../../components/Table'
import { GridRowData, GridColDef } from '@mui/x-data-grid'
import { api } from '../../../services/api'
import { UserContext } from '../../../UserContext'
import { useNavigate } from 'react-router'
import { IPost } from '../../../interfaces/blog'

const Posts: React.FC = () => {
  const [rows, setRows] = React.useState<GridRowData[]>([])

  const { setLoading } = React.useContext(UserContext)
  const navigate = useNavigate()

  React.useEffect(() => {
    getPosts()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'col1',
      headerName: 'ID',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'col2',
      headerName: 'Titulo',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'col3',
      headerName: 'Data de Criação',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'col4',
      headerName: 'Ação',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="link-default" onClick={() => viewUser(params.row.col1)}>Editar</span>
                    </div>
        )
      }
    }
  ]

  const viewUser = (id: number) => {
    navigate(`/backoffice/blog/${id}`)
  }

  const getPosts = async () => {
    try {
      setRows([])
      setLoading(true)

      const { data } = await api.get('/blog/posts')

      const posts = data.posts.map((el: IPost) => {
        const date = new Date(el.data)

        return {
          id: el.id,
          col1: el.id,
          col2: el.titulo,
          col3: date.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
        }
      })

      setRows(posts)
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  return (
        <Container>
            <Table rows={rows} columns={columns}/>
        </Container>
  )
}

export default Posts
