import React from 'react'
import Container from '../../../../components/Container'
import { api } from '../../../../services/api'
import { useParams } from 'react-router-dom'
import { useLoading } from '../../../../hooks/useLoading'
import { useSnackbar } from 'notistack'
import DOMPurify from 'dompurify'
import {
  Comment,
  Comments,
  ContainerComments,
  Form,
  ImagePerfil,
  InputComment
} from '../../../fichas-tecnicas/[id]/styles'
import Button from '../../../../components/Button'
import InfiniteScroll from 'react-infinite-scroll-component'
import ballKing from '../../../../assets/png/ball-king.png'
import ball from '../../../../assets/png/ball.png'
import moment from 'moment'
import { UserContext } from '../../../../UserContext'
import { CommentProps, CommentsData, IPost } from '../../../../interfaces/blog'

const Artigo: React.FC = () => {
  const [post, setPost] = React.useState<IPost>()
  const [comments, setComments] = React.useState<CommentsData | null>()
  const [page, setPage] = React.useState(1)
  const [selectedComment, setSelectComment] = React.useState<CommentProps | null>()
  const [commentValue, setCommentValue] = React.useState('')

  const { id } = useParams()
  const { showLoading, closeLoading } = useLoading()
  const { enqueueSnackbar } = useSnackbar()
  const { data: session } = React.useContext(UserContext)

  React.useEffect(() => {
    getPost(id)
    getComments()
  }, [])

  const updatePage = () => {
    setPage(page + 1)
  }

  const getComments = async (pageNumber?: number) => {
    try {
      showLoading()

      const { data: commentsData } = await api.get(`/blog/comments/${id}?${pageNumber ? pageNumber + 1 : 1}`)

      if (pageNumber) updatePage()

      setComments(commentsData)
    } catch (err: any) {
      if (err.status === 429) {
        handleSnackbar('Muitas tentativas! Aguarde 60 segundos para tentar novamente.')
      } else if (err.status === 400) {
        err.errors.map((error: string) => handleSnackbar(error))
      } else {
        handleSnackbar('Não foi possível obter comentários desse Jogo.')
      }
    } finally {
      closeLoading()
    }
  }

  const getPost = async (id?: string | undefined) => {
    if (!id) return

    try {
      showLoading()

      const { data: dataPost } = await api.get(`/blog/${id}`)

      setPost(dataPost)
    } catch (e: any) {
      handleSnackbar('Ocorreu um erro ao obter os posts')
    } finally {
      closeLoading()
    }
  }

  const handleSnackbar = (message: string) => {
    enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
  }

  const submitComment = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      showLoading()

      await api.post(`/blog/comments/${id}`, {
        text: commentValue
      })

      setCommentValue('')
      getComments()
    } catch (err: any) {
      if (err.status === 429) {
        handleSnackbar('Aguarde 60 segundos para tentar novamente.')
      } else if (err.status === 400) {
        err.errors.map((error: string) => handleSnackbar(error))
      } else {
        handleSnackbar('Erro interno.')
      }
    } finally {
      closeLoading()
    }
  }

  const submitCommentEdit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      if (!selectedComment) return

      showLoading()

      await api.put(`/blog/comments/${id}/${selectedComment.id_comment}`, {
        text: selectedComment.text
      })

      setSelectComment(null)
      getComments()
    } catch (e: any) {
      if (e.status === 429) {
        handleSnackbar('Aguarde 60 segundos para tentar novamente.')
      } else if (e.status === 400) {
        e.errors.map((error: string) => handleSnackbar(error))
      } else {
        handleSnackbar('Erro interno.')
      }
    } finally {
      closeLoading()
    }
  }

  const editComment = (comment: CommentProps) => {
    setSelectComment(comment)
  }

  const deleteComment = async (idComment: number) => {
    try {
      showLoading()

      await api.delete(`/blog/comments/${id}/${idComment}`)
      getComments()
    } catch (e: any) {
      if (e.status === 429) {
        handleSnackbar('Aguarde 60 segundos para tentar novamente.')
      } else if (e.status === 400) {
        e.errors.map((error: string) => handleSnackbar(error))
      } else {
        handleSnackbar('Erro interno.')
      }
    } finally {
      closeLoading()
    }
  }

  const handleChangeComment = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setCommentValue(e.currentTarget.value)
  }

  const handleChangeCommentEdit = (e: React.FormEvent<HTMLTextAreaElement>) => {
    if (!selectedComment) return
    setSelectComment({ ...selectedComment, text: e.currentTarget.value })
  }

  return (
        <>
            <Container className="bg-white-3">
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post?.texto ?? '') }}></div>
            </Container>
            <Container>
                <ContainerComments>
                    <h1>Comentários:</h1>
                    <Form onSubmit={submitComment}>
                        <InputComment
                            placeholder="Digite seu comentário.... (máximo 400 caracteres)"
                            value={commentValue}
                            onChange={handleChangeComment}
                            maxLength={400}
                        />
                        <Button disabled={!commentValue.trim()}>Enviar</Button>
                    </Form>
                    {(comments?.comments && comments?.comments.length > 0) && (
                        <InfiniteScroll
                            dataLength={comments.comments.length}
                            next={() => {
                              getComments(page)
                            }}
                            hasMore={comments.hasMoreComments}
                            loader={<h4>Carregando...</h4>}
                        >
                            <Comments>
                                {comments.comments.map(comment => (
                                    <Comment key={comment.id_comment}>
                                        <ImagePerfil>
                                            {comment.isSubscriber
                                              ? (
                                                    <img
                                                        src={ballKing}
                                                        alt="Logotipo Pelejas"
                                                    />
                                                )
                                              : (
                                                    <img
                                                        src={ball}
                                                        alt="Logotipo Pelejas"
                                                    />
                                                )}
                                        </ImagePerfil>
                                        <div>
                                            <h2>{comment.name_user} <span>ás {moment(comment.date_comment).format('DD/MM/YYYY')}</span></h2>

                                            {(selectedComment && selectedComment.id_comment === comment.id_comment)
                                              ? (
                                                    <Form onSubmit={submitCommentEdit}>
                                                        <InputComment
                                                            placeholder="Digite seu comentário.... (máximo 400 caracteres)"
                                                            value={selectedComment.text}
                                                            onChange={handleChangeCommentEdit}
                                                            maxLength={400}
                                                        />
                                                        <Button disabled={!selectedComment.text.trim()}>Enviar</Button>
                                                    </Form>
                                                )
                                              : (
                                                    <p>{comment.text}</p>
                                                )
                                            }

                                            {(session && session.id === comment.id_user) && (
                                                <div className="options-comment">
                                                    <span className="text-link" onClick={() => deleteComment(comment.id_comment)}>Excluir</span>
                                                    <span>|</span>
                                                    <span className="text-link" onClick={() => editComment(comment)}>Editar</span>
                                                </div>
                                            )}
                                        </div>
                                    </Comment>
                                ))}
                            </Comments>
                        </InfiniteScroll>
                    )}
                </ContainerComments>
            </Container>
        </>
  )
}

export default Artigo
