import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MeusDados from '../../pages/perfil/meus-dados'
import Cartoes from '../../pages/perfil/cartoes'
import Adicionar from '../../pages/perfil/cartoes/adicionar'
import Faturas from '../../pages/perfil/faturas'
import Assinatura from '../../pages/minha-conta/assinatura'

const PerfilRoutes = () => {
  return (
        <Routes>
            <Route path="/" element={<MeusDados />}/>
            <Route path="/cartoes" element={<Cartoes />}/>
            <Route path="/cartoes/adicionar" element={<Adicionar />}/>
            <Route path="/faturas" element={<Faturas />}/>
            <Route path="/assinatura" element={<Assinatura />}/>
        </Routes>
  )
}

export default PerfilRoutes
