import axios from 'axios'
import { API_BASE_URL } from '../config/contants'
import secure from '../config/secureLS'

export const api = axios.create({
  baseURL: API_BASE_URL
})

api.interceptors.request.use((config) => {
  const token = secure.get('pelejas@access-token')
  if (token?.data) {
    config.headers = {
      Authorization: `Bearer ${token.data}`,
      Accept: 'application/json'
    }
  } else {
    const sessionId = secure.get('@pelejas:session_id')

    if (sessionId?.data) {
      config.headers = {
        session_id: sessionId.data,
        Accept: 'application/json'
      }
    }
  }
  return config
})

api.interceptors.response.use(
  (res) => res,
  (error) => {
    const { response } = error
    if (response) {
      if (response.status === 401) {
        secure.clear()
        window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/entrar?redirect=${window.location.href}` || ''
      }

      throw Object.assign({ ...response.data, status: response.status })
    } else if (error.message === 'Network Error') {
      throw Object.assign(
        new Error('Verifique sua Conexão de Internet!')
      )
    }
  }
)
