import NoteAltIcon from '@mui/icons-material/NoteAlt'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import DOMPurify from 'dompurify'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import { UserContext } from '../../../UserContext'
import ballKing from '../../../assets/png/ball-king.png'
import ball from '../../../assets/png/ball.png'
import Button from '../../../components/Button'
import Container from '../../../components/Container'
import { useLoading } from '../../../hooks/useLoading'
import { api } from '../../../services/api'
import { TeamsSelect, ToolCharts } from '../../inicio/styles'
import {
    AddHtml,
    ArrowDownwardIconStyled,
    ArrowUpwardIconStyled,
    Comment,
    Comments,
    ContainerComments,
    ContentHtml,
    Description,
    Desktop,
    Form,
    HeaderLineup,
    Hr,
    ImagePerfil,
    InputComment,
    Mobile,
    Players,
    Referee,
    SportsSoccerStyled,
    SportsStyled,
    SquareStyled
} from './styles'

interface Goals {
    gol: string;
    time: string;
    period: string;
}

interface Cards {
    card: string;
    time: string;
    period: string;
}

interface Lineup {
    name: string;
    goals?: Goals[];
    cards?: Cards[];
    isReplacement: boolean;
    wasReplacement: boolean;
    position?: number;
}

interface GameData {
    public: number;
    name_opponent: string;
    logo_opponent: string;
    referee: string;
    assistant: string;
    second_assistant?: string;
    coach_palmeiras: string;
    coach_adversary: string;
    score_palmeiras: number;
    score_adversary: number;
    stadium?: string;
    championship?: string;
    date: string;
    stage: string;
    period: string;
    html: string;
}

export interface Datasheet {
    game_data: GameData,
    lineup_palmeiras: Lineup[],
    lineup_adversary: Lineup[],
}

interface CommentProps {
    name_user: string;
    id_comment: number;
    id_user: number;
    text: string;
    date_comment: string;
    isSubscriber: boolean;
}

interface CommentsData {
    comments: CommentProps[];
    hasMoreComments: boolean;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

const FichaTecnica: React.FC = () => {
    const [commentValue, setCommentValue] = React.useState('')
    const [value, setValue] = React.useState(0)
    const [page, setPage] = React.useState(1)
    const [selectedComment, setSelectComment] = React.useState<CommentProps | null>()

    const { id } = useParams()

    const { showLoading, closeLoading } = useLoading()
    const { enqueueSnackbar } = useSnackbar()

    const [sheet, setSheet] = React.useState<Datasheet | null>()
    const [comments, setComments] = React.useState<CommentsData | null>()

    const { data: session } = React.useContext(UserContext)

    React.useEffect(() => {
        getGameInfo()
        getComments()
    }, [])

    const getGameInfo = async () => {
        try {
            showLoading()

            const { data: datasheetData } = await api.get(`/datasheet/${id}`)

            setSheet(datasheetData.data)
        } catch (err) {
            handleSnackbar('Não foi possível obter informações desse jogo.')
        } finally {
            closeLoading()
        }
    }

    const getComments = async (pageNumber?: number) => {
        try {
            showLoading()
            const page = pageNumber ? pageNumber + 1 : 1
            const { data: commentsData } = await api.get(`/datasheet/comments/${id}?page=${page}`)

            if (pageNumber) updatePage()

            setComments({
                comments: commentsData.data,
                hasMoreComments: commentsData.meta.last_page > page
            })
        } catch (err: any) {
            if (err.status === 429) {
                handleSnackbar('Muitas tentativas! Aguarde 60 segundos para tentar novamente.')
            } else if (err.status === 400) {
                err.errors.map((error: string) => handleSnackbar(error))
            } else {
                handleSnackbar('Não foi possível obter comentários desse Jogo.')
            }
        } finally {
            closeLoading()
        }
    }

    const handleSnackbar = (message: string) => {
        enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'bottom', horizontal: 'center' } })
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const submitComment = async (e: React.FormEvent) => {
        e.preventDefault()

        try {
            showLoading()

            await api.post(`/datasheet/comments/${id}`, {
                text: commentValue
            })

            setCommentValue('')
            getComments()
        } catch (err: any) {
            if (err.status === 429) {
                handleSnackbar('Aguarde 60 segundos para tentar novamente.')
            } else if (err.status === 400) {
                err.errors.map((error: string) => handleSnackbar(error))
            } else {
                handleSnackbar('Erro interno.')
            }
        } finally {
            closeLoading()
        }
    }

    const submitCommentEdit = async (e: React.FormEvent) => {
        e.preventDefault()

        try {
            if (!selectedComment) return

            showLoading()

            await api.put(`/datasheet/comments/${id}/${selectedComment.id_comment}`, {
                text: selectedComment.text
            })

            setSelectComment(null)
            getComments()
        } catch (e: any) {
            if (e.status === 429) {
                handleSnackbar('Aguarde 60 segundos para tentar novamente.')
            } else if (e.status === 400) {
                e.errors.map((error: string) => handleSnackbar(error))
            } else {
                handleSnackbar('Erro interno.')
            }
        } finally {
            closeLoading()
        }
    }

    const editComment = (comment: CommentProps) => {
        setSelectComment(comment)
    }

    const deleteComment = async (idComment: number) => {
        try {
            showLoading()

            await api.delete(`/datasheet/comments/${id}/${idComment}`)
            getComments()
        } catch (e: any) {
            if (e.status === 429) {
                handleSnackbar('Aguarde 60 segundos para tentar novamente.')
            } else if (e.status === 400) {
                e.errors.map((error: string) => handleSnackbar(error))
            } else {
                handleSnackbar('Erro interno.')
            }
        } finally {
            closeLoading()
        }
    }

    const updatePage = () => {
        setPage(page + 1)
    }

    const label_period = sheet?.game_data.period === 'N' ? 'Noite' : sheet?.game_data.period === 'T' ? 'Tarde' : 'Manhã'
    const goals_palmeiras = sheet?.lineup_palmeiras.filter(player => player.goals && player.goals.length > 0)
    const goals_adversary = sheet?.lineup_adversary.filter(player => player.goals && player.goals.length > 0)
    const cards_palmeiras = sheet?.lineup_palmeiras.filter(player => player.cards && player.cards.length > 0)
    const cards_adversary = sheet?.lineup_adversary.filter(player => player.cards && player.cards.length > 0)

    const weekDays = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado']

    let getDate
    if (sheet?.game_data.date) {
        const breakDate = sheet?.game_data.date.split('/').reverse()
        getDate = new Date(Number.parseInt(breakDate[0]), Number.parseInt(breakDate[1]) - 1, Number.parseInt(breakDate[2]))
    } else {
        getDate = new Date()
    }
    const dayOfWeek = getDate.getDay()

    const handleChangeComment = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setCommentValue(e.currentTarget.value)
    }

    const handleChangeCommentEdit = (e: React.FormEvent<HTMLTextAreaElement>) => {
        if (!selectedComment) return
        setSelectComment({ ...selectedComment, text: e.currentTarget.value })
    }

    return (
        <>
            <ToolCharts>
                <Container>
                    <TeamsSelect>
                        <div id="palmeiras" className="team">
                            <span>PALMEIRAS</span>
                            <div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/svg/pal.svg'} />
                                </div>
                            </div>
                        </div>
                        <div id="versus">
                            <span>{sheet?.game_data.score_palmeiras}</span>
                        </div>
                        <div id="versus">
                            <span>X</span>
                        </div>
                        <div id="versus">
                            <span>{sheet?.game_data.score_adversary}</span>
                        </div>
                        <div id="opponent" className="team">
                            <div>
                                <div>
                                    <img src={sheet?.game_data.logo_opponent} />
                                </div>
                            </div>
                            <span>{(sheet?.game_data.name_opponent && sheet?.game_data.name_opponent.length > 9) ? `${sheet?.game_data.name_opponent.slice(0, 6)}...` : sheet?.game_data.name_opponent}</span>
                        </div>
                    </TeamsSelect>
                    <div>
                        <Description>
                            {sheet?.game_data.championship && <p>{sheet?.game_data.championship}</p>}
                            {sheet?.game_data.stage && <p>{sheet?.game_data.stage}</p>}
                            {sheet?.game_data.stadium && <p>{sheet?.game_data.stadium}</p>}
                            {sheet?.game_data.date && (<p>{sheet?.game_data.date} ({weekDays[dayOfWeek]}) - {label_period}</p>)}
                            {sheet?.game_data.date && (<p>Público: {sheet?.game_data.public}</p>)}
                        </Description>
                    </div>
                </Container>
            </ToolCharts>
            <Container className="bg-white-3" tag="main">
                <Referee>
                    <div>
                        <SportsStyled /><p><strong>Árbitro:</strong> {sheet?.game_data.referee}</p>
                    </div>
                    <div>
                        <SettingsAccessibilityIcon /><p><strong>Auxiliare(s):</strong> {sheet?.game_data.assistant} {sheet?.game_data.second_assistant && `e ${sheet?.game_data.second_assistant}}`}</p>
                    </div>
                </Referee>
                <Mobile>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="PALMEIRAS" {...a11yProps(0)} />
                            <Tab label={sheet?.game_data.name_opponent} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Players>
                            <div className="box">
                                <ul>
                                    {sheet?.lineup_palmeiras.map(player => (
                                        <li key={player.name}>
                                            <span>{player.name}</span>
                                            <div className="status">
                                                {player.cards && player.cards.map((card, index) => <SquareStyled key={index} coloricon={card.card !== 'a' ? 'red' : 'yellow'} />)}
                                                {player.goals && player.goals.map((goal, index) => <SportsSoccerStyled coloricon={goal.gol === 'c' ? 'red' : 'green'} key={index} />)}
                                                {player.isReplacement && <ArrowUpwardIconStyled />}
                                                {player.wasReplacement && <ArrowDownwardIconStyled />}
                                            </div>
                                        </li>
                                    )
                                    )}
                                    {sheet?.game_data.coach_palmeiras && (
                                        <li>
                                            <div className="coach">
                                                <NoteAltIcon />
                                                <div>
                                                    <span><strong>Técnico:</strong> {sheet?.game_data.coach_palmeiras}</span>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                    {(goals_palmeiras && goals_palmeiras.length > 0) && (
                                        <li>
                                            <div className="flex-1">
                                                <div className="goals">
                                                    <SportsSoccerIcon />
                                                    <span className="text-bold">Gols Marcados</span>
                                                </div>
                                                <div className="hr">
                                                    <Hr />
                                                </div>
                                                <div>
                                                    {goals_palmeiras.map((player, index) => (
                                                        <div key={index}>
                                                            {player.goals && player.goals.map((goal, indexGoal) => (
                                                                <div key={indexGoal}>
                                                                    <p>- {player.name} <strong>Marcou</strong> aos {goal.time}min do {goal.period}º tempo {goal.gol !== 'n' && <span>({goal.gol === 'p' ? 'PENALTI' : 'CONTRA'})</span>}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                    {(cards_palmeiras && cards_palmeiras.length > 0) && (
                                        <li>
                                            <div className="flex-1">
                                                <div className="goals">
                                                    <span className="text-bold">Cartões</span>
                                                </div>
                                                <div className="hr">
                                                    <Hr />
                                                </div>
                                                <div>
                                                    {cards_palmeiras.map((player, index) => (
                                                        <div key={index}>
                                                            {player.cards && player.cards.map((card, indexGoal) => (
                                                                <div key={indexGoal}>
                                                                    <p>- {player.name} <strong>Recebeu</strong> Cartão {card.card === 'v' ? 'Vermelho' : 'Amarelo'} {(card.time && card.period) && (<span>aos {card.time}min do {card.period}º tempo</span>)}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </Players>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Players>
                            <div className="box">
                                <ul>
                                    {sheet?.lineup_adversary.map(player => (
                                        <li key={player.name}>
                                            <span>{player.name}</span>
                                            <div className="status">
                                                {player.cards && player.cards.map((card, index) => <SquareStyled key={index} coloricon={card.card !== 'a' ? 'red' : 'yellow'} />)}
                                                {player.goals && player.goals.map((goal, index) => <SportsSoccerStyled coloricon={goal.gol === 'c' ? 'red' : 'green'} key={index} />)}
                                                {player.isReplacement && <ArrowUpwardIconStyled />}
                                                {player.wasReplacement && <ArrowDownwardIconStyled />}
                                            </div>
                                        </li>
                                    )
                                    )}
                                    {sheet?.game_data.coach_adversary && (
                                        <li>
                                            <div className="coach">
                                                <NoteAltIcon />
                                                <div>
                                                    <span><strong>Técnico:</strong> {sheet?.game_data.coach_adversary}</span>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                    {(goals_adversary && goals_adversary.length > 0) && (
                                        <li>
                                            <div className="flex-1">
                                                <div className="goals">
                                                    <SportsSoccerIcon />
                                                    <span className="text-bold">Gols Marcados</span>
                                                </div>
                                                <div className="hr">
                                                    <Hr />
                                                </div>
                                                <div>
                                                    {goals_adversary.map((player, index) => (
                                                        <div key={index}>
                                                            {player.goals && player.goals.map((goal, indexGoal) => (
                                                                <div key={indexGoal}>
                                                                    <p>- {player.name} <strong>Marcou</strong> aos {goal.time}min do {goal.period}º tempo {goal.gol !== 'n' && <span>({goal.gol === 'p' ? 'PENALTI' : 'CONTRA'})</span>}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                    {(cards_adversary && cards_adversary.length > 0) && (
                                        <li>
                                            <div className="flex-1">
                                                <div className="goals">
                                                    <span className="text-bold">Cartões</span>
                                                </div>
                                                <div className="hr">
                                                    <Hr />
                                                </div>
                                                <div>
                                                    {cards_adversary.map((player, index) => (
                                                        <div key={index}>
                                                            {player.cards && player.cards.map((card, indexGoal) => (
                                                                <div key={indexGoal}>
                                                                    <p>- {player.name} <strong>Recebeu</strong> Cartão {card.card === 'v' ? 'Vermelho' : 'Amarelo'} {(card.time && card.period) && (<span>aos {card.time}min do {card.period}º tempo</span>)}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </Players>
                    </TabPanel>
                </Mobile>
                <Desktop>
                    <Players>
                        <div className="box">
                            <HeaderLineup>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/svg/pal.svg'} />
                                </div>
                                <span>PALMEIRAS</span>
                            </HeaderLineup>
                            <ul>
                                {sheet?.lineup_palmeiras.map(player => (
                                    <li key={player.name}>
                                        <span>{player.name}</span>
                                        <div className="status">
                                            {player.cards && player.cards.map((card, index) => <SquareStyled key={index} coloricon={card.card !== 'a' ? 'red' : 'yellow'} />)}
                                            {player.goals && player.goals.map((goal, index) => <SportsSoccerStyled coloricon={goal.gol === 'c' ? 'red' : 'green'} key={index} />)}
                                            {player.isReplacement && <ArrowUpwardIconStyled />}
                                            {player.wasReplacement && <ArrowDownwardIconStyled />}
                                        </div>
                                    </li>
                                )
                                )}
                                {sheet?.game_data.coach_palmeiras && (
                                    <li>
                                        <div className="coach">
                                            <NoteAltIcon />
                                            <div>
                                                <span><strong>Técnico:</strong> {sheet?.game_data.coach_palmeiras}</span>
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {(goals_palmeiras && goals_palmeiras.length > 0) && (
                                    <li>
                                        <div className="flex-1">
                                            <div className="goals">
                                                <SportsSoccerIcon />
                                                <span className="text-bold">Gols Marcados</span>
                                            </div>
                                            <div className="hr">
                                                <Hr />
                                            </div>
                                            <div>
                                                {goals_palmeiras.map((player, index) => (
                                                    <div key={index}>
                                                        {player.goals && player.goals.map((goal, indexGoal) => (
                                                            <div key={indexGoal}>
                                                                <p>- {player.name} <strong>Marcou</strong> aos {goal.time}min do {goal.period}º tempo {goal.gol !== 'n' && <span>({goal.gol === 'p' ? 'PENALTI' : 'CONTRA'})</span>}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {(cards_palmeiras && cards_palmeiras.length > 0) && (
                                    <li>
                                        <div className="flex-1">
                                            <div className="goals">
                                                <span className="text-bold">Cartões</span>
                                            </div>
                                            <div className="hr">
                                                <Hr />
                                            </div>
                                            <div>
                                                {cards_palmeiras.map((player, index) => (
                                                    <div key={index}>
                                                        {player.cards && player.cards.map((card, indexGoal) => (
                                                            <div key={indexGoal}>
                                                                <p>- {player.name} <strong>Recebeu</strong> Cartão {card.card === 'v' ? 'Vermelho' : 'Amarelo'} {(card.time && card.period) && (<span>aos {card.time}min do {card.period}º tempo</span>)}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="box">
                            <HeaderLineup>
                                <div>
                                    <img src={sheet?.game_data.logo_opponent} />
                                </div>
                                <span>{sheet?.game_data.name_opponent}</span>
                            </HeaderLineup>
                            <ul>
                                {sheet?.lineup_adversary.map(player => (
                                    <li key={player.name}>
                                        <span>{player.name}</span>
                                        <div className="status">
                                            {player.cards && player.cards.map((card, index) => <SquareStyled key={index} coloricon={card.card !== 'a' ? 'red' : 'yellow'} />)}
                                            {player.goals && player.goals.map((goal, index) => <SportsSoccerStyled coloricon={goal.gol === 'c' ? 'red' : 'green'} key={index} />)}
                                            {player.isReplacement && <ArrowUpwardIconStyled />}
                                            {player.wasReplacement && <ArrowDownwardIconStyled />}
                                        </div>
                                    </li>
                                )
                                )}
                                {sheet?.game_data.coach_adversary && (
                                    <li>
                                        <div className="coach">
                                            <NoteAltIcon />
                                            <div>
                                                <span><strong>Técnico:</strong> {sheet?.game_data.coach_adversary}</span>
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {(goals_adversary && goals_adversary.length > 0) && (
                                    <li>
                                        <div className="flex-1">
                                            <div className="goals">
                                                <SportsSoccerIcon />
                                                <span className="text-bold">Gols Marcados</span>
                                            </div>
                                            <div className="hr">
                                                <Hr />
                                            </div>
                                            <div>
                                                {goals_adversary.map((player, index) => (
                                                    <div key={index}>
                                                        {player.goals && player.goals.map((goal, indexGoal) => (
                                                            <div key={indexGoal}>
                                                                <p>- {player.name} <strong>Marcou</strong> aos {goal.time}min do {goal.period}º tempo {goal.gol !== 'n' && <span>({goal.gol === 'p' ? 'PENALTI' : 'CONTRA'})</span>}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {(cards_adversary && cards_adversary.length > 0) && (
                                    <li>
                                        <div className="flex-1">
                                            <div className="goals">
                                                <span className="text-bold">Cartões</span>
                                            </div>
                                            <div className="hr">
                                                <Hr />
                                            </div>
                                            <div>
                                                {cards_adversary.map((player, index) => (
                                                    <div key={index}>
                                                        {player.cards && player.cards.map((card, indexGoal) => (
                                                            <div key={indexGoal}>
                                                                <p>- {player.name} <strong>Recebeu</strong> Cartão {card.card === 'v' ? 'Vermelho' : 'Amarelo'} {(card.time && card.period) && (<span>aos {card.time}min do {card.period}º tempo</span>)}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </Players>
                </Desktop>
                {sheet?.game_data.html && (
                    <AddHtml>
                        <h1>Informações Adicionais:</h1>
                        <ContentHtml dangerouslySetInnerHTML={{ __html: sheet?.game_data.html }}></ContentHtml>
                    </AddHtml>
                )}
            </Container>
            <Container>
                <ContainerComments>
                    <h1>Comentários:</h1>
                    <Form onSubmit={submitComment}>
                        <InputComment
                            placeholder="Digite seu comentário.... (máximo 400 caracteres)"
                            value={commentValue}
                            onChange={handleChangeComment}
                            maxLength={400}
                        />
                        <Button disabled={!commentValue.trim()}>Enviar</Button>
                    </Form>
                    {(comments?.comments && comments?.comments.length > 0) && (
                        <InfiniteScroll
                            dataLength={comments.comments.length}
                            next={() => {
                                getComments(page)
                            }}
                            hasMore={comments.hasMoreComments}
                            loader={<h4>Carregando...</h4>}
                        >
                            <Comments>
                                {comments.comments.map(comment => (
                                    <Comment key={comment.id_comment}>
                                        <ImagePerfil>
                                            {comment.isSubscriber
                                                ? (
                                                    <img
                                                        src={ballKing}
                                                        alt="Logotipo Pelejas"
                                                    />
                                                )
                                                : (
                                                    <img
                                                        src={ball}
                                                        alt="Logotipo Pelejas"
                                                    />
                                                )}
                                        </ImagePerfil>
                                        <div>
                                            <h2>{comment.name_user} <span>ás {moment(comment.date_comment).format('DD/MM/YYYY')}</span></h2>

                                            {(selectedComment && selectedComment.id_comment === comment.id_comment)
                                                ? (
                                                    <Form onSubmit={submitCommentEdit}>
                                                        <InputComment
                                                            placeholder="Digite seu comentário.... (máximo 400 caracteres)"
                                                            value={selectedComment.text}
                                                            onChange={handleChangeCommentEdit}
                                                            maxLength={400}
                                                        />
                                                        <Button disabled={!selectedComment.text.trim()}>Enviar</Button>
                                                    </Form>
                                                )
                                                : (
                                                    <p>{comment.text}</p>
                                                )
                                            }

                                            {(session && session.id === comment.id_user) && (
                                                <div className="options-comment">
                                                    <span className="text-link" onClick={() => deleteComment(comment.id_comment)}>Excluir</span>
                                                    <span>|</span>
                                                    <span className="text-link" onClick={() => editComment(comment)}>Editar</span>
                                                </div>
                                            )}
                                        </div>
                                    </Comment>
                                ))}
                            </Comments>
                        </InfiniteScroll>
                    )}
                </ContainerComments>
            </Container>
        </>
    )
}

export default FichaTecnica
