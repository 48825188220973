import React from 'react'
import Container from '../../components/Container'
import TitleSection from '../../components/TitleSection'
import { CardContainer } from './styles'
import { Link } from 'react-router-dom'
import ReceiptIcon from '@mui/icons-material/Receipt'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import PersonIcon from '@mui/icons-material/Person'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

const MinhaConta: React.FC = () => {
  return (
        <>
            <TitleSection text="Minha Conta"/>
            <Container>
                <CardContainer>
                    <Link className="card" to="/perfil/cartoes">
                        <CreditCardIcon />
                        <span>MEUS CARTÕES</span>
                    </Link>
                    <Link className="card" to="/perfil/faturas">
                        <ReceiptIcon />
                        <span>FATURAS</span>
                    </Link>
                    <Link className="card" to="/perfil/assinatura">
                        <AttachMoneyIcon />
                        <span>ASSINATURA</span>
                    </Link>
                    <Link className="card" to="/perfil">
                        <PersonIcon />
                        <span>MEUS DADOS</span>
                    </Link>
                </CardContainer>
            </Container>
        </>
  )
}

export default MinhaConta
