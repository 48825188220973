import React from 'react'
import Container from '../../../components/Container'
import Table from '../../../components/Table'
import { GridRowData, GridColDef } from '@mui/x-data-grid'
import { api } from '../../../services/api'
import { UserContext } from '../../../UserContext'
import { useNavigate } from 'react-router-dom'

interface Survey {
    id: number
    question: string
    expire_at: string
    created_at: string
    updated_at: string
    deleted_at: any
}

const Enquetes: React.FC = () => {
  const [rows, setRows] = React.useState<GridRowData[]>([])

  const { setLoading } = React.useContext(UserContext)
  const navigate = useNavigate()

  React.useEffect(() => {
    getSurveys()

    // eslint-disable-next-line
    }, []);

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'ID', flex: 1, minWidth: 150 },
    { field: 'col2', headerName: 'Titulo', flex: 1, minWidth: 150 },
    { field: 'col3', headerName: 'Expira em', flex: 1, minWidth: 150 },
    {
      field: 'col4',
      headerName: 'Ação',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="link-warning" onClick={() => deleteSurvey(params.row.col1)}>Deletar</span>
                    </div>
        )
      }
    },
    { field: 'col5', headerName: 'Id Pagamedor MP', flex: 1, minWidth: 150, hide: true },
    { field: 'col6', headerName: 'Plano', flex: 1, minWidth: 150, hide: true },
    {
      field: 'col7',
      headerName: 'Ação',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="link-default" onClick={() => handlePlans(params.row.col1)}>Visualizar</span>
                    </div>
        )
      }
    }
  ]

  const deleteSurvey = async (id: number) => {
    try {
      setRows([])

      await api.delete(`/survey/${id}`)
    } catch (error) {

    } finally {
      getSurveys()
    }
  }

  const handlePlans = async (id: number) => {
    try {
      setRows([])

      navigate(`/backoffice/enquete/${id}`)
    } catch (error) {

    } finally {
      getSurveys()
    }
  }

  const getSurveys = async () => {
    try {
      setRows([])
      setLoading(true)

      const { data } = await api.get('/surveys')

      const surveys = data.map((el: Survey) => {
        const data = el.expire_at ? new Intl.DateTimeFormat('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(el.expire_at)) : ''

        return {
          id: el.id,
          col1: el.id,
          col2: el.question,
          col3: data
        }
      })

      setRows(surveys)
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  return (
        <Container>
            <Table rows={rows} columns={columns}/>
        </Container>
  )
}

export default Enquetes
