import PageMessageDialog from '../../../components/PageMessageDialog'
import MarkunreadIcon from '@mui/icons-material/Markunread'
import React from 'react'

const Link: React.FC = () => {
  return (
        <>
            <PageMessageDialog
                icon={<MarkunreadIcon sx={{ fontSize: '80px', color: '#ccc' }} />}
                title="Redefinir Senha"
                text={<p>Foi enviado para o seu <strong>e-mail</strong>, um link para redefinir a sua senha.</p>}
            />
        </>
  )
}

export default Link
