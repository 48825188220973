import React from 'react'
import { ContainerUserInfo, Infos, Responsive, PageNow } from './styled'
import { ListItemIcon, ListItemText } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { UserContext } from '../../../../UserContext'
import { useLocation, useNavigate } from 'react-router'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'

const UserBarInfos: React.FC = () => {
  const { userLogout, data } = React.useContext(UserContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [title, setTitle] = React.useState('')
  const navigate = useNavigate()
  const location = useLocation()

  const handleLogout = () => {
    userLogout()
    navigate('/entrar', { replace: true })
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const toEdit = () => {
    handleClose()
    navigate('editar/perfil')
  }

  // eslint-disable-next-line
    React.useEffect(() => {
    setTitle(document.title)
    // eslint-disable-next-line
    });

  return (
        <Responsive inicio={location.pathname === '/backoffice/'}>
            <ContainerUserInfo>
                <Infos>
                    <PageNow>{title && title.split('-')[0]}</PageNow>
                </Infos>
                <Infos onClick={handleClick}>
                    <Avatar className="pointer" alt={data.nome} src="/static/images/avatar/perfil.jpg"/>
                    <div className="pointer">
                        <h1>{data.nome && data.nome.substring(0, 30) + '...'}</h1>
                        <p>ADMINISTRADOR</p>
                    </div>
                </Infos>
                <Menu
                    elevation={0}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => toEdit()}>
                        <ListItemIcon>
                            <PersonIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText primary="Editar Perfil"/>
                    </MenuItem>
                    <MenuItem onClick={() => handleLogout()}>
                        <ListItemIcon>
                            <ExitToAppIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText primary="Sair"/>
                    </MenuItem>
                </Menu>
            </ContainerUserInfo>
        </Responsive>
  )
}

export default UserBarInfos
