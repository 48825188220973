import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Pix from '../../pages/assinatura/pagamento/pix'
import Cartao from '../../pages/assinatura/pagamento/cartao'

const Payment = () => {
  return (
        <Routes>
            <Route path="/pix" element={<Pix />}/>
            <Route path="/cartao" element={<Cartao />}/>
        </Routes>
  )
}

export default Payment
