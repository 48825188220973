import React from 'react'
import { Container } from './styles'
import Button from '../../../components/Button'
import { useLoading } from '../../../hooks/useLoading'
import { api } from '../../../services/api'
import axios from 'axios'
import { API_BASE_URL } from '../../../config/contants'
import secure from '../../../config/secureLS'

const Logotipos: React.FC = () => {
  const [team, setTeam] = React.useState('')
  const formRef = React.useRef<any>()

  const { showLoading, closeLoading } = useLoading()
  const [logotipos, setLogotipos] = React.useState<any>()

  React.useEffect(() => {
    getLogotipos()
  }, [])

  const getLogotipos = async () => {
    try {
      showLoading()

      const { data } = await api.get('/logotipos')

      setLogotipos(data.data)
    } catch (err: any) {
    } finally {
      closeLoading()
    }
  }

  const update = async (file?: File) => {
    try {
      showLoading()

      const bodyFormData = new FormData()
      if (file) {
        bodyFormData.append('file', file)
      }

      const token = secure.get('pelejas@access-token')

      await axios.post(
                `${API_BASE_URL}/logotipos/${team}`,
                bodyFormData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token.data}`
                  }
                }
      )

      await getLogotipos()
    } catch (err: any) {
    } finally {
      closeLoading()
    }
  }

  const handleTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTeam(event.target.value)
  }

  return (
        <Container>
            {logotipos && (
                <select onChange={handleTime}>
                    {logotipos.map((logo: any) => (
                        <option key={logo.id} value={logo.id}>
                            <em>
                                {logo.team} {!logo.file && '(Sem Arquivo)'}
                            </em>
                        </option>
                    ))}
                </select>
            )}
            <div id="image">
                {team && (
                    <>
                        {logotipos.find((logo: any) => logo.id === +team)
                          ? (
                            <img
                                src={
                                    logotipos.find(
                                      (logo: any) => logo.id === +team
                                    ).file
                                }
                            />
                            )
                          : (
                              'Sem arquivo'
                            )}
                    </>
                )}
                <form ref={formRef} className="options">
                    <input
                        type="file"
                        onChange={(e) => {
                          if (e.target.files) {
                            update(e.target.files[0])
                            formRef.current.reset()
                          }
                        }}
                    />
                    {team &&
                        logotipos.find((logo: any) => logo.id === +team)
                          .file && (
                            <Button type="button" onClick={() => update()}>
                                Excluir
                            </Button>
                    )}
                </form>
            </div>
        </Container>
  )
}

export default Logotipos
