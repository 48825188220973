import React from 'react'
import { Route, Routes } from 'react-router-dom'
import FichaTecnica from '../../pages/fichas-tecnicas/[id]/index.page'
import CruzarDados from '../../pages/fichas-tecnicas/cruzar-dados/index.page'
import Relatorio from '../../pages/fichas-tecnicas/cruzar-dados/relatorio/index.page'
import FichasTecnicas from '../../pages/fichas-tecnicas/index.page'

const FichasTecnicasRoutes = () => {
  return (
        <Routes>
            <Route path="/" element={<FichasTecnicas />}/>
            <Route path="/:id" element={<FichaTecnica />}/>
            <Route path="/cruzar-dados" element={<CruzarDados />}/>
            <Route path="/cruzar-dados/relatorio" element={<Relatorio />}/>
        </Routes>
  )
}

export default FichasTecnicasRoutes
