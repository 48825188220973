import React from 'react'
import Container from '../../components/Container'
import {
  ToolCharts,
  TeamsSelect,
  Hr,
  ChartsContainer,
  LinkContainer,
  ContainerTools,
  ChartIconStyled,
  ChartCrossIconStyled,
  HeaderContent,
  CallBlog
} from './styles'
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts'
import Skeleton from '@mui/material/Skeleton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Link } from 'react-router-dom'
import Planos from '../planos'
import { UserContext } from '../../UserContext'

const data02 = [
  { name: 'Jogos', value: 2400, color: '#124030' },
  { name: 'Gols Marcados', value: 4567, color: '#124030' },
  { name: 'Gols Sofridos', value: 1398, color: '#124030' },
  { name: 'Jogos', value: 2400, color: '#124030' },
  { name: 'Gols Marcados', value: 4567, color: '#124030' },
  { name: 'Gols Sofridos', value: 1398, color: '#ccc' }
]

const Home: React.FC = () => {
  const { subscriber } = React.useContext(UserContext)

  return (
        <>
            <ToolCharts>
                <Container>
                    <header className="header">
                        <h1>Bem-vindo ao PELEJAS, torcedor palmeirense!</h1>
                        <p>(dados dos ultimos <span className="text-bold">2 anos</span> de contronto, <Link to="/fichas-tecnicas/cruzar-dados">Clique aqui</Link> para mais filtros)</p>
                    </header>
                    <TeamsSelect>
                        <div id="palmeiras" className="team">
                            <span>PALMEIRAS</span>
                            <div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/svg/pal.svg'}/>
                                </div>
                            </div>
                        </div>
                        <div id="versus">
                            <span>X</span>
                        </div>
                        <div id="opponent" className="team">
                            <div>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/svg/cor.svg'}/>
                                </div>
                            </div>
                            <span>CORINTHIANS</span>
                        </div>
                    </TeamsSelect>
                    <Hr />
                    <div>
                        <p className="title-chart">Dados de PALMEIRAS <span className="text-orange">x</span> CORINTHIANS</p>
                    </div>
                    <ChartsContainer>
                        <div className="charts">
                            <h1>Resultados gerais da pesquisa:</h1>
                            <div className="ds-flex">
                                <ResponsiveContainer width={300} height={220}>
                                    <PieChart width={300} height={220}>
                                        <Pie label dataKey="value" data={data02} cx={150} cy={110} innerRadius={40} outerRadius={80}>
                                            {data02.map((entry: any, index: any) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                                <div className="info-charts">
                                    <p><strong>Jogos: </strong> 20000</p>
                                    <p><strong>Gols Marcados: </strong> 1745</p>
                                    <p><strong>Gols Sofridos: </strong> 1745</p>
                                    <p><strong>Gols Marcados: </strong> 1745</p>
                                    <p><strong>Gols Marcados: </strong> 1745</p>
                                </div>
                            </div>
                        </div>
                        <div className="charts">
                            <h1>Resultados gerais da Equipe:</h1>
                            <div className="ds-flex">
                                <ResponsiveContainer width={300} height={220}>
                                    <PieChart width={300} height={220}>
                                        <Pie width={80} height={80} label dataKey="value" data={data02} cx={150} cy={110} innerRadius={40} outerRadius={80}>
                                            {data02.map((entry: any, index: any) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                                <div className="info-charts">
                                    <Skeleton sx={{ bgcolor: 'grey.900' }} animation="wave" />
                                    <Skeleton sx={{ bgcolor: 'grey.900' }} animation="wave" />
                                    <Skeleton sx={{ bgcolor: 'grey.900' }} animation="wave" />
                                    <Skeleton sx={{ bgcolor: 'grey.900' }} animation="wave" />
                                    <Skeleton sx={{ bgcolor: 'grey.900' }} animation="wave" />
                                    <Skeleton sx={{ bgcolor: 'grey.900' }} animation="wave" />
                                    <Skeleton sx={{ bgcolor: 'grey.900' }} animation="wave" />
                                </div>
                            </div>
                        </div>
                    </ChartsContainer>
                    <LinkContainer>
                        <Link to="/">
                            Clique aqui para filtros avançados
                        </Link>
                        <ArrowForwardIosIcon />
                    </LinkContainer>
                </Container>
            </ToolCharts>
            <Container className="bg-white-2" tag="article">
                <HeaderContent>
                    <h1>Recursos Gratuitos</h1>
                    <p>Estátisticas gratuitas, com mais dados para assinantes premium.</p>
                </HeaderContent>
                <ContainerTools>
                    <div className="box">
                        <div className="header">
                            <ChartIconStyled />
                            <h1>FICHAS TÉCNICAS</h1>
                        </div>
                        <div className="description">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div className="action">
                            <Link to="/fichas-tecnicas">
                                ACESSAR
                            </Link>
                        </div>
                    </div>
                    <div className="box">
                        <div className="header">
                            <ChartCrossIconStyled />
                            <h1>CRUZAR FICHAS TÉCNICAS</h1>
                        </div>
                        <div className="description">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div className="action">
                            <Link to="/fichas-tecnicas/cruzar-dados">
                                ACESSAR
                            </Link>
                        </div>
                    </div>
                </ContainerTools>
            </Container>

            {!subscriber && (
                <Planos />
            )}

            <Container tag="article" className="bg-orange padding-content-20">
                <CallBlog>
                    <h1>Leia no Blog curiosidades do VERDÃO</h1>
                </CallBlog>
            </Container>
        </>
  )
}

export default Home
