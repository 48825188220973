import React from 'react'
import Container from '../../../../components/Container'
import TitleSection from '../../../../components/TitleSection'
import Button from '../../../../components/Button'
// import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet'
import secure from '../../../../config/secureLS'
import { UserContext } from '../../../../UserContext'
import { api } from '../../../../services/api'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../../components/Loading'
import { CardContainer } from '../../../perfil/cartoes/styles'
import { ButtonSubmitContainer } from './styles'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'

interface ClientData {
    email: string;
    identification: {
        type: string;
        number: string;
    };
    cards?: [{
        id: number;
        payment_method: {
            secure_thumbnail: string;
        };
        issuer: {
            name: string;
        }
        first_six_digits: string;
        last_four_digits: string;
        expiration_month: string;
        expiration_year: string;
    }];
}

const Cartao: React.FC = () => {
  // const { enqueueSnackbar } = useSnackbar();
  const [client, setClient] = React.useState<ClientData | null>()
  const [newCard, setNewCard] = React.useState(false)
  const [card, setCard] = React.useState<string>('')
  const [value, setValue] = React.useState('')

  const navigate = useNavigate()

  const { data } = React.useContext(UserContext)

  const token = secure.get('pelejas@access-token')

  React.useEffect(() => {
    clientExist()
  }, [])

  const clientExist = async () => {
    try {
      const { data: dataClient } = await api.get('mercado-pago/client')

      if (!dataClient.data) {
        navigate('/assinatura/informacoes-pessoais', { replace: true })
      }

      setClient(dataClient.data)
      setValue(dataClient.data.default_card)
    } catch (err) {
      navigate('/inicio', { replace: true })
    }
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
  }

  const handleCard = () => {
    setCard(value)
    setNewCard(false)
  }

  return (
        <div>
            <TitleSection text="PAGAMENTO - CARTÃO"/>

            <Container tag="main">
                {(client?.cards && !newCard && !card) &&
                    (
                        <>
                            <CardContainer>
                                {client?.cards.map(card => (
                                    <div className="card" key={card.id}>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-error-radios"
                                                name="card"
                                                value={value}
                                                onChange={handleRadioChange}
                                            >
                                                <FormControlLabel value={card.id} control={<Radio />} label="Cartão Princípal" />
                                            </RadioGroup>
                                        </FormControl>
                                        <div className="header">
                                            <img src={card.payment_method.secure_thumbnail}/>
                                            <p className="issuer">{card?.issuer.name}</p>
                                        </div>
                                        <p className="number">{card?.first_six_digits} **** **** {card?.last_four_digits}</p>
                                        <p className="expire"><b>Expiração:</b> {card?.expiration_month}/{card?.expiration_year}</p>
                                    </div>))
                                }
                            </CardContainer>
                            <ButtonSubmitContainer>
                                <Button color="secondary" onClick={() => setNewCard(true)}>Novo cartão</Button>
                                <Button onClick={() => handleCard()}>Avançar</Button>
                            </ButtonSubmitContainer>
                        </>
                    )}
                <div id="cardPaymentBrick_container" style={{ display: (newCard || !client?.cards) ? 'block' : 'none' }}/>

                <form id="pay" style={{ display: card ? 'block' : 'none' }}>
                    {card && (
                        <>
                            <li>
                                <label>Payment Method:</label>
                                <select id="cardId" name="cardId" data-checkout='cardId'>
                                    <option value={card}>{card}</option>
                                </select>
                            </li>
                            <li>
                                <label htmlFor="cvv">CVV:</label>
                                <input type="text" id="cvv" value="123" data-checkout="securityCode" placeholder="123"/>
                            </li>
                            <button type="submit">Submit</button>
                        </>
                    )}
                </form>
            </Container>

            <div id="loading">
                <Loading open={true}/>
            </div>

            {(data.id && client && client.email) && (
                <Helmet>
                    <script
                        type="text/javascript"
                    >
                        {`                                             

                        function removeElement(id) {
                            var elem = document.getElementById(id);
                            return elem.parentNode.removeChild(elem);
                        }
                        
                        var mp = new MercadoPago('${process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY}', { 
                            locale: 'pt-BR' 
                        }); 
                        
                        var bricksBuilder = mp.bricks(); 
                        
                        var renderCardPaymentBrick = async (bricksBuilder) => { 

                        var settings = { 
                            initialization: { 
                              amount: 29.99,      
                              payer: { 
                                email: '${client.email}', 
                                identification: { 
                                    type: '${client.identification.type}', 
                                    number: '${client.identification.number}', 
                                }, 
                              },                 
                            },    
                            customization: { 
                                paymentMethods: { 
                                    minInstallments: 1, 
                                    maxInstallments: 1, 
                                }, 
                                visual: { 
                                    texts: { 
                                        formTitle: 'Cartão de crédito ou débito' 
                                    } 
                                } 
                            },                      
                            callbacks: { 
                              onReady: () => { 
                                removeElement('loading')                          
                              }, 
                              onSubmit: (cardFormData) => {   
                                return new Promise((resolve, reject) => { 
                                    fetch("${process.env.REACT_APP_API_URL}/mercado-pago/cartao", { 
                                        method: "POST", 
                                        headers: { 
                                            "Content-Type": "application/json", 
                                            "Authorization": "Bearer " + "${token.data}", 
                                        }, 
                                        body: JSON.stringify(cardFormData) 
                                    }) 
                                    .then((response) => { 
                                        // get payment result 
                                        if (response.status == 200){ 
                                            window.location.href = '/assinatura/bem-vindo'; 
                                        }
                                        //  else { 
                                        //     window.location.href = '/assinatura/pagamento/cartao?error=true'; 
                                        // } 
                                        
                                        resolve(); 
                                    }) 
                                    .catch((error) => { 
                                        // get payment result error 
                                        reject(); 
                                    }) 
                                }); 
                              }, 
                              onError: (error) => { 
                                // callback chamado para todos os casos de erro do Brick 
                              }, 
                            }, 
                          }; 
                          var cardPaymentBrickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings); 
                        }; 
                        
                        renderCardPaymentBrick(bricksBuilder);      
                        
                        doSubmit = false;
                        var form = document.querySelector('#pay');
                        form.addEventListener('submit', doPay);
                        
                        async function doPay(event){
                            event.preventDefault();
                            if(!doSubmit){
                                var $form = document.querySelector('#pay');
                                                      
                                const token = await mp.createCardToken({
                                   cardId: document.getElementById('cardId').value,
                                   securityCode: document.getElementById('cvv').value,
                                })
                    
                                // Use the received token to make a POST request to your backend
                               console.log('token received: ', token.id)
                                
                                fetch("${process.env.REACT_APP_API_URL}/mercado-pago/cartao", { 
                                    method: "POST", 
                                    headers: { 
                                        "Content-Type": "application/json", 
                                        "Authorization": "Bearer " + "${token.data}", 
                                    }, 
                                    body: JSON.stringify({token: token.id}) 
                                }) 
                                .then((response) => { 
                                    // get payment result 
                                    if (response.status == 200){ 
                                        window.location.href = '/assinatura/bem-vindo'; 
                                    }
                                    //  else { 
                                    //     window.location.href = '/assinatura/pagamento/cartao?error=true'; 
                                    // } 
                                    
                                    resolve(); 
                                }) 
                                .catch((error) => { 
                                    // get payment result error
                                    console.log(error); 
                                }) 
                        
                               return false;
                            }
                        };
                                           
                        `}
                    </script>
                </Helmet>
            )}
        </div>
  )
}

export default Cartao
