import styled from 'styled-components'

export const PixContainer = styled.div`
  text-align: center;
  
  img {
    max-width: 400px;
  }
  
  #code {
    padding: 5px;
    background-color: #ddd;
    border: 1px solid #ccc;
    color: #666;
    margin-bottom: 5px;
    
    word-wrap: break-word;
  }
`
