import React from 'react'
import {
  HeaderContainer,
  Logo,
  MenuContent,
  MenuIconStyled,
  MenuMobile,
  CloseIconStyled,
  HeaderMenuMobile,
  AccountCircleIconStyled,
  MenuList,
  HomeIconStyled,
  TitleOption,
  CreditCardIconStyled,
  TextFormatIconStyled,
  CallIconStyled,
  LogoutIconStyled,
  SettingsIconStyled,
  UserInfos,
  MenuDesktop,
  KeyboardArrowDownIconStyled,
  KeyboardArrowUpIconStyled,
  CallUser, ScrollBlock
} from './styles'
import { Slide } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart'
import { Link, matchRoutes, useLocation } from 'react-router-dom'
import Logotipo from '../../assets/png/logo.png'
import ballKing from '../../assets/png/ball-king.png'
import ball from '../../assets/png/ball.png'
import { UserContext } from '../../UserContext'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

const routes = [
  { path: '/entrar' },
  { path: '/assinatura/bem-vindo' },
  { path: '/cadastro' },
  { path: '/esqueci-minha-senha' },
  { path: '/esqueci-minha-senha/link' },
  { path: '/esqueci-minha-senha/redefinir/sucesso' },
  { path: '/esqueci-minha-senha/redefinir/[token]/[email]' },
  { path: '/login-backoffice' }
]

const useCurrentPath = () => {
  const location = useLocation()
  const router = matchRoutes(routes, location)

  return !!router
}

const Header: React.FC = () => {
  const currentPath = useCurrentPath()

  const [menuMobile, setMenuMobile] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorElFichas, setAnchorElFichas] = React.useState<null | HTMLElement>(null)

  const { data, subscriber } = React.useContext(UserContext)

  const router = useLocation()

  const openMenuUser = Boolean(anchorEl)
  const openMenuFichas = Boolean(anchorElFichas)

  const handleMenuMobile = () => {
    setMenuMobile(!menuMobile)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickFichas = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFichas(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseFichas = () => {
    setAnchorElFichas(null)
  }

  const handleCloseMenuMobile = (e: React.MouseEvent) => {
    if (e.currentTarget === e.target) handleMenuMobile()
  }

  const handleCloseMenuMobileOnClick = () => {
    handleMenuMobile()
  }

  if (currentPath) {
    return <></>
  }

  return (
        <HeaderContainer className="container">
            <div className="content">
                <MenuContent>
                    <Logo>
                        <Link to="/inicio">
                            <img
                                src={Logotipo}
                                alt="Logotipo Pelejas"
                            />
                        </Link>
                        Pelejas
                    </Logo>

                    <MenuDesktop>
                        <h1 className="font-zero">Menu Pelejas</h1>
                        <ul>
                            <Link className={router.pathname === '/inicio' ? 'active' : ''} to="/inicio">
                                <li>
                                    Página Inicial
                                </li>
                            </Link>
                            <div onClick={handleClickFichas} id="fake-link" className={openMenuUser ? 'active' : ''}>
                                <a className={router.pathname === '/fichas-tecnicas' ? 'active' : ''} title="Pelejas | Página Inicíal">
                                    <li>
                                        <div id="name">
                                            <p>
                                                Fichas Técnicas
                                            </p>
                                        </div>
                                        {openMenuFichas ? <KeyboardArrowUpIconStyled /> : <KeyboardArrowDownIconStyled />}
                                    </li>
                                </a>
                            </div>
                            <Menu
                                anchorEl={anchorElFichas}
                                id="account-menu-fichas"
                                open={openMenuFichas}
                                onClose={handleCloseFichas}
                                onClick={handleCloseFichas}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1
                                    },
                                    '& a': {
                                      color: '#666',
                                      textDecoration: 'none'
                                    },
                                    '&:before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0
                                    }
                                  }
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <Link to="/fichas-tecnicas">
                                    <MenuItem>
                                        <ListItemIcon>
                                            <ShowChartIcon fontSize="small" />
                                        </ListItemIcon>
                                        Exibir fichas técnicas
                                    </MenuItem>
                                </Link>
                                <Link to="/fichas-tecnicas/cruzar-dados">
                                    <MenuItem>
                                        <ListItemIcon>
                                            <StackedLineChartIcon fontSize="small" />
                                        </ListItemIcon>
                                        Filtrar dados
                                    </MenuItem>
                                </Link>
                            </Menu>
                            <Link className={router.pathname === '/planos' ? 'active' : ''} to="/planos">
                                <li>
                                    Planos de Assinatura
                                </li>
                            </Link>
                            <Link className={router.pathname === '/blog' ? 'active' : ''} to="/blog">
                                <li>
                                    Blog
                                </li>
                            </Link>
                            {data?.id
                              ? <>
                                    <span id="user-infos" className={openMenuUser ? 'active' : ''} onClick={handleClick}>
                                        <li>
                                            {subscriber
                                              ? (
                                                    <img
                                                        src={ballKing}
                                                        alt="Logotipo Pelejas"
                                                    />
                                                )
                                              : (
                                                    <img
                                                        src={ball}
                                                        alt="Logotipo Pelejas"
                                                    />
                                                )}
                                            <div id="name">
                                                <p>
                                                    <>
                                                        {(data?.nome && data?.nome.length > 9) ? `${data?.nome.slice(0, 6)}...` : data?.nome }
                                                    </>
                                                </p>
                                            </div>
                                            {openMenuUser ? <KeyboardArrowUpIconStyled /> : <KeyboardArrowDownIconStyled />}
                                        </li>
                                    </span>
                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={openMenuUser}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        PaperProps={{
                                          elevation: 0,
                                          sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                              width: 32,
                                              height: 32,
                                              ml: -0.5,
                                              mr: 1
                                            },
                                            '& a': {
                                              color: '#666',
                                              textDecoration: 'none'
                                            },
                                            '&:before': {
                                              content: '""',
                                              display: 'block',
                                              position: 'absolute',
                                              top: 0,
                                              right: 14,
                                              width: 10,
                                              height: 10,
                                              bgcolor: 'background.paper',
                                              transform: 'translateY(-50%) rotate(45deg)',
                                              zIndex: 0
                                            }
                                          }
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <Link to="/minha-conta">
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <SettingsIconStyled fontSize="small" />
                                                </ListItemIcon>
                                                Minha Conta
                                            </MenuItem>
                                        </Link>
                                        {data.type_user === 'admin' && (
                                            <Link to="/backoffice">
                                                <MenuItem>
                                                    <ListItemIcon>
                                                        <AdminPanelSettingsIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    Área Restrita
                                                </MenuItem>
                                            </Link>
                                        )}
                                        <Link to="/sair">
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <LogoutIconStyled fontSize="small" />
                                                </ListItemIcon>
                                                Encerrar Sessão
                                            </MenuItem>
                                        </Link>
                                    </Menu>
                                </>
                              : <CallUser>
                                    <div>
                                        <Link to="/entrar">
                                            Entrar
                                        </Link>
                                        <Link to="/cadastro">
                                            Cadastre-se
                                        </Link>
                                    </div>
                                </CallUser>
                            }
                        </ul>
                    </MenuDesktop>

                    <MenuIconStyled onClick={handleMenuMobile} fontSize="large"/>
                </MenuContent>
                {menuMobile &&
                    (<ScrollBlock />)
                }
                <Slide direction="left" in={menuMobile} mountOnEnter unmountOnExit>
                    <MenuMobile onClick={handleCloseMenuMobile}>
                        <div>
                            <HeaderMenuMobile>
                                {data?.id
                                  ? <UserInfos>
                                        <AccountCircleIconStyled/>

                                        <div>
                                            <h2><>{(data?.nome && data?.nome.length > 9) ? `${data?.nome.slice(0, 6)}...` : data?.nome }</></h2>
                                            <p>Assinante Peleja</p>
                                        </div>
                                    </UserInfos>
                                  : <ul>
                                        <li>Entrar</li>
                                        <li>Cadastre-se</li>
                                    </ul>
                                }
                                <CloseIconStyled onClick={handleMenuMobile} fontSize="large"/>
                            </HeaderMenuMobile>
                            <MenuList>
                                <div>
                                    <TitleOption>MENU</TitleOption>
                                    <Link onClick={handleCloseMenuMobileOnClick} className={router.pathname === '/inicio' ? 'active' : ''} to="/inicio">
                                        <li>
                                            <HomeIconStyled />
                                            Página Inicial
                                        </li>
                                    </Link>
                                    <Link onClick={handleCloseMenuMobileOnClick} className={router.pathname === '/planos' ? 'active' : ''} to="/planos">
                                        <li>
                                            <CreditCardIconStyled />
                                            Planos de Assinatura
                                        </li>
                                    </Link>
                                    <Link onClick={handleCloseMenuMobileOnClick} to="/blog">
                                        <li>
                                            <TextFormatIconStyled />
                                            Blog
                                        </li>
                                    </Link>
                                    <Link onClick={handleCloseMenuMobileOnClick} to="/">
                                        <li>
                                            <CallIconStyled />
                                            Entre em Contato
                                        </li>
                                    </Link>
                                </div>
                                {data?.id &&
                                <div>
                                    <TitleOption>OPÇÕES DA CONTA</TitleOption>
                                    <MenuList>
                                        <Link to="/minha-conta">
                                            <li>
                                                <SettingsIconStyled/>
                                                Minha Conta
                                            </li>
                                        </Link>
                                        <Link to="/sair">
                                            <li>
                                                <LogoutIconStyled/>
                                                Encerrar Sessão
                                            </li>
                                        </Link>
                                    </MenuList>
                                </div>
                                }
                            </MenuList>
                        </div>
                    </MenuMobile>
                </Slide>
                <div className="clear"></div>
            </div>
        </HeaderContainer>
  )
}

export default Header
