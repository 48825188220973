import styled, { createGlobalStyle } from 'styled-components'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import HomeIcon from '@mui/icons-material/Home'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import TextFormatIcon from '@mui/icons-material/TextFormat'
import CallIcon from '@mui/icons-material/Call'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

export const HeaderContainer = styled.header`
  padding: 5px 0 !important;
  background-color: #00341D;
  border-bottom: 1px solid #002716;
`

export const Logo = styled.h1`
  font-size: 0em;
  
  img {
    width: 100%;
    max-width: 150px;
  }
  
  @media (min-width: 1200px) {
    margin-right: 40px;
  }
`

export const MenuContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MenuIconStyled = styled(MenuIcon)`
  fill: #fff !important;

  @media (min-width: 1200px) {
    display: none !important;
  }
`

export const CloseIconStyled = styled(CloseIcon)`
  fill: #666;
`

export const HomeIconStyled = styled(HomeIcon)`
  fill: #666;
`

export const KeyboardArrowDownIconStyled = styled(KeyboardArrowDownIcon)`
  fill: #eee;
`

export const KeyboardArrowUpIconStyled = styled(KeyboardArrowUpIcon)`
  fill: #eee;
`

export const CreditCardIconStyled = styled(CreditCardIcon)`
  fill: #666;
`

export const TextFormatIconStyled = styled(TextFormatIcon)`
  fill: #666;
`

export const CallIconStyled = styled(CallIcon)`
  fill: #666;
`

export const LogoutIconStyled = styled(LogoutIcon)`
  fill: #666;
`

export const SettingsIconStyled = styled(SettingsIcon)`
  fill: #666;
`

export const AccountCircleIconStyled = styled(AccountCircleIcon)`
  fill: #666;
  width: 60px !important;
  height: 60px !important;

  @media (min-width: 1200px) {
    fill: #eee;
    width: 30px !important;
    height: 30px !important;
  }
`

export const MenuMobile = styled.div`
  color: #666;
  
  > div{
    display: flex;
    flex-direction: column;
    
    width: 100%;
    height: 100%;

    padding: 20px 40px;

    background-color: ${props => props.theme.colors.backgroundWhite};

    overflow: auto;

    @media (min-width: 734px) {
      max-width: 500px;
      -webkit-box-shadow: -9px 4px 16px -21px rgba(0,0,0,1);
      -moz-box-shadow: -9px 4px 16px -21px rgba(0,0,0,1);
      box-shadow: -9px 4px 16px -21px rgba(0,0,0,1);
    }
  }
  
  display: flex;
  justify-content: flex-end;
  
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  
  width: 100%;
  height: 100%;
  
  @media (min-width: 1200px) {
    display: none;
  }

  a {
    display: block;
    text-decoration: none;
    border-radius: 10px;
    color: #666;

    &.active{
      background-color: rgba(189, 195, 199,0.2) !important;
    }
  }
`

export const ScrollBlock = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

export const HeaderMenuMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  border-bottom: 1px solid #ededed;
  padding-bottom: 20px;
  
  margin-bottom: 20px;
  
  ul {
    list-style: none;
  }
  
  li{
    padding: 0 5px;
    margin-bottom: 5px;
  }
`

export const MenuList = styled.div`
  list-style: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  
  li {
    display: flex;
    align-items: center; 
    
    font-size: .8em;
    
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 5px;

    :hover {
      background-color: #ededed;
    }
  }
  
  svg {
    margin-right: 10px;
  }
`

export const TitleOption = styled.h1`
  font-size: .9em;
  margin: 10px 0;
  color: #CCCCCC;
  font-weight: bold;
`

export const UserInfos = styled.div`
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 10px;
  }
  
  h2, p {
    font-size: .8em;
  }
  
  p {
    color: #ccc;
    font-weight: bold;
  }
`

export const MenuDesktop = styled.nav`
  display: none;
  
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    flex: 1;
  }

  @media (min-width: 1200px) {
    display: flex;
    flex: 1;
  }
  
  & > ul > a, #fake-link {
    cursor: pointer;
    margin-right: 20px;
    font-size: 0.8em;
    text-decoration: none;
    border-radius: 10px;
    padding: 5px 10px;
    font-weight: bold;
    
    &.active{
      background-color: rgba(189, 195, 199,0.1) !important;
    }
    
    :hover {
      background-color: rgba(189, 195, 199,0.1);
    }
  }
  
  li {
    color: #eee;
    display: flex;
  }
  
  span#user-infos {
    margin-left: auto;
    margin-right: 0;
    font-size: 0.8em;
    border-radius: 10px;
    padding: 5px 10px;

    &.active{
      background-color: rgba(189, 195, 199,0.1) !important;
    }

    :hover {
      background-color: rgba(189, 195, 199,0.1);
    }
    
    background-color: #002716;
    
    cursor: pointer;
    
    li {
      display: flex;
      align-items: center;
      
      #name {
        margin: 0 5px;
        
        p{color: #fff;}
      }
    }
    
    img {
      width: 20px;
    }
  }
`

export const CallUser = styled.div`
  margin-left: auto;
  font-size: 0.7em;
  
  a:last-of-type{
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #666;
    font-weight: bold;
  }
  
  a {
    color: #fff;
    text-decoration: none;
    
    font-size: 1.2em;
    
    :hover {
      color: #fff;
    }
  }
`
