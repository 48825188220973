import styled from 'styled-components'

export const BlogContainer = styled.div`
  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  button.remove {
    background-color: red;
  }
  
  button {
    max-width: 200px;
    margin-left: 10px;
    width: auto;
    padding: 10px;
    font-size: 0.9em;
  }
`
