import styled from 'styled-components'

export const Post = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  margin-right: 20px;
  
  h1 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .html-content {
    margin: 20px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    font-size: 0.8em;
  }
  
  a {
    color: #00341D;
  }
  
  img {
    width: 100%;
    border-radius: 4px;
  }
  
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  @media (min-width: 71.1875em) {
    .ds-flex {
      display: flex;
      align-items: flex-end;
      flex-direction: row-reverse;
      
      .text {
        flex: 1;
      }
      
      img {
        max-width: 200px;
        margin-left: 20px;
      }
    }
  }
`

export const PaginationContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`

export const LayoutBlog = styled.div`
  display: flex;
  align-items: flex-start;
  
  .sidebar {
    display: none;
    padding-left: 20px;
    border-left: 1px solid #eee;
    max-width: 300px;
    min-width: 300px;
    flex: 1;

    @media (min-width: 71.1875em) {
      display: block;
    }
    
    & > div {
      margin-bottom: 10px;
    }
    
    & > div:last-of-type {
      margin-bottom: 0;
    }
    
    h2 {
      font-size: .9em;
      color: #00341D;
    }

    p {
      font-size: .8em;
    }
  }
`

export const Survey = styled.div`
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #eee;
  
  h3 {
    font-size: 1em;
    color: #000;
  }
  
  ul {
    list-style: none;
  }

  #submit {
    text-align: right;
  }
  
  button {
    font-size: 1em;
    padding: 5px 10px;
    max-width: 100px;
  }
`
