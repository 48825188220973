import styled from 'styled-components'

export const ContentResult = styled.div`
  .super-app-theme--win{
    background-color: rgba(39, 174, 96,.3) !important;
    
    &:hover{
      background-color: rgba(39, 174, 96,.4) !important;
    }
  }

  .super-app-theme--lose{
    background-color: rgba(192, 57, 43,.3) !important;

    &:hover{
      background-color: rgba(192, 57, 43,.4) !important;
    }
  }

  .super-app-theme--draw{
    background-color: rgba(127, 140, 141,.3) !important;

    &:hover{
      background-color: rgba(127, 140, 141,.4) !important;
    }
  }
  
  display: flex;

  flex-direction: column;

  @media (min-width: 38.75em) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  
  h1 {
    font-size: 1.3em;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 0.9em;
    margin-bottom: 5px;
  }
`
