import styled from 'styled-components'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart'
import ShowChartIcon from '@mui/icons-material/ShowChart'

export const HeaderDescription = styled.div`
  text-align: left;
  border-bottom: 1px solid #ededed;
  padding-bottom: 20px;
  margin-bottom: 20px;
  color: #666;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 45.125em) {
    flex-direction: row;
  }
  
  h1 {
    font-size: 1.2em;
    margin-bottom: 4px;
  }
  
  .tagline {
    font-size: .8em;
  }
  
  .control {
  }
`

export const ChartIconStyled = styled(StackedLineChartIcon)`
  fill: #006437 !important;
  width: 80px !important;
  height: 80px !important;
  margin-right: 40px !important;
`

export const ChartOneIconStyled = styled(ShowChartIcon)`
  fill: #006437 !important;
  width: 80px !important;
  height: 80px !important;
  margin-right: 40px !important;
`

export const Form = styled.form`
  width: 100%;
  margin: 10px 0;
  
  .label {
    font-weight: bold;
    font-size: 0.9em;
    margin-top: 20px;
    margin-bottom: 5px;
    display: block;
  }
  
  .label-medium {
    font-size: 1.1em;
  }
  
  .input-div-flex {
    display: flex;
    align-items: flex-start;
    
    > div {
      margin: 0;
    }
  }

  @media (min-width: 71.1875em) {
    #options-scoreboard {
      display: flex;

      & > div:last-of-type {
        margin-left: 100px;
      }
    }
  }
  
  #scoreboard {
    display: flex;
    align-items: center;
    
    & > div {
      display: flex;
      align-items: center;
      
      .team-logo {
        display: flex;
        align-items: center;
      }
      
      #opponent-logo {
        margin-left: 10px;
      }

      #palmeiras-logo {
        margin-right: 10px;
      }
      
      img {
        max-width: 40px;
      }
    }
    
    & input {
      max-width: 100px;
      text-align: center;
    }
    
    & > span {
      display: block;
      margin: 0 10px;
      font-weight: bold;
    }
  }
  
  @media (min-width: 71.1875em) {
    .division {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      
      > div {
        flex: 1;
      }

      > div:first-of-type {
        margin-right: 20px;
      }
    }
  }
`

export const ButtonContainer = styled.div`
  text-align: right;
  padding-top: 20px;
  margin-top: 10px;
  border-top: 1px solid #eee;
  
  button {
    padding: 10px;
    max-width: 200px;
  }
`

export const ContentResult = styled.div`
  .pointer {
    cursor: pointer;
  }
  
  .super-app-theme--win{
    background-color: rgba(39, 174, 96,.3) !important;

    &:hover{
      background-color: rgba(39, 174, 96,.4) !important;
    }
  }

  .super-app-theme--lose{
    background-color: rgba(192, 57, 43,.3) !important;

    &:hover{
      background-color: rgba(192, 57, 43,.4) !important;
    }
  }

  .super-app-theme--draw{
    background-color: rgba(127, 140, 141,.3) !important;

    &:hover{
      background-color: rgba(127, 140, 141,.4) !important;
    }
  }
`
