import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Route, Routes } from 'react-router'
import { UserStorage } from './UserContext'
import GlobalStyle from './styles/global'
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme'
import { SnackbarProvider } from 'notistack'
import { LoadingProvider } from './hooks/useLoading'
import ScrollToTop from './components/ScrollToTop'
import LoginRoutes from './pages/backoffice'
import JustNotLoginRoutes from './routes/JustNotLoginRoutes'
import { createTheme, ThemeProvider as ThemeProviderMui } from '@mui/material/styles'
import { ptBR } from '@mui/x-data-grid'

const themeMui = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' }
    }
  },
  ptBR
)

function App () {
  return (
        <div className="App">
            <ThemeProviderMui theme={themeMui}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider maxSnack={10}>
                        <LoadingProvider>
                            <BrowserRouter>
                                <ScrollToTop/>
                                <UserStorage>
                                    <Routes>
                                        <Route path="/backoffice/*" element={<LoginRoutes/>}/>
                                        <Route path="*" element={<JustNotLoginRoutes/>}/>
                                    </Routes>
                                </UserStorage>
                            </BrowserRouter>
                            <GlobalStyle/>
                        </LoadingProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </ThemeProviderMui>
        </div>
  )
}

export default App
