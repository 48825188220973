import styled from 'styled-components'

interface StatusProps {
    name?: string;
}

const colorStatus = (status?: string) => {
  let color = '#222'

  switch (status) {
    case 'Cancelado':
      color = 'red'
      break
    case 'Ativo':
      color = 'green'
      break
  }

  return color
}

export const Status = styled.span<StatusProps>`
    color: ${props => colorStatus(props.name)}
`

export const SignatureContainer = styled.div`
  background-color: #ededed;
  color: #666;
  padding: 20px;
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    h1 {
      font-size: 1.4em;
    }
  }
  
  .footer {
    display: flex;
    justify-content: flex-end;
  }
  
  button {
    max-width: 100px;
    padding: 5px;
    margin-top: 20px;
    font-size: 0.8em;
    color: red;
  }
  
  img {
    max-width: 40px;
    margin-right: 10px;
  }
`
