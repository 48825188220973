import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  
  .card {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 10px 20px 10px 20px;
    flex: 1;
    max-width: 500px;
    margin-right: 10px;
    
    &:last-of-type {
      margin-right: 0px;
    }
    
    .issuer {
      font-weight: bold;
    }
    
    .number {
      font-size: 1.3em;
      margin: 10px 0;
    }
    
    .expire {
      font-size: 0.8em;
    }
    
    .header {
      display: flex;
      align-items: center;
      margin-top: 10px;
      
      img {
        margin-right: 10px;
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`

export const AddCard = styled.div`
  display: flex;
  
  svg {
    margin-right: 10px;
  }
  
  a {
    color: #222;
    font-weight: bold;
  }
  
  margin-bottom: 20px;
`
