import styled from 'styled-components'
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer'
import SportsIcon from '@mui/icons-material/Sports'
import SquareIcon from '@mui/icons-material/Square'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

interface ColorProps {
    coloricon: 'yellow' | 'red' | 'green'
}

export const SquareStyled = styled(SquareIcon)<ColorProps>`
  color: ${props => props.coloricon === 'yellow' ? '#f1c40f' : '#c0392b'};
`

export const ArrowUpwardIconStyled = styled(ArrowUpwardIcon)`
  color: #006437;
`

export const ArrowDownwardIconStyled = styled(ArrowDownwardIcon)`
  color: #c0392b;
`

export const SportsStyled = styled(SportsIcon)`
  color: #000;
`

export const SportsSoccerStyled = styled(SportsSoccerIcon)<ColorProps>`
  color: ${props => props.coloricon === 'red' ? '#c0392b' : '#006437'};
`

export const Description = styled.div`
  color: #fff;
  padding: 10px;
  border-radius: 3px;
  margin: 40px auto 0 auto;
  width: 98%;
  max-width: 400px;
  
  p {
    font-size: 1em;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px #000;
  }
  
  svg {
    margin-right: 5px;
  }
`

export const Referee = styled.div`
  display: flex;
  margin-bottom: 30px;
  
  div {
    border-radius: 3px;
    font-size: 0.8em;
    margin: 0 10px 5px 0;
    padding: 10px;
    color: #000;
    background-color: #ccc;
    display: flex;
    align-items: center;
  }
`

export const Players = styled.div`
  display: flex;

  .box:first-of-type {
    margin-right: 10px;
  }
  
  .box {
    flex: 1;    
    
    ul {
      list-style: none;
      
      li {
        color: #000;
        padding: 5px 10px;
        margin: 10px 0;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: space-between;
        
        .status {
          display: flex;
          align-items: center;
        }
        
        .hr {
          margin: 10px 0;
        }
        
        .coach, .goals {
          
          span {
            display: block;
            margin-left: 5px;
          }
          
          display: flex;
          align-items: center;
        }
        
        span {
          font-size: 1.1em;
        }
      }
    }
  }
`

export const Hr = styled.hr`
  height: 0.1px;
  background-color: #eee;
  border: 0;
  display: block;
`

export const ContainerComments = styled.div`
  h1 {
    font-size: 1.4em;
    margin-bottom: 40px;
  }
`

export const Comments = styled.div`

`

export const Comment = styled.div`
  display: flex;
  margin-bottom: 40px;

  p {
    word-break: break-word;
  }
  
  &:last-of-type {
    margin-bottom: 0;
  }
  
  h2 {
    font-size: 1.2em;
    color: #000;
    
    span {
      font-size: .7em;
      color: #666;
    }
  }
  
  .options-comment {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    
    span {
      font-size: 0.9em;
      display: block;
      margin-left: 10px;
    }  
  }
  
  font-size: 0.9em;
  color: #666;
`

export const ImagePerfil = styled.div`
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  padding: 10px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`

export const HeaderLineup = styled.div`
  > div{
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    
  }
  
  display: flex;
  align-items: center;
`

export const AddHtml = styled.div`
  padding: 40px 0;
  
  h1 {
    font-size: 1.4em;
    color: #000;
    margin-bottom: 20px;
  }
`

export const ContentHtml = styled.div`
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  overflow: hidden;
  
  img {
    max-width: 100% !important;
  }
`

export const Form = styled.form`
  text-align: right;
  
  button {
    max-width: 150px;
    padding: 5px 10px;
    font-size: 1em;
  }
  
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
`

export const InputComment = styled.textarea`
  resize: none;
  min-width: 100%;
  height: 100px;
  padding: 10px;
  border: 2px solid #E3E4E7;
  border-radius: 5px;
  font-size: 0.8em;
`

export const Desktop = styled.div`
  display: none;

  @media (min-width: 60em) {
    display: inherit;
  }
`

export const Mobile = styled.div`
  @media (min-width: 60em) {
    display: none;
  }
`
